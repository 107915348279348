import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
  Title: {
    color: theme.palette.primary.main,
    fontFamily: "GothicRegular",
    fontSize: ({ fontSize }) => fontSize,
    letterSpacing: ({ letterSpacing }) => letterSpacing,
    textTransform: ({ textTransform }) => textTransform,
  },
  textWithGoldUnderLine: {
    textTransform: "uppercase",
    fontFamily: "OpenSansSemibold",
    fontSize: "1.25rem",
    color: ({ color }) => color,
    "&:after": {
      content: `""`,
      display: "block",
      height: "3px",
      background: theme.palette.primary.main,
      width: "170px",
      transition: "all 0.3s ease 0s",
      marginTop: 4,
    },
  },
  summeryTextGoldUnderLine: {
    textTransform: "uppercase",
    fontFamily: "OpenSansSemibold",
    fontSize: "1.25rem",
    color: ({ color }) => color,
    "&:after": {
      content: `""`,
      display: "block",
      height: "3px",
      background: theme.palette.primary.main,
      width: "100%",
      margin: "auto",
      transition: "all 0.3s ease 0s",
      marginTop: 4,
    },
  },
}));

const MyTypograph = (props) => {
  const {
    variant,
    children,
    fontSize = "2rem",
    className,
    letterSpacing = "0.50rem",
    textTransform = "uppercase",
    color = "black",
  } = props;

  const classes = useStyle({ fontSize, textTransform, letterSpacing, color });
  return (
    <div
      className={clsx(className, {
        [classes.Title]: variant === "Title",
        [classes.textWithGoldUnderLine]: variant === "GoldUnderLineTitlesmall",
        [classes.summeryTextGoldUnderLine]:
          variant === "summeryTextGoldUnderLine",
      })}
    >
      {children}
    </div>
  );
};
export default MyTypograph;
