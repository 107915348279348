import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import plusSign from '../../assets/plusSign.png'

const useStyle = makeStyles(theme=>({ 
    menuBar:{
        zIndex: theme.zIndex.drawer+1,
        height: "124px",
        width: "50px",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        backgroundColor:theme.palette.common.black,
        right: 0,
        transform: "translate(50px)",
        transition:"transform 300ms",
        '& :hover' : {
            backgroundColor : theme.palette.primary.light,
        },
        position : 'absolute',
        top: "0%",
        cursor:"pointer",
        "& div":{
            display:"flex",
            justifyContent:"center",
            flexDirection:"column",
        },
        [theme.breakpoints.down('sm')] : {
            height: "90px",
            width: "35px",
        },
    },
    closed : {
        transform: "translate(0px)",
    },
    '@keyframes eachPageOut' : {
        "0%":{
            transform : 'translateX(0%)'
        },
        "100%":{
            transform : 'translateX(-100%)'
        },
    },
    onEachPage:{
        animation : '$eachPageOut 5s ease-in-out',
    }
}));

const OfferButton = ({OfferOpen, initStop, OpenOfferDrawer, ifOffer, isOffersDrawerOpenedOnce}) => {

    const classes = useStyle();

    return(
        isOffersDrawerOpenedOnce
        &&
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                onClick={()=>{
                    OpenOfferDrawer()
                }}
                className={clsx(classes.menuBar,{
                    [classes.closed] : (!OfferOpen && initStop) || (!OfferOpen && ifOffer),
                })}>
                    <Grid 
                        justify="center" 
                        container 
                        alignItems="center" 
                        item xs={12} 
                        direction="row">
                            <img alt="PlusSign" src={plusSign} />
                    </Grid>
            </Grid>
    )
};
export default OfferButton;