import React, { useEffect, useState } from "react";

const imageAssets = [
  require("../assets/1.png"),
  require("../assets/acne_after_women.jpg"),
  require("../assets/acne_before_women.jpg"),
  require("../assets/HAIR_LOSS_AFTER_MEN.jpg"),
  require("../assets/HAIR_LOSS_BEFORE_MEN.jpg"),
  require("../assets/hairloss_after_men.jpg"),
  require("../assets/HAIRLOSS_AFTER.jpg"),
  require("../assets/hairloss_before_men.jpg"),
  require("../assets/HAIRLOSS_BEFORE.jpg"),
  require("../assets/HAIRLOSS_BEFORE.jpg"),
  require("../assets/introLine.png"),
  require("../assets/loding-img.png"),
  require("../assets/Logo-Square_Gradient.jpg"),
  require("../assets/introLine.png"),
  require("../assets/loding-img.png"),
  require("../assets/mail.png"),
  require("../assets/mobIntroLine.png"),
  require("../assets/mobintroline2.png"),
];

export default function LoadAssets({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    cacheImages(imageAssets);
  }, []);

  const cacheImages = async (srcArray) => {
    const promise = await srcArray.map((src) => {
      return new Promise(function(resolve, reject) {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });

    await Promise.all(promise);
    setIsLoading(false);
  };

  return <>{children}</>;
}
