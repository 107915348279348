import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';

const useStyle = makeStyles(theme=>({
    footDivider:{
        backgroundColor:theme.palette.secondary.main,
        height:"15px",
        left: "0",
        width: "100%",
        bottom: "0",
        position: "absolute",
    },
    forTopfootDivider:{
        top:0
    },
    forSubPagefootDivider:{
        position: "inherit !important",
        height:30,
        marginTop:50
    }
}));

const FooterDivider = ({forTop=false, forSubpage=false}) => {
    const classes = useStyle();
    return(
            <div>
                <Divider className={clsx(classes.footDivider,
                        {
                            [classes.forTopfootDivider]:forTop,
                            [classes.forSubPagefootDivider]:forSubpage
                        }
                    )}/>
            </div>
    );
}
export default FooterDivider;