import * as React from "react";

function MenuIcon() {
  return (
    <svg width={17} height={16} viewBox="0 0 7.408 7.408">
      <g fill="#fff" fillOpacity={0.919}>
        <path d="M.005.005h7.408v1.058H.005zM.002 3.15H7.41v1.058H.002zM0 6.351h7.408V7.41H0z" />
      </g>
    </svg>
  );
}

export default MenuIcon;
