import { useState, useEffect } from 'react';

export const useCustomScroll = () => {

    const [sticky, setSticky] = useState(null);
    const handleScroll = () => {
        setSticky(window.pageYOffset !== 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);

    return [sticky];
}
