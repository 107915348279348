import React from 'react'
import { Route } from "react-router-dom";
const Botox = React.lazy(() => import('../Botox'));
const ChemSkinPeel = React.lazy(() => import('../ChemSkinPeel'));
const Fillers = React.lazy(() => import('../Fillers'));
const Microsclerotherapy = React.lazy(() => import('../Microsclerotherapy'));
const VitaminInfusions = React.lazy(() => import('../VitaminInfusions'));
const Microneedling = React.lazy(() => import('../Microneedling'));

export default function AllTreatmentsRoutes(
    redirectFunc,
    OpenPopUpModal,
    OpenPopUpModalFunc,
    ifOffer,
    enternalLinkFunc
){
    return (
        [
            <Route path='/botox' exact>
                <Botox
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/obagi-skin-treatment' exact>
                <ChemSkinPeel
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/filler' exact>
                <Fillers
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/micro-sclero-therapy' exact>
                <Microsclerotherapy    
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/vitamin-infusions' exact>
                <VitaminInfusions    
                    enternalLinkFunc={enternalLinkFunc}
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/microneedling' exact>
                <Microneedling
                    enternalLinkFunc={enternalLinkFunc}
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>
        ]
    )    
}
