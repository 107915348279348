import React, { useState, useCallback } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import NextIcon from "../../assets/next.svg";
import quotes from "../../assets/quotes.png";
import { withRouter } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import DrPalin from "../../assets/PNG.png";
import MyTypograph from "../../components/Common/MyTypograph";
import SurgeonTag from "../../assets/NameTagMin.png";
import { Avatar, lighten, Zoom } from "@material-ui/core";
import PrevIcon from "../../assets/prev.svg";
import ReactCompareImage from "react-compare-image";
import pic1 from "../../assets/BeforeAfter/Jaw After.png"; //
import pic2 from "../../assets/BeforeAfter/Jaw Before.png"; //
import Farah_After from "../../assets/BeforeAfter/Farah After.png";
import Farah_Before from "../../assets/BeforeAfter/Farah Before.png";
import Farah_After_SET2 from "../../assets/BeforeAfter/Farah after set2.png";
import Farah_Before_SET2 from "../../assets/BeforeAfter/Farah before set2.png";
import Fareen_Nose_After from "../../assets/BeforeAfter/Fareen Nose After.png";
import Fareen_Nose_Before from "../../assets/BeforeAfter/Fareen Nose Before.png";
import Iqra_nose_After from "../../assets/BeforeAfter/Iqra nose After.png";
import Iqra_nose_Before from "../../assets/BeforeAfter/Iqra nose Before.png";
import Saira_After from "../../assets/BeforeAfter/Saira After.png";
import Saira_Before from "../../assets/BeforeAfter/Saira Before.png";
import Sunil_set1_After from "../../assets/BeforeAfter/Sunil set1 After.png";
import Sunil_set1_Before from "../../assets/BeforeAfter/Sunil set1 Before.png";
import Sunil_set2_After from "../../assets/BeforeAfter/Sunil set2 After.png";
import Sunil_set2_Before from "../../assets/BeforeAfter/Sunil set2 Before.png";
import Front_After_set1 from "../../assets/BeforeAfter/Front After set1.png";
import Front_Before_set1 from "../../assets/BeforeAfter/Front Before set1.png";
import Front_After_set2 from "../../assets/BeforeAfter/Front After set2.png";
import Front_Before_set2 from "../../assets/BeforeAfter/Front Before set2.png";
import Button from "@material-ui/core/Button";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { CSSTransition } from "react-transition-group";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
const static_arr = [
  {
    beforeImg: pic2,
    afterImg: pic1,
    id: 0,
    title: "Chin & JawLine",
    description: `The client had always been bothered by her weak
        jawline and chin. She was concerned the
        procedure would be invasive and result in
        an unnatural appearance. With a minimally
        invasive technique, I was able to enhance her
        facial profile whilst keeping her looking natural.`,
  },
  {
    beforeImg: Fareen_Nose_Before,
    afterImg: Fareen_Nose_After,
    id: 1,
    title: "Liquid Rhinoplasty (Nose)",
    description: `Fareen was always conscious of the large dorsal hump on her nose. By injecting dermal filler into the nasal tissue, the nose was given a simpler profile and therefore created the perception of a smaller and straighter nose.`,
  },
  {
    beforeImg: Iqra_nose_Before,
    afterImg: Iqra_nose_After,
    id: 2,
    title: "Liquid Rhinoplasty (Nose)",
    description: `Iqra sought consultation in straightening her nose. Here, I didn’t want to do anything too drastic as it would’ve given Iqra an unnatural appearance. Injecting a small amount of dermal filler into the bridge, the hawk shaped nose was straightened, giving Iqra a much more flattering profile.`,
  },
  //array from now
  [
    {
      beforeImg: Farah_Before,
      afterImg: Farah_After,
      id: 31,
      title: "Tear Troughs & Lips ",
      description: `Hollow tear troughs exaggerated shadowing beneath Farah’s eyes making her appear tired. After the treatment, shadowing was reduced dramatically, giving her a fresher, younger look. Using only 0.2ml of dermal filler, I focused on the cupids bow of her lips to enhance the shape and give more definition. `,
    },
    {
      beforeImg: Farah_Before_SET2,
      afterImg: Farah_After_SET2,
      id: 32,
    },
  ],
  {
    beforeImg: Saira_Before,
    afterImg: Saira_After,
    id: 4,
    title: "Crow’s Feet & Tear Troughs ",
    description: `Saira visited the clinic with concerns regarding her crow’s feet and hollow periorbital areas. Using dermal filler and Botox®, I was able to smoothen out the wrinkles and fill out the cavity. The dermal filler also helped to plump the skin and further decrease wrinkling around her eyes, adding to the effect of the Botox®`,
  },
  [
    {
      beforeImg: Sunil_set1_Before,
      afterImg: Sunil_set1_After,
      id: 5,
      title: "Crow’s Feet / Frown Lines / Cheeks / Nasolabial Folds",
      description: `Botox® was used to significantly reduce winkles around the eye and forehead. Sunil was given a more angular face by augmenting the cheeks using dermal filler. This had the added benefit of tightening the skin around the jaw and chin, adding further definition to the face. `,
    },
    {
      beforeImg: Sunil_set2_Before,
      afterImg: Sunil_set2_After,
      id: 6,
    },
  ],
  [
    {
      beforeImg: Front_Before_set1,
      afterImg: Front_After_set1,
      id: 7,
      title: "Cheeks / Jawline & Chin / Lips",
      description: `Roxanna had a naturally round face. Sculpting her cheeks using dermal filler not only slimmed her face but also had the added benefit of softening her nasolabial folds. Roxanna’s chin was wider than desired (golden ratio) and by elongating and slimming her chin, softened and feminised her features. Jawline augmentation tightened and lifted the sagging skin around her jaw – reducing the appearance of her double chin.`,
    },
    {
      beforeImg: Front_After_set2,
      afterImg: Front_Before_set2,
      id: 8,
    },
  ],
  // {
  //     beforeImg : Saira_Before,
  //     afterImg : Saira_After,
  //     id : 9,
  //     title : 'Crow’s Feet & Tear Troughs ',
  //     description : `Saira visited the clinic with concerns regarding her crow’s feet and hollow periorbital areas. Using dermal filler and Botox®, I was able to smoothen out the wrinkles and fill out the cavity. The dermal filler also helped to plump the skin and further decrease wrinkling around her eyes, adding to the effect of the Botox®`
  // },
];

const useStyles = makeStyles((theme) => ({
  main_container: {
    //background: theme.palette.primary.light,
    background: "#FBF9F7",
    overflowX: "hidden",
  },
  containerClinicPage: {
    display: "flex",
    // backgroundColor: "white",
    height: "100vh",
    position: "relative",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "120vh",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      height: "180vh",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
      height: "100vh",
    },
  },
  contentDiv: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#FBF9F7",

    [theme.breakpoints.up("lg")]: {
      // marginTop: -240,
      // marginTop: 300,
    },
    [theme.breakpoints.down("lg")]: {
      // marginTop: 100,
      // marginTop: 300,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 60,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },

    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  titleDiv: {
    marginTop: 50, // 100 -> 50
    marginBottom: 50,
    [theme.breakpoints.down("xl")]: {
      marginTop: 55,
      marginBottom: 35,
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: 25,
      marginBottom: 15,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 25,
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  centeredText: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem !important",
      letterSpacing: "0.2rem",
    },
  },
  InnerContentDiv: {
    height: "fit-content",
    display: "flex",
    margin: "30px 170px",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      // height: 440,
      margin: "10px 170px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "68%",
    },
    [theme.breakpoints.up("xl")]: {
      minHeight: 560,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      margin: "0px 30px",
    },
  },
  CompareImgDiv: {
    paddingBottom: 10,
    "& > div:first-child": {
      borderRadius: 20,
      maxHeight: "100%",
      height: "100% !important",
    },
    "& >div:first-child>div:first-child>div:first-child>div:first-child>div:first-child, >div:first-child>div:first-child>div:first-child>div:first-child, >div:first-child>div:first-child>div:first-child, >div:first-child>div:first-child, >div:first-child, .react-swipeable-view-container>div, .react-swipeable-view-container>div>div": {
      borderRadius: 20,
      maxHeight: "100%",
      height: "100% !important",
    },
    [theme.breakpoints.up("md")]: {
      height: 440,
      minWidth: `24%`,
    },
    [theme.breakpoints.up("lg")]: {
      height: 450,
      minWidth: `24%`,
    },
    [theme.breakpoints.up("xl")]: {
      minHeight: 560,
      width: `22.5%`,
    },
    [theme.breakpoints.down("sm")]: {
      //   height: "100%",
      minWidth: `50%`,
      width: "50%",
      margin: "0 25%",
      height: 460,
    },
  },
  quoteDiv: {
    height: `100%`,
    width: "60%",
    [theme.breakpoints.up("xl")]: {
      width: "57%",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      minWidth: `100%`,
      width: "100%",
      margin: "20px 0px",
    },
  },

  imgContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    alignItems: "center",
    "& img:nth-child(1),img:nth-child(2)": {
      marginTop: `0 !important`,
    },
    "& img:nth-child(5),img:nth-child(6)": {
      marginBottom: `0 !important`,
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexWrap: "nowrap",
      transform: "none !important",
    },
  },
  singleImgContainer: {
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      justifySelf: "center",
    },
  },
  rightSideImagesSliderContainer: {
    width: "20%",
    [theme.breakpoints.up("xl")]: {
      width: "18%",
    },
    // Added this line just to make image selector up
    [theme.breakpoints.up("md")]: {
      position: "relative",
      top: "-20px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    alignItems: "center",
    "& img:nth-child(1),img:nth-child(2)": {
      marginTop: `0 !important`,
    },
    "& img:nth-child(5),img:nth-child(6)": {
      marginBottom: `0 !important`,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "140px",
      overflowY: "hidden",
      justifyContent: "center",
      margin: "5px 0px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "auto",
      height: "140px",
      overflowY: "hidden",
      justifyContent: "center",
    },
  },
  quoteTitle: {
    textTransform: "uppercase",
    fontFamily: "CenturyGothicBold",
    letterSpacing: ".5rem",
    fontSize: "1.15rem",
    textAlign: "center",
    margin: `20px 0`,
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.7rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      margin: "auto",
      textAlign: "center",
      fontSize: "1rem",
      wordWrap: "break-down",
    },
  },
  quoteTitleSpecialCase: {
    letterSpacing: `0.1rem !important`,
    alignSelf: "center",
    width: "75%",
  },
  quoteContent: {
    position: "relative",
    left: -8,
    top: "40px",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 0px",
      left: "unset",
      top: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "75%",
      margin: "auto",
      textAlign: "center",

      wordWrap: "break-down",
    },
  },
  quoteMainPartContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  quoteImgContainer: {
    display: "flex",
    justifyContent: "center",
    justifySelf: "flex-start",
  },
  quoteImg: {
    height: "20px",
    [theme.breakpoints.only("md")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "30px",
    },
  },
  QuoteText: {
    width: "60%",
    fontFamily: "GothicRegular",
    fontSize: "1rem",
    lineHeight: "2.1rem",
    marginBlockStart: 0,
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.35rem",
      lineHeight: "2.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  QuoteTextLong: {
    width: "75%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  QuoteTextDiv: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      // Need to adjust quote text
    },
  },
  QuoteAurthor: {
    fontSize: "1.05rem",
    fontFamily: "CenturyGothicBold",
    color: theme.palette.primary.main,
    textAlign: "center",
    display: "flex",
    marginLeft: 12,
    justifyContent: "center",

    [theme.breakpoints.up("xl")]: {
      fontSize: "1.85rem",
    },
    [theme.breakpoints.up("md")]: {
      // paddingTop: "0px",
      // position: "relative",
      // bottom: "54px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "100px",
    },
  },
  BtnDiv: {
    textAlign: "center",
    marginTop: 30,
  },
  smallImg: {
    minWidth: 74,
    maxWidth: 74,
    height: "auto",
    margin: "6px",
    flex: "1 0 34%",
    cursor: "pointer",
    borderRadius: 10,
    [theme.breakpoints.up("xl")]: {
      maxWidth: 95,
      minWidth: 95,
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 65,
      maxWidth: 65,
    },
  },
  border: {
    border: `3px solid ${theme.palette.primary.main}`,
    width: "calc(42px - 3px)",
    [theme.breakpoints.down("sm")]: {
      border: "none",
    },
  },
  drPalinAvatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    "& img": {},
    [theme.breakpoints.up("xl")]: {
      width: theme.spacing(13),
      height: theme.spacing(13),
    },
  },
  drPalindetailsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: 16,
  },
  drPalinTitle: {
    fontSize: `1.2rem`,
    fontWeight: 700,
    color: "black",
    marginBottom: 8,
    [theme.breakpoints.up("xl")]: {
      fontSize: `1.25rem`,
    },
  },
  surgeonTagImg: {
    height: 12,
    marginBottom: 6,
    [theme.breakpoints.up("xl")]: {
      height: 16,
    },
  },
  sliderContainer: {
    position: "relative",
  },
  dotsContainer: {
    position: "absolute",
    display: "flex",
    width: "100%",
    justifyContent: "center",
    bottom: -45,
    alignItems: "center",
    "& *": {
      margin: "0 4px",
      cursor: "pointer",
      color: "white",
      fontFamily: "GothicRegular",
      fontSize: ".9rem",
    },
    "& svg": {
      opacity: 0.5,
      transition: "all 250ms",
      "&:hover": {
        opacity: 1,
        transition: "all 250ms",
      },
    },
    [theme.breakpoints.down("sm")]: {
      //paddingTop: 10,
      // !need to work on this
    },
  },
  dot: {
    width: 40,
    borderRadius: 4,
    padding: "5px 3px 5px 10px",
    cursor: "pointer",
    margin: theme.spacing(0, 0.5),
    transition: "all .25s",
    background: lighten(theme.palette.primary.light, 0.5),
    "&:hover": {
      borderColor: theme.palette.secondary.main,
      transition: "all .25s",
      borderWidth: 0.5,
      background: lighten(theme.palette.primary.light, 0.15),
    },
    "&:active": {
      boxShadow: theme.shadows[2],
      transition: "all .25s",
    },
  },
  activeDot: {
    background: theme.palette.primary.light,
    "&:hover": {
      background: theme.palette.primary.light,
    },
  },
  rightArrow: {
    position: "absolute",
    right: -55,
    top: "45%",
    color: theme.palette.primary.main,
    "& img": {
      cursor: "pointer",
      width: 50,
      opacity: 0.4,
      transition: "all 250ms",
      "&:hover": {
        opacity: 1,
        transition: "all 250ms",
      },
    },
    [theme.breakpoints.up("xl")]: {
      top: "43%",
    },
    [theme.breakpoints.up("xl")]: {
      top: "43%",
    },
    [theme.breakpoints.down("sm")]: {
      top: "10%",
      right: "3%",
      "& img": {
        width: 25,
      },
    },
    [theme.breakpoints.down("xs")]: {
      top: "10%", // at 13% it positions to center
      right: "15%",
      "& img": {
        width: 25,
      },
    },
  },
  rightArrowDisabled: {
    color: lighten(theme.palette.primary.main),
  },
  leftArrow: {
    color: theme.palette.primary.main,
    position: "absolute",
    right: `18.5%`,
    top: "45%",
    "& *": {
      cursor: "pointer",
      width: 50,
      opacity: 0.4,
      transition: "all 250ms",
      "&:hover": {
        opacity: 1,
        transition: "all 250ms",
      },
    },
    [theme.breakpoints.up("xl")]: {
      right: "16.5%",
      top: "43%",
    },

    [theme.breakpoints.down("sm")]: {
      left: `8%`,
      top: "10%",
      right: "80%",
      "& *": {
        cursor: "pointer",
        width: 30,
        opacity: 0.4,
        transition: "all 250ms",
        "&:hover": {
          opacity: 1,
          transition: "all 250ms",
        },
      },
    },
    [theme.breakpoints.down("xs")]: {
      left: `15%`,
      top: "10%",
      right: `90.5%`,
      "& *": {
        cursor: "pointer",
        width: 25,
        opacity: 0.4,
        transition: "all 250ms",
        "&:hover": {
          opacity: 1,
          transition: "all 250ms",
        },
      },
    },
  },
  btnDiv: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",

    [theme.breakpoints.up("md")]: {
      marginTop: "98",
    },
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
  },
  buttonLabel: {
    color: theme.palette.primary.main,
    fontSize: ".84rem !important",
    letterSpacing: "0.2rem",
  },
  scrollButton: {
    [theme.breakpoints.down("md")]: {
      bottom: "2%",
    },
    width: "200px",
    borderRadius: 0,
    marginLeft: "auto",
    marginRight: "2rem",
    borderWidth: "1px !important",
    paddingRight: 6,
    position: "absolute",
    top: "unset",
    bottom: "6%",
    //margin: "0 0", // "10px 0"
    animation: "$intropic 0s 3s forwards",
    visibility: "hidden",
    "&:hover": {
      backgroundColor: `rgba(186,160,121,0.5) !important`,
      transition: `all 250ms`,
      "& *": {
        color: "white !important",
      },
    },
    "& .MuiButton-label": {
      height: 20,
    },
  },

  "@keyframes intropic": {
    to: { visibility: "visible" },
  },

  iconDownRoot: {
    fontSize: "1.9rem !important",
    color: theme.palette.primary.main,
    transition: `all 250ms`,
  },
  bottomScrollDiv: {
    //marginTop: "100px",
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      marginTop: "100px",
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "60px", // 50px
      // display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "50px", // 50px
      // display:
      //   window.innerWidth <= 1370 && window.innerWidth <= 1500 ? "none" : "",
    },
  },
  scrollUpButton: {
    [theme.breakpoints.down("md")]: {
      bottom: "2%",
    },
    width: "20px",
    borderRadius: 0,
    marginLeft: "auto",
    marginRight: "2rem",
    borderWidth: "1px !important",
    paddingRight: "30px",
    position: "absolute",
    top: "unset",
    bottom: "6%",
    //margin: "0 0", // "10px 0"
    animation: "$intropic 0s 3s forwards",
    visibility: "hidden",
    "&:hover": {
      backgroundColor: `rgba(186,160,121,0.5) !important`,
      transition: `all 250ms`,
      "& *": {
        color: "white !important",
      },
    },
    "& .MuiButton-label": {
      height: 20,
    },
  },
}));

const AestheCliniPage = ({
  history,
  openSubNavListfunc,
  openMenuFunc,
  handlePageChange,
  pageNo,
  width,
}) => {
  const classes = useStyles();
  const [selectedImg, setSelectedImg] = useState(static_arr[0]);
  const [value, setValue] = React.useState(0);
  const handleChangeIndex = (newValue) => {
    if (newValue <= 1 && newValue >= 0) {
      setValue(newValue);
    }
  };

  const [boool, setBoool] = useState(false);
  //Right side small images sliders
  const [rightSideSlider, setRightSideSlider] = useState(0);

  const isMaxSlideRightSide = useCallback((newValue) => {
    return newValue <= Math.floor(static_arr.length / 6) ? false : true;
  }, []);

  const handleChangeIndexRightSlider = (newValue) => {
    if (!isMaxSlideRightSide(newValue)) {
      setRightSideSlider(newValue);
    }
  };
  const rowsPerPage = width < 600 ? 4 : 6;

  const SectionTitle = (
    <div className={classes.titleDiv}>
      <MyTypograph
        style={{ textAlign: "center" }}
        variant="Title"
        className={classes.centeredText}
      >
        AESTHETIC & CLINICAL EXCELLENCE
      </MyTypograph>
    </div>
  );

  return (
    <div className={classes.main_container}>
      <div className={classes.containerClinicPage}>
        <div className={classes.contentDiv}>
          {width > 350 && (
            <div className={classes.btnDiv}>
              <Button
                variant="outlined"
                classes={{
                  root: classes.scrollUpButton,
                  label: classes.buttonLabel,
                }}
                style={{ position: "unset", marginTop: 20 }}
                onClick={(e) => handlePageChange(pageNo - 1)}
                endIcon={
                  <KeyboardArrowUpIcon
                    classes={{
                      root: classes.iconDownRoot,
                    }}
                  />
                }
                tabIndex={"0"}
                disableFocusRipple
              ></Button>
            </div>
          )}
          {SectionTitle}
          <div className={classes.InnerContentDiv}>
            {/* // !Large Screen */}
            {width > 860 && (
              <div className={classes.CompareImgDiv}>
                {!Array.isArray(selectedImg) ? (
                  <ReactCompareImage
                    className={classes.CompareHeight}
                    leftImage={selectedImg.beforeImg}
                    rightImage={selectedImg.afterImg}
                    sliderPositionPercentage={0.5}
                    key={boool}
                  />
                ) : (
                  <div className={classes.sliderContainer}>
                    <SwipeableViews
                      index={value}
                      onChangeIndex={handleChangeIndex}
                    >
                      <ReactCompareImage
                        className={classes.CompareHeight}
                        leftImage={selectedImg[0].beforeImg}
                        rightImage={selectedImg[0].afterImg}
                        sliderPositionPercentage={0.5}
                      />
                      <ReactCompareImage
                        className={classes.CompareHeight}
                        leftImage={selectedImg[1].beforeImg}
                        rightImage={selectedImg[1].afterImg}
                        sliderPositionPercentage={0.5}
                      />
                    </SwipeableViews>
                    <div className={classes.dotsContainer}>
                      {Array(2)
                        .fill("")
                        .map((item, index) => (
                          <div
                            key={index.toString()}
                            className={clsx(classes.dot, {
                              [classes.activeDot]: index === value,
                            })}
                            onClick={() => handleChangeIndex(index)}
                            title={`Set ${index + 1}`}
                          >
                            Set {index + 1}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            {width > 860 && (
              <div className={classes.quoteDiv}>
                <div className={classes.quoteContent}>
                  <div className={classes.quoteMainPartContainer}>
                    <div className={classes.quoteImgContainer}>
                      <img className={classes.quoteImg} src={quotes} alt="" />
                    </div>

                    <div
                      className={clsx(classes.quoteTitle, {
                        [classes.quoteTitleSpecialCase]:
                          Array.isArray(selectedImg) && selectedImg[0].id === 5,
                      })}
                    >
                      {!Array.isArray(selectedImg)
                        ? selectedImg.title
                        : selectedImg[0].title}
                    </div>
                    <div className={classes.QuoteTextDiv}>
                      <p
                        className={clsx(classes.QuoteText, {
                          [classes.QuoteTextLong]:
                            Array.isArray(selectedImg) &&
                            selectedImg[0].id === 7,
                        })}
                      >
                        {!Array.isArray(selectedImg)
                          ? selectedImg.description
                          : selectedImg[0].description}
                      </p>
                    </div>
                  </div>

                  <div className={classes.QuoteAurthor}>
                    <Avatar src={DrPalin} className={classes.drPalinAvatar} />
                    <div className={classes.drPalindetailsContainer}>
                      <span className={classes.drPalinTitle}>
                        Dr Martin Palin
                      </span>
                      <img
                        src={SurgeonTag}
                        className={classes.surgeonTagImg}
                        alt={""}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {width > 860 && (
              <SwipeableViews
                index={rightSideSlider}
                onChangeIndex={handleChangeIndexRightSlider}
                className={classes.rightSideImagesSliderContainer}
                enableMouseEvents
              >
                <div
                  className={clsx(classes.imgContainer, {
                    [classes.singleImgContainer]:
                      static_arr.slice(
                        rightSideSlider * rowsPerPage,
                        rightSideSlider * rowsPerPage + rowsPerPage
                      ).length === 1,
                  })}
                >
                  {static_arr
                    .slice(
                      rightSideSlider * rowsPerPage,
                      rightSideSlider * rowsPerPage + rowsPerPage
                    )
                    .map((item) =>
                      !Array.isArray(item) ? (
                        <img
                          key={item.id.toString()}
                          src={item.beforeImg}
                          className={clsx(classes.smallImg, {
                            [classes.border]: item.id === selectedImg.id,
                          })}
                          onClick={() => {
                            setSelectedImg(item);
                            setBoool(!boool);
                          }}
                          alt={""}
                        />
                      ) : (
                        <img
                          key={item[0].id.toString()}
                          src={item[0].beforeImg}
                          className={clsx(classes.smallImg, {
                            [classes.border]: item.some((i) => {
                              return Array.isArray(selectedImg) &&
                                selectedImg.some((s) => {
                                  return i.id === s.id;
                                })
                                ? true
                                : false;
                            }),
                          })}
                          onClick={() => {
                            setSelectedImg(item);
                            setBoool(!boool);
                            setValue(0);
                          }}
                          alt={""}
                        />
                      )
                    )}
                </div>

                {/* {width < 600 && (
                  <div
                    className={clsx(classes.imgContainer, {
                      [classes.singleImgContainer]:
                        static_arr.slice(
                          rightSideSlider * rowsPerPage,
                          rightSideSlider * rowsPerPage + rowsPerPage
                        ).length === 1,
                    })}
                  >
                    {static_arr.slice(6).map((item) =>
                      !Array.isArray(item) ? (
                        <img
                          key={item.id.toString()}
                          src={item.beforeImg}
                          className={clsx(classes.smallImg, {
                            [classes.border]: item.id === selectedImg.id,
                          })}
                          onClick={() => {
                            setSelectedImg(item);
                            setBoool(!boool);
                            setValue(0);
                          }}
                          alt={""}
                        />
                      ) : (
                        <img
                          key={item[0].id.toString()}
                          src={item[0].beforeImg}
                          className={clsx(classes.smallImg, {
                            [classes.border]: item.some((i) => {
                              return Array.isArray(selectedImg) &&
                                selectedImg.some((s) => {
                                  return i.id === s.id;
                                })
                                ? true
                                : false;
                            }),
                          })}
                          onClick={() => {
                            setSelectedImg(item);
                            setBoool(!boool);
                          }}
                          alt={""}
                        />
                      )
                    )}
                  </div>
                )} */}
              </SwipeableViews>
            )}

            {/* // ! Customize for < Ipad Image */}

            {width < 860 && (
              <div className={classes.quoteDiv}>
                <div className={classes.quoteContent}>
                  <div className={classes.quoteMainPartContainer}>
                    <div style={{ height: 140 }}>
                      <SwipeableViews
                        index={rightSideSlider}
                        onChangeIndex={handleChangeIndexRightSlider}
                        className={classes.rightSideImagesSliderContainer}
                        enableMouseEvents
                      >
                        <div
                          className={clsx(classes.imgContainer, {
                            [classes.singleImgContainer]:
                              static_arr.slice(
                                rightSideSlider * rowsPerPage,
                                rightSideSlider * rowsPerPage + rowsPerPage
                              ).length === 1,
                          })}
                        >
                          {static_arr
                            .slice(
                              rightSideSlider * rowsPerPage,
                              rightSideSlider * rowsPerPage + rowsPerPage
                            )
                            .map((item) =>
                              !Array.isArray(item) ? (
                                <img
                                  key={item.id.toString()}
                                  src={item.beforeImg}
                                  className={clsx(classes.smallImg, {
                                    [classes.border]:
                                      item.id === selectedImg.id,
                                  })}
                                  onClick={() => {
                                    setSelectedImg(item);
                                    setBoool(!boool);
                                  }}
                                  alt={""}
                                />
                              ) : (
                                <img
                                  key={item[0].id.toString()}
                                  src={item[0].beforeImg}
                                  className={clsx(classes.smallImg, {
                                    [classes.border]: item.some((i) => {
                                      return Array.isArray(selectedImg) &&
                                        selectedImg.some((s) => {
                                          return i.id === s.id;
                                        })
                                        ? true
                                        : false;
                                    }),
                                  })}
                                  onClick={() => {
                                    setSelectedImg(item);
                                    setBoool(!boool);
                                    setValue(0);
                                  }}
                                  alt={""}
                                />
                              )
                            )}
                        </div>

                        {/* //! On Mobile View*/}

                        {width < 600 && (
                          <div
                            className={clsx(classes.imgContainer, {
                              [classes.singleImgContainer]:
                                static_arr.slice(
                                  rightSideSlider * rowsPerPage,
                                  rightSideSlider * rowsPerPage + rowsPerPage
                                ).length === 1,
                            })}
                          >
                            {static_arr.slice(4).map((item) =>
                              !Array.isArray(item) ? (
                                <img
                                  key={item.id.toString()}
                                  src={item.beforeImg}
                                  className={clsx(classes.smallImg, {
                                    [classes.border]:
                                      item.id === selectedImg.id,
                                  })}
                                  onClick={() => {
                                    setSelectedImg(item);
                                    setBoool(!boool);
                                    setValue(0);
                                  }}
                                  alt={""}
                                />
                              ) : (
                                <img
                                  key={item[0].id.toString()}
                                  src={item[0].beforeImg}
                                  className={clsx(classes.smallImg, {
                                    [classes.border]: item.some((i) => {
                                      return Array.isArray(selectedImg) &&
                                        selectedImg.some((s) => {
                                          return i.id === s.id;
                                        })
                                        ? true
                                        : false;
                                    }),
                                  })}
                                  onClick={() => {
                                    setSelectedImg(item);
                                    setBoool(!boool);
                                  }}
                                  alt={""}
                                />
                              )
                            )}
                          </div>
                        )}
                      </SwipeableViews>
                    </div>

                    <div className={classes.CompareImgDiv}>
                      {!Array.isArray(selectedImg) ? (
                        <ReactCompareImage
                          className={classes.CompareHeight}
                          leftImage={selectedImg.beforeImg}
                          rightImage={selectedImg.afterImg}
                          sliderPositionPercentage={0.5}
                          key={boool}
                        />
                      ) : (
                        <div className={classes.sliderContainer}>
                          <SwipeableViews
                            index={value}
                            onChangeIndex={handleChangeIndex}
                          >
                            <ReactCompareImage
                              className={classes.CompareHeight}
                              leftImage={selectedImg[0].beforeImg}
                              rightImage={selectedImg[0].afterImg}
                              sliderPositionPercentage={0.5}
                            />
                            <ReactCompareImage
                              className={classes.CompareHeight}
                              leftImage={selectedImg[1].beforeImg}
                              rightImage={selectedImg[1].afterImg}
                              sliderPositionPercentage={0.5}
                            />
                          </SwipeableViews>
                          <div className={classes.dotsContainer}>
                            {Array(2)
                              .fill("")
                              .map((item, index) => (
                                <div
                                  key={index.toString()}
                                  className={clsx(classes.dot, {
                                    [classes.activeDot]: index === value,
                                  })}
                                  onClick={() => handleChangeIndex(index)}
                                  title={`Set ${index + 1}`}
                                >
                                  Set {index + 1}
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>

                    <div style={{ marginTop: 50 }}>
                      <div className={classes.quoteImgContainer}>
                        <img className={classes.quoteImg} src={quotes} alt="" />
                      </div>
                      <div
                        className={clsx(classes.quoteTitle, {
                          [classes.quoteTitleSpecialCase]:
                            Array.isArray(selectedImg) &&
                            selectedImg[0].id === 5,
                        })}
                      >
                        {!Array.isArray(selectedImg)
                          ? selectedImg.title
                          : selectedImg[0].title}
                      </div>

                      <div className={classes.QuoteTextDiv}>
                        <p
                          className={clsx(classes.QuoteText, {
                            [classes.QuoteTextLong]:
                              Array.isArray(selectedImg) &&
                              selectedImg[0].id === 7,
                          })}
                        >
                          {!Array.isArray(selectedImg)
                            ? selectedImg.description
                            : selectedImg[0].description}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={classes.QuoteAurthor}>
                    <Avatar src={DrPalin} className={classes.drPalinAvatar} />
                    <div className={classes.drPalindetailsContainer}>
                      <span className={classes.drPalinTitle}>
                        Dr Martin Palin
                      </span>
                      <img
                        src={SurgeonTag}
                        className={classes.surgeonTagImg}
                        alt={""}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* //! Swipe Arrow Function Here */}
            <Zoom in={rightSideSlider !== 0}>
              <div
                className={classes.leftArrow}
                onClick={() =>
                  handleChangeIndexRightSlider(rightSideSlider - 1)
                }
              >
                <img src={PrevIcon} alt={"prev"} title={"Previous"} />
              </div>
            </Zoom>
            <Zoom in={!isMaxSlideRightSide(rightSideSlider + 1)}>
              <div
                className={clsx(classes.rightArrow)}
                onClick={() =>
                  handleChangeIndexRightSlider(rightSideSlider + 1)
                }
              >
                <img src={NextIcon} alt={"next"} title={"Next"} />
              </div>
            </Zoom>
          </div>
          {/* // !ScrollDown Button        */}
          {width > 816 && (
            <div className={classes.bottomScrollDiv}>
              <CSSTransition
                in={true}
                classNames="socialmediaandothers"
                timeout={{
                  enter: 300,
                  exit: 0,
                  appear: 250,
                }}
              >
                <Button
                  variant="outlined"
                  classes={{
                    root: classes.scrollButton,
                    label: classes.buttonLabel,
                  }}
                  onClick={(e) => handlePageChange(pageNo + 1)}
                  endIcon={
                    <KeyboardArrowDownIcon
                      classes={{
                        root: classes.iconDownRoot,
                      }}
                    />
                  }
                  tabIndex={"0"}
                  disableFocusRipple
                >
                  SCROLL DOWN
                </Button>
              </CSSTransition>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(AestheCliniPage);
