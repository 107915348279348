import React from 'react'
import { Route } from "react-router-dom";
const Jawline = React.lazy(() => import('../Jawline'));
const Lips = React.lazy(() => import('../Lips'));
const Cheeks = React.lazy(() => import('../Cheeks'));
const Chin = React.lazy(() => import('../Chin'));
const TearTrough = React.lazy(() => import('../TearTrough'));
const ForheadLines = React.lazy(() => import('../ForheadLines'));
const FrownLines = React.lazy(() => import('../FrownLines'));
const NefertitiNeckLift = React.lazy(() => import('../NefertitiNeckLift'));
const MarionetteLines = React.lazy(() => import('../MarionetteLines'));
const NasolabialFolds = React.lazy(() => import('../NasolabialFolds'));
const Nose = React.lazy(() => import('../Nose'));
const LegSlimming = React.lazy(() => import('../LegSlimming'));
const EyeBrows = React.lazy(() => import('../EyeBrows'));
const GummySmile = React.lazy(() => import('../GummySmile'));
const BunnyLines = React.lazy(() => import('../BunnyLines'));
const CrowsFeet = React.lazy(() => import('../CrowsFeet'));
const Temple = React.lazy(() => import('../Temple'));

export default function AugmentationRoutes(
    redirectFunc,
    OpenPopUpModal,
    OpenPopUpModalFunc,
    ifOffer
){
    return (
        [
            <Route path='/jawline' exact>
                <Jawline
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/lips' exact>
                <Lips
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/cheeks' exact>
                <Cheeks
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/chin' exact>
                <Chin
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/trea-trough' exact>
                <TearTrough    
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/forehead-lines' exact>
                <ForheadLines
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/frown-lines' exact>
                <FrownLines
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/nefertiti-neck-lift' exact>
                <NefertitiNeckLift
                    redirectFunc={redirectFunc}    
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/marionette-lines' exact>
                <MarionetteLines
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/nasolabial-folds' exact>
                <NasolabialFolds    
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/nose' exact>
                <Nose    
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/leg-slimming' exact>
                <LegSlimming
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/eyebrows-lift' exact>
                <EyeBrows
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/gummy-smile' exact>
                <GummySmile
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/bunny-lines' exact>
                <BunnyLines
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/crows-feet' exact>
                <CrowsFeet
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/temple' exact>
                <Temple
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
        ]
    )    
}
