import React, { useState, useEffect, useMemo, useLayoutEffect } from "react";
import clsx from "clsx";
import OfferMenu from "../components/LandingPage/OfferMenu";
import SliderWrapper from "../components/LandingPage/SliderWrapper";
import { makeStyles } from "@material-ui/core/styles";
import { CSSTransition } from "react-transition-group";
import Timebar from "../components/LandingPage/Timebar";
import ReactPageScroller from "react-page-scroller";
import MeetDrPalin from "../components/HomePages/MeetDrPalin";
import AestheCliniPage from "../components/HomePages/AestheCliniPage";
import DrawerMenu from "../components/Common/DrawerMenu/DrawerMenu";
import OfferButton from "../components/Common/OfferButton";
import { Button } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import VideoDrPalin from "../components/VideoDrPalin";
import FreeScrollPages from "../components/FreeScrollPages";
import Header from "../components/Common/Header";

const useStyle = makeStyles((theme) => ({
  conatiner: {
    display: "flex",
  },
  drawerMenuIcon: {
    position: "absolute",
  },
  delayClass: {
    transitionDelay: "500ms !important",
  },
  freeScrollPages: {},
  bodytag: {
    overflow: "hidden",
  },
  scrollButtonContainer: {
    margin: 0,
    display: "flex",
    justifyContent: "center",
    position: "fixed",
    bottom: 40,
    width: "100%",
  },
  scrollButtonContainerVideo: {
    top: 43,
    bottom: "unset",
  },
  scrollButtonHide: {
    display: "none",
  },
  scrollButtonContainerBeforeAfterPage: {
    bottom: 30,
    [theme.breakpoints.up("md")]: {
      bottom: 30,
    },
    [theme.breakpoints.up("lg")]: {
      bottom: 30,
    },
    [theme.breakpoints.up("xl")]: {
      bottom: 150,
    },
  },
  scrollButton: {
    width: "200px",
    borderRadius: 0,
    borderColor: "white !important",
    borderWidth: "2px !important",
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 6,
    transition: `all 250ms`,
    "&:hover": {
      backgroundColor: `rgba(186,160,121,0.5) !important`,
      transition: `all 250ms`,
      "& *": {
        color: "white !important",
      },
    },
  },
  scrollButtonNotNumberOne: {
    borderColor: `${theme.palette.primary.main} !important`,
    borderWidth: "1px !important",
    transition: `all 250ms`,
  },
  buttonLabel: {
    fontSize: "0.87rem !important",
    letterSpacing: "0.1rem",
    color: "white",
    fontWeight: 600,
    transition: `all 250ms`,
  },
  buttonLabelNotNumberOne: {
    color: theme.palette.primary.main,
    fontWeight: 400,
    transition: `all 250ms`,
  },
  iconDownRoot: {
    fontSize: "1.9rem !important",
    color: "white",
    transition: `all 250ms`,
  },
  iconDownRootNotNumberOne: {
    color: `${theme.palette.primary.main} !important`,
    transition: `all 250ms`,
  },
}));

const LandingPage = ({
  stopAudio,
  ifOffer,
  OnPageChange,
  isMenuOpen,
  closeMenuFunc,
  openMenuFunc,
  OpenPopUpModal,
  OpenPopUpModalFunc,
  isOffersDrawerOpenedOnce,
  setIsOffersDrawerOpenedOnce,
  offers,
}) => {
  const classes = useStyle();
  const [clickCondition, setclickCondition] = useState(false);
  const [clickTreatment, setclickTreatment] = useState(false);
  const [isNavDrawerOpen, setNavDrawerOpen] = useState(false);
  const [topOpen, setToOpen] = useState("");

  function setDrawerOpenFunc(value, e) {
    if (isNavDrawerOpen) {
      if (value === topOpen) {
        setNavDrawerOpen(!isNavDrawerOpen);
      } else if (value !== topOpen) {
        setToOpen(value);
      }
    } else {
      setNavDrawerOpen(!isNavDrawerOpen);
      setToOpen(value);
    }
  }

  function openSubNavListfunc(data, e) {
    if (data === "condition") {
      setclickTreatment(false);
      setclickCondition(!clickCondition);
    } else {
      setclickCondition(false);
      setclickTreatment(!clickTreatment);
    }
    setDrawerOpenFunc(data, e);
  }

  //Page No && Page scrolling things
  const [pageNo, setPageNo] = useState(0);

  const handlePageChange = (data) => {
    setPageNo(data);
  };

  let blockScrollUp = useMemo(() => {
    if (pageNo === 4) {
      return true;
    } else {
      return false;
    }
  }, [pageNo]);

  let blockScrollDown = useMemo(() => {
    if (pageNo === 4) {
      return true;
    } else {
      return false;
    }
  }, [pageNo]);

  //for slider
  const [beforeafterchange, setBeforeafterchange] = useState(false);

  function afterChangefunc() {
    setBeforeafterchange(true);
  }

  function beforeChangefunc() {
    setBeforeafterchange(false);
  }

  //offer drawer things
  const [offerDrawerOpen, setOfferDrawerOpen] = useState(false);
  const [initStop, setInitStop] = useState(false);

  function CloseOfferDrawer() {
    setOfferDrawerOpen(false);
    setInitStop(true);
    setDoNotOpenDrawerAgainFlag(true);
  }

  function openOfferDrawerFunc() {
    setOfferDrawerOpen(true);
    setDoNotOpenDrawerAgainFlag(false);
  }

  //useEffect section
  useEffect(() => {
    let timeoutId;
    if (!isOffersDrawerOpenedOnce && ifOffer && !offerDrawerOpen) {
      timeoutId = setTimeout(() => {
        openOfferDrawerFunc();
        setBeforeafterchange(true);
        setIsOffersDrawerOpenedOnce(true);
      }, 800);
    }
    return () => clearTimeout(timeoutId);
  }, [ifOffer, isOffersDrawerOpenedOnce]);

  useEffect(() => {
    document.body.className = classes.bodytag;
    return () => {
      document.body.className = "";
    };
  });

  //if the page changes from 0 to anything else and drawer is opne then it closes the opend offer drawer
  useEffect(() => {
    if (pageNo !== 0 && offerDrawerOpen) {
      setOfferDrawerOpen(false);
    }
  }, [pageNo]);

  const [doNotOpenDrawerAgainFlag, setDoNotOpenDrawerAgainFlag] = useState(
    false
  );

  useEffect(() => {
    let timeoutId;
    if (pageNo === 0 && !offerDrawerOpen) {
      if (ifOffer && !doNotOpenDrawerAgainFlag) {
        timeoutId = setTimeout(() => {
          openOfferDrawerFunc();
          setBeforeafterchange(true);
          setIsOffersDrawerOpenedOnce(true);
        }, 100);
      }
    }
    return () => clearTimeout(timeoutId);
  }, [pageNo]);

  function checkScrollDirection(event) {
    if (checkScrollDirectionIsUp(event)) {
      handlePageChange(pageNo - 1);
    } else {
      handlePageChange(pageNo + 1);
    }
  }

  function checkScrollDirectionIsUp(event) {
    if (event.wheelDelta) {
      return event.wheelDelta > 0;
    }
    return event.deltaY < 0;
  }

  function onWheel(event) {
    if (pageNo === 1) {
      checkScrollDirection(event);
    }
  }
  const [width, setInnerWidth] = useState(0);
  useLayoutEffect(() => {
    function updateWindowDimensions() {
      setInnerWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateWindowDimensions);
    updateWindowDimensions();
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  // console.log("width", width);
  return (
    <>
      {width < 860 && pageNo === 0 && !offerDrawerOpen && (
        <DrawerMenu
          OnPageChange={OnPageChange}
          isMenuOpen={isMenuOpen}
          ifOffer={ifOffer}
          closeMenuFunc={closeMenuFunc}
          openMenuFunc={openMenuFunc}
          OpenPopUpModal={OpenPopUpModal}
          setclickCondition={setclickCondition}
          setclickTreatment={setclickTreatment}
          OpenPopUpModalFunc={OpenPopUpModalFunc}
          isNavDrawerOpen={isNavDrawerOpen}
          topOpen={topOpen}
          setNavDrawerOpen={setNavDrawerOpen}
          setToOpen={setToOpen}
          openSubNavListfunc={openSubNavListfunc}
        />
      )}
      {width < 860 && pageNo > 0 && <Header offerPage ifoffer={ifOffer} />}
      {width > 860 && (
        <DrawerMenu
          OnPageChange={OnPageChange}
          isMenuOpen={isMenuOpen}
          ifOffer={ifOffer}
          closeMenuFunc={closeMenuFunc}
          openMenuFunc={openMenuFunc}
          OpenPopUpModal={OpenPopUpModal}
          setclickCondition={setclickCondition}
          setclickTreatment={setclickTreatment}
          OpenPopUpModalFunc={OpenPopUpModalFunc}
          isNavDrawerOpen={isNavDrawerOpen}
          topOpen={topOpen}
          setNavDrawerOpen={setNavDrawerOpen}
          setToOpen={setToOpen}
          openSubNavListfunc={openSubNavListfunc}
        />
      )}

      {width > 860 && (
        <div onWheel={(e) => onWheel(e)}>
          <ReactPageScroller
            pageOnChange={handlePageChange}
            customPageNumber={pageNo}
            blockScrollUp={blockScrollUp}
            blockScrollDown={blockScrollDown}
            renderAllPagesOnFirstRender={false}
          >
            <div className={classes.conatiner}>
              {/* Slider */}
              <div>
                <SliderWrapper
                  slideNo={pageNo}
                  afterChangefunc={afterChangefunc}
                  beforeChangefunc={beforeChangefunc}
                  OfferOpen={offerDrawerOpen}
                  handlePageChange={handlePageChange}
                />
                {/* Timebar */}
                <CSSTransition
                  in={true}
                  timeout={2000}
                  classNames="ProgressBarTrans"
                >
                  <Timebar
                    change={beforeafterchange}
                    OfferOpen={offerDrawerOpen}
                  />
                </CSSTransition>
              </div>
              {/* Offer Drawer Menu */}
              <OfferButton
                initStop={initStop}
                OpenOfferDrawer={openOfferDrawerFunc}
                OfferOpen={offerDrawerOpen}
                ifOffer={ifOffer}
                isOffersDrawerOpenedOnce={isOffersDrawerOpenedOnce}
              />
              <OfferMenu
                OfferOpen={offerDrawerOpen}
                OpenOfferDrawer={openOfferDrawerFunc}
                CloseOfferDrawer={CloseOfferDrawer}
                stopAudio={stopAudio}
                slideNo={pageNo}
                offers={offers}
              />
            </div>
            <div
              style={{
                width: "100%",
                height: "100%",
                overflowY: "hidden",
              }}
            >
              <VideoDrPalin
                onWheel={onWheel}
                handlePageChange={handlePageChange}
                pageNo={pageNo}
              />
            </div>
            {width > 860 && (
              <React.Fragment>
                <AestheCliniPage
                  openSubNavListfunc={openSubNavListfunc}
                  openMenuFunc={openMenuFunc}
                  handlePageChange={handlePageChange}
                  pageNo={pageNo}
                  width={width}
                />
              </React.Fragment>
            )}
            <MeetDrPalin handlePageChange={handlePageChange} pageNo={pageNo} />
            <FreeScrollPages
              pageNo={pageNo}
              OpenPopUpModal={OpenPopUpModal}
              OpenPopUpModalFunc={OpenPopUpModalFunc}
              width={width}
              handlePageChange={handlePageChange}
            />
          </ReactPageScroller>
        </div>
      )}
      {width < 860 && (
        <div onWheel={(e) => onWheel(e)} style={{ position: "fixed" }}>
          <ReactPageScroller
            pageOnChange={handlePageChange}
            customPageNumber={pageNo}
            blockScrollUp={pageNo === 2 ? true : false}
            blockScrollDown={blockScrollDown}
            renderAllPagesOnFirstRender={false}
          >
            <div className={classes.conatiner}>
              {/* Slider */}
              <div>
                <SliderWrapper
                  slideNo={pageNo}
                  afterChangefunc={afterChangefunc}
                  beforeChangefunc={beforeChangefunc}
                  OfferOpen={offerDrawerOpen}
                  handlePageChange={handlePageChange}
                />
                {/* Timebar */}
                <CSSTransition
                  in={true}
                  timeout={2000}
                  classNames="ProgressBarTrans"
                >
                  <Timebar
                    change={beforeafterchange}
                    OfferOpen={offerDrawerOpen}
                  />
                </CSSTransition>
              </div>
              {/* Offer Drawer Menu */}
              <OfferButton
                initStop={initStop}
                OpenOfferDrawer={openOfferDrawerFunc}
                OfferOpen={offerDrawerOpen}
                ifOffer={ifOffer}
                isOffersDrawerOpenedOnce={isOffersDrawerOpenedOnce}
              />
              <OfferMenu
                OfferOpen={offerDrawerOpen}
                OpenOfferDrawer={openOfferDrawerFunc}
                CloseOfferDrawer={CloseOfferDrawer}
                stopAudio={stopAudio}
                slideNo={pageNo}
                offers={offers}
              />
            </div>
            <div
              style={{
                width: "100%",
                height: "100%",
                overflowY: "hidden",
              }}
            >
              <VideoDrPalin
                onWheel={onWheel}
                handlePageChange={handlePageChange}
                pageNo={pageNo}
              />
            </div>
            <FreeScrollPages
              pageNo={pageNo}
              OpenPopUpModal={OpenPopUpModal}
              OpenPopUpModalFunc={OpenPopUpModalFunc}
              width={width}
              handlePageChange={handlePageChange}
            />
          </ReactPageScroller>
        </div>
      )}
      {/* {
                (pageNo == -1 )
                &&
                <div
                  className={clsx(classes.scrollButtonContainer, {
                    [classes.scrollButtonContainerVideo] : pageNo === 1,
                    [classes.scrollButtonContainerBeforeAfterPage] : pageNo === 2
                  })}
                >
                    <Button
                      variant='outlined'
                      classes={{
                          root : clsx(classes.scrollButton,{
                              [classes.scrollButtonNotNumberOne] : pageNo !== 0
                          }),
                          label : clsx(classes.buttonLabel,{
                              [classes.buttonLabelNotNumberOne] : pageNo !== 0
                          })
                      }}
                      onClick={(e)=>handlePageChange(pageNo+1)}
                      endIcon={
                          <KeyboardArrowDownIcon
                            classes={{
                                root : clsx(classes.iconDownRoot,{
                                    [classes.iconDownRootNotNumberOne] : pageNo !== 0
                                })
                            }}
                          />
                      }
                      tabIndex={'0'}
                      disableFocusRipple
                    >
                        SCROLL DOWN
                    </Button>
                </div>
            } */}
    </>
  );
};
export default LandingPage;
