import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import signGif from '../../assets/Signature.gif';
import signGif from "../../assets/Signature-Transperent.gif";

const useStyles = makeStyles((theme) => ({
  ReLoadGifDiv: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "& img": {
      width: "100%",
      height: "auto",
      border: "0 solid",
      outline: "none",
    },
  },
}));

const ReLoadGif = () => {
  const classes = useStyles();

  const [Gif, setGif] = useState(null);
  const [reload, setReload] = useState(true);
  const signDivRef = useRef(null);
  //

  useEffect(() => {
    if (signDivRef.current !== null) {
      var bounding = signDivRef.current.getBoundingClientRect();
      if (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <= window.innerWidth &&
        bounding.bottom <= window.innerHeight
      ) {
        setReload(true);
        //console.log('Element is in the viewport!');
      } else {
        setReload(false);
        //console.log('Element is NOT in the viewport!');
      }
    }
  });

  useEffect(() => {
    window.addEventListener("scroll", function() {
      if (signDivRef.current !== null) {
        var position = signDivRef.current.getBoundingClientRect();
        if (position.top >= 0 && position.bottom <= window.innerHeight) {
          setReload(true);
          //console.log('Element is fully visible in screen');
        } else {
          setReload(false);
          //console.log('Element is not visible in screen');
        }
      }
    });
    return () => {
      window.removeEventListener("scroll", function() {
        if (signDivRef.current !== null) {
          var position = signDivRef.current.getBoundingClientRect();
          if (position.top >= 0 && position.bottom <= window.innerHeight) {
            setReload(true);
            //console.log('Element is fully visible in screen');
          } else {
            setReload(false);
            //console.log('Element is not visible in screen');
          }
        }
      });
    };
  }, []);

  useEffect(() => {
    let reloadGif;
    if (reload === true) {
      reloadGif = setTimeout(function() {
        return setGif(`${signGif}?${new Date().getTime()}`);
      }, 100);
    } else {
      setGif(`${signGif}?${new Date().getTime()}`);
    }
    return () => {
      clearTimeout(reloadGif);
    };
  }, [reload]);

  return (
    <div ref={signDivRef} className={classes.ReLoadGifDiv}>
      <img alt="" src={Gif} />
    </div>
  );
};
export default ReLoadGif;
