import React from 'react';
import { makeStyles, darken } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Scrollbars from 'react-custom-scrollbars';
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    root:{
        maxWidth: "inherit",
        height: "75%",
        width: "70%",
    },
    paperModalInner:{
        height:"100%", 
        width:"100%",
        display:" flex", 
        flexDirection: "column",
    },
    ModalHeaderDiv:{
        height:"20%",
        width:"100%",
        background:theme.palette.primary.main,
        display:"flex",
    },
    ModalHeaderTitleDiv:{
        width:"60%",   
    },
    ModalHeaderTitleDivP:{
        margin:0,
        height: "100%",
        color:"white",
        fontFamily:"GothicRegular",
        textTransform:"uppercase",
        fontSize:"1.55rem",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    ModalHeaderCloseBtnDiv:{
        width:"37%", 
        color:"white", 
        alignItems:"center",
        display: "flex", 
        justifyContent:" flex-end",  
    },
    MarginDiv:{
        width:"3%"
    },
    ModalBodyDiv:{
        height:"80%", 
        background:"white",
        overflow:"auto",
        paddingBottom:45
    },
    contentDiv:{
        display:"flex",
        marginTop:50,
    },
    InnercontentDivDiv:{
        width:"49%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    InnercontentDivDivSpan:{
        color:theme.palette.secondary.main,
        fontFamily:"Open Sans",
        fontWeight: 700,
        fontSize:"1rem",
    },
    InnercontentDivDivP:{
        fontSize: ".9rem",
        fontFamily: "Open Sans",
        marginBlockStart: "2px",
        marginTop: "0px",
        marginBlockEnd: "10px",
    },
    InnerContentDiv:{
        width:"90%", 
        display: "flex", 
        flexDirection: "column",
        marginBottom:"15px",
    },
    listtherefore:{
        listStyleType: "none",
        marginBlockStart: 0,
        marginBlockEnd: 0,
        
    }, 
    listthereforeli:{
        margin:"5px 0px 10px 0px",
        fontFamily:"Open Sans",
        fontSize:".9rem",
        '&::before':{
            content: `"•"`,
            color: theme.palette.secondary.main,
            display: "inline-block",
            width:" 1em",
            marginLeft:" -1em",
        }
    },    
    dividerLine:{
        width:"2%",
    },
    dividerLineHR:{
        border:"none",
        borderLeft:"1px solid hsla(200, 10%, 50%,100)",
        height:"90%",
        width:1,       
    },
    scrollBartracker:{
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
    },
    FirstPara:{
        marginBlockStart: "5px !important",
        fontSize: ".9rem",
        fontFamily: "Open Sans",

    },
    ulHeader:{
        marginBlockEnd: "0px !important",
        fontSize: ".9rem",
        fontFamily: "Open Sans",
    },
    link : {
        color : theme.palette.primary.main,
        cursor : 'pointer',
        fontWeight : 600,
        '&:hover' : {
            color : darken(theme.palette.primary.main, 0.2)
        }
    },
    goldBold : {
        color : theme.palette.primary.main,
        cursor : 'pointer',
        fontWeight : 600,
    }
}));

export default function PopUpModal({OpenPopUpModal, closePopUpModalFunc , whichModal, closeMainMenuFunc}) {
  const classes = useStyles();

  const history = useHistory();

  const redirectContactUs = _ => {
      closePopUpModalFunc()
      closeMainMenuFunc()
      history.push('/contact-us');
  }

  return (
    <Dialog
        closeAfterTransition={true}
        style={{zIndex:1301}}
        onEscapeKeyDown={closePopUpModalFunc}
        onBackdropClick={closePopUpModalFunc}
        classes={{
            paperScrollPaper: classes.root}}
        open={OpenPopUpModal}>
            {  
                (whichModal ==="TandC")&&
                    <div className={classes.paperModalInner} >
                        <div className={classes.ModalHeaderDiv}>
                            <div className={classes.ModalHeaderTitleDiv}>
                                <p className={classes.ModalHeaderTitleDivP} >
                                    Terms & Conditions
                                </p>
                            </div>
                            <div className={classes.ModalHeaderCloseBtnDiv}>
                                <CloseIcon style={{cursor:"pointer"}} onClick={()=>{
                                    closePopUpModalFunc()
                                }}/>
                            </div>
                            <div className={classes.MarginDiv}>
                            </div>
                        </div>
                        <div className={classes.ModalBodyDiv}>
                            <Scrollbars 
                                renderThumbVertical={props => <div {...props} className={classes.scrollBartracker}/>}
                                autoHeight={false}>
                                <div className={classes.contentDiv}>
                                    <div className={classes.InnercontentDivDiv}>
                                        <div className={classes.InnerContentDiv} >    
                                        <span className={classes.InnercontentDivDivSpan} >Access to the Site</span>
                                            <p className={classes.FirstPara}>
                                                Connection to the www.drpalin.co.uk site entails acceptance of this Legal Notice.
                                            </p>
                                            <p className={classes.InnercontentDivDivP}>
                                                Dr Palin Aesthetics reserves the right to modify and update this Legal
                                                Notice at any time, and it is specified that any modifications and updates
                                                of the Legal Notice shall apply as soon as they are posted online.    
                                            </p>
                                        </div>
                                        <div className={classes.InnerContentDiv} >    
                                        <span className={classes.InnercontentDivDivSpan} >Intellectual Property</span>
                                                <p className={classes.FirstPara}>
                                                    Dr Palin Aesthetics is the owner of the domain name of the Site.</p>
                                                <p className={classes.InnercontentDivDivP}>This Site and all software used in connection with the Site may contain confidential information or information protected by intellectual property legislation or other laws.</p>
                                                <p className={classes.InnercontentDivDivP}>Therefore, unless otherwise stated, the intellectual property rights on the documents contained on the Site and each of the items created for the Site are the exclusive property of Dr Palin Aesthetics, and Dr Palin Aesthetics does not grant any license nor any other right on these items, with the exception of the right of consultation granted to Internet users.</p>
                                                <p className={classes.InnercontentDivDivP}>In particular, the rights relating to trademarks and other intellectual property rights mentioned on the Site are the property of Dr Palin Aesthetics. The reproduction of any document published on the Site is permitted exclusively for personal and private use for non-commercial purposes. Any other type of reproduction and any use of copies for other purposes are explicitly prohibited.</p>
                                                <p className={classes.InnercontentDivDivP}>Regarding software, it is forbidden to copy, modify, create a derivative work, reverse the design or assembly or in any other way attempt to find the source code (except as provided by law), sell, assign, sub-license or transfer in any way any right in the software. Similarly, it is also forbidden to modify the software or to use modified versions of the software and in particular (without this enumeration being in any way restrictive) to obtain unauthorized access to the service and to access the Site by means other than through the interface provided to you by Dr Palin Aesthetics for that purpose.</p>
                                        </div>
                                    </div>
                                    <div className={classes.dividerLine}>
                                    <hr className={classes.dividerLineHR}/>
                                    </div>
                                    <div className={classes.InnercontentDivDiv}>
                                        <div className={classes.InnerContentDiv} >    
                                        <span className={classes.InnercontentDivDivSpan} >Limitation of Liability</span>
                                                <p className={classes.FirstPara}>
                                                    Dr Palin Aesthetics undertakes to ensure, to the extent possible, that information published on the Site is correct and up-to-date. Nevertheless, Dr Palin Aesthetics cannot guarantee the accuracy and completeness of information provided on the Site, including information from third parties.
                                                </p>
                                                <p className={classes.ulHeader}> Therefore, Dr Palin Aesthetics disclaims any responsibility:</p>
                                                <ul className={classes.listtherefore} >
                                                    <li className={classes.listthereforeli}>for any possible interruption of the Site; </li>
                                                    <li className={classes.listthereforeli}>for any malfunction;</li>
                                                    <li className={classes.listthereforeli}>for any errors or omissions in information published on the Site;</li>
                                                    <li className={classes.listthereforeli}>for any damage resulting from the unauthorized intrusion of a third party having modified information supplied on the Site;</li>
                                                    <li className={classes.listthereforeli}>for any direct or indirect damage, regardless of the cause, nature, origin or consequences.</li>
                                                </ul>
                                        </div>
                                        <div className={classes.InnerContentDiv} >    
                                            <span className={classes.InnercontentDivDivSpan} >Intellectual Property</span>
                                            <p className={classes.FirstPara}>
                                                The Site may include links to other websites or Internet pages. Dr Palin Aesthetics has no control over such external sites and items. Therefore, Dr Palin Aesthetics cannot be held responsible for any such external sites and items and disclaims any liability for the content, advertising, products, services and anything else available on such sites or via an external link. Furthermore, Dr Palin Aesthetics cannot be held liable for any actual or alleged damages or losses consequential on or in connection with the access, use or the fact of having relied on the content, goods or services available on such external sites or sources.
                                            </p>
                                        </div>
                                        <div className={classes.InnerContentDiv} >    
                                            <span className={classes.InnercontentDivDivSpan} >Modifications</span>
                                            <p className={classes.FirstPara}>
                                                Dr Palin Aesthetics reserves the right to correct the content of the Site at any time and without prior notice to the Internet user. Thus, any modification is deemed accepted without reservation by any Internet user who accesses the Site subsequent to its being posted online. 
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Scrollbars>
                        </div>    
                    </div>

            }
            {
                (whichModal ==="Privacy")&&
                    <div className={classes.paperModalInner} >
                            <div className={classes.ModalHeaderDiv}>
                                <div className={classes.ModalHeaderTitleDiv}>
                                    <p className={classes.ModalHeaderTitleDivP}>
                                        Privacy Policy 
                                    </p>
                                </div>
                                <div className={classes.ModalHeaderCloseBtnDiv}>
                                    <CloseIcon style={{cursor:"pointer"}} onClick={()=>{
                                        closePopUpModalFunc()
                                    }}/>
                                </div>
                                <div className={classes.MarginDiv}>
                                </div>
                            </div>
                            <div className={classes.ModalBodyDiv}>
                                <Scrollbars 
                                    renderThumbVertical={props => <div {...props} className={classes.scrollBartracker}/>}
                                    autoHeight={false}>
                                    <div className={classes.contentDiv}>
                                    <div className={classes.InnercontentDivDiv}>
                                        <div className={classes.InnerContentDiv} >    
                                            <span className={classes.InnercontentDivDivSpan} >Personal Data Protection</span>
                                            <p className={classes.FirstPara}>
                                                Dr Palin Aesthetics collects personal data, including via the <span className={classes.link} onClick={redirectContactUs}>Contact Us</span> tab on the Site and/or via other questionnaires.
                                            </p>
                                            <p className={classes.ulHeader} >
                                                Dr Palin Aesthetics collects personal data fairly and lawfully. Dr Palin Aesthetics undertakes to preserve the confidentiality and security of personal data provided by visitors to our Site.    
                                            </p>
                                            <ul className={classes.listtherefore} >
                                                <li className={classes.listthereforeli}>Controller: the Controller is Dr Palin Aesthetics</li>
                                                <li className={classes.listthereforeli}>Purpose of the processing: the provision of personal data makes it possible to respond to messages sent by visitors to our Site via the <span className={classes.link} onClick={redirectContactUs}>Contact Us</span> tab and/or via other questionnaires</li>
                                                <li className={classes.listthereforeli}>Optional or mandatory nature of responses: an asterisk in the personal data collection form indicates that the response is mandatory.</li>
                                                <li className={classes.listthereforeli}>Possible consequences of failure to respond: if the fields with an asterisk are not filled in, we cannot assure you that your request will be handled.</li>
                                                <li className={classes.listthereforeli}>Data recipients: the personal data collected will be used by people in the Dr Palin Aesthetics communication department to handle requests and questions posted on the Site via the <span className={classes.link} onClick={redirectContactUs}>Contact Us</span> tab and/or via other questionnaires.</li>
                                                <li className={classes.listthereforeli}>Rights of access, rectification, cancellation and opposition: every visitor has a right to access and to have rectified or deleted any data relating to him, and the right to oppose for legitimate reasons the processing of such data.</li>
                                                <li className={classes.listthereforeli}>Storage of personal data: Dr Palin Aesthetics undertakes to use information concerning personal data only for the time necessary to achieve the purposes described above.</li>
                                            </ul>
                                            <p className={classes.InnercontentDivDivP}>
                                                In order to exercise the above rights, the visitor is invited to send an e-mail to: 
                                                <span className={classes.goldBold}>&nbsp;enquiries@drpalin.co.uk</span>
                                            </p>
                                        </div>
                                        <div className={classes.InnerContentDiv} >    
                                            <span className={classes.InnercontentDivDivSpan} >Cookies</span>
                                            <p className={classes.FirstPara}>
                                                Dr Palin Aesthetics may use cookies stored in the computer of visitors to its Site. In general terms, a cookie stores information relating to the navigation of a visitor's computer (pages, time and the date of consultation, etc), which Dr Palin Aesthetics will be able to read on subsequent visits by these visitors. These cookies do not permit us to identify the visitors.
                                            </p>
                                            <p className={classes.InnercontentDivDivP}> 
                                                Moreover, these cookies are designed to permit Dr Palin Aesthetics to gather information on the use of the Dr Palin Aesthetics website and to compile visitor statistics.
                                            </p>
                                            <p className={classes.InnercontentDivDivP}>
                                                Dr Palin Aesthetics undertakes to use the information obtained through the implementation of cookies only for the time required for achievement of the purposes indicated above.
                                            </p>
                                                
                                                
                                        </div>
                                    </div>
                                    <div className={classes.dividerLine}>
                                        <hr className={classes.dividerLineHR}/>
                                    </div>
                                    <div className={classes.InnercontentDivDiv}>
                                        <div className={classes.InnerContentDiv} >
                                            <p className={classes.InnercontentDivDivP}>
                                                Every visitor has the right to oppose the recording of cookies by configuring their browser as follows:
                                            </p>
                                            <p className={classes.ulHeader}>
                                                For Microsoft Internet Explorer 6.0 and above:
                                            </p>
                                            <ul className={classes.listtherefore} >
                                                <li className={classes.listthereforeli}> Choose the "Tools" menu, then "Internet Options". </li>
                                                <li className={classes.listthereforeli}> Click on the "Confidentiality" tab.</li>
                                                <li className={classes.listthereforeli}> Select the desired level using the cursor.</li>
                                            </ul>
                                            <p className={classes.ulHeader}>For Microsoft Internet Explorer 5:</p>
                                                <ul className={classes.listtherefore} >
                                                    <li className={classes.listthereforeli}> Choose the "Tools" menu, then "Internet Options". </li>
                                                    <li className={classes.listthereforeli}> Click on the "Security" tab.</li>
                                                    <li className={classes.listthereforeli}> Select "Internet" then "Custom Level".</li>
                                                    <li className={classes.listthereforeli}> Under the heading "cookies", choose the option that suits you.</li>
                                                </ul>
                                            <p className={classes.ulHeader}>
                                                For Netscape 6.X and 7. X:
                                            </p>
                                            <ul className={classes.listtherefore} >
                                                <li className={classes.listthereforeli}> Choose the menu "Edit">"Preferences". </li>
                                                <li className={classes.listthereforeli}> Privacy and Security.</li>
                                                <li className={classes.listthereforeli}> Cookies.</li>
                                            </ul>
                                            <p className={classes.ulHeader}>
                                                For Firefox:
                                            </p>
                                            <ul className={classes.listtherefore} >
                                                <li className={classes.listthereforeli}> Choose the menu "Tools">"Options".</li>
                                                <li className={classes.listthereforeli}> Click on the "Privacy" option.</li>
                                            </ul>
                                            <p className={classes.ulHeader}>For Safari 3.x for Mac OS X:</p>
                                            <ul className={classes.listtherefore} >
                                                <li className={classes.listthereforeli}> Choose the menu "Edit". </li>
                                                <li className={classes.listthereforeli}> Click on the option "Preferences".</li>
                                                <li className={classes.listthereforeli}> Click on the option "Security".</li>
                                                <li className={classes.listthereforeli}> Click on "Display cookies".</li>
                                            </ul>
                                            <p className={classes.InnercontentDivDivP}>
                                            However, some features of the Dr Palin Aesthetics website can no longer be used if the visitor refuses for them to be installed.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                </Scrollbars>
                            </div>
                        </div>
                
            }
    </Dialog>
  );
};  