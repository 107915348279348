import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx'
//import { message } from '../../utils/helper';

const useStyle = makeStyles(theme=>({ 
    Timebar:{
        position:"absolute",
        right:"0px",
        top:"0px",
        height:"100%",
        width:"0.25em",
        zIndex : 1200,
        transition : 'right 600ms cubic-bezier(0, 0, 0.2, 1)'
    },
    customAnimation : {
        height:"0",
        animation : '$myani 5s',
        backgroundColor:theme.palette.primary.main

    },
    '@keyframes myani' : {
        from:{height: "0%"},
        to:{height: "100%"}
    },
    OfferOpenClass : {
        right : '350px',
        transition : 'right 400ms cubic-bezier(0, 0, 0.2, 1)'
    }
}));

const Timebar = (props) => {
    const classes = useStyle();

    const {
        change,
        OfferOpen
    } = props;

    return(
        <div className={clsx(classes.Timebar,{
            [classes.OfferOpenClass] : OfferOpen
        })} >
            <div className={clsx({
                [classes.customAnimation]:change,
                
            })}></div>
        </div>
    )
}

export default Timebar;