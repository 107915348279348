import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "../src/assets/style/index.css";
import "../src/assets/style/style.css";
// import 'imagehover.css/css/imagehover.min.css';
// Internet Explorer 6-11o
const isIE = /*@cc_on!@*/ false || !!document.documentMode;
console.log(isIE);
ReactDOM.render(<App />, document.getElementById("root"));
//TODO 06/MArch/2020
//TODO Dashed URL(Done)
//TODO Props to make style(Done)
//TODO Top of the component meet dr plain react slick slider(Done)
//TODO slide.current check null exception(Done)
//TODO remove Selector(Done)
