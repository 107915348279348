import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    main_container : {
        overflow : 'hidden'
    },
    conatainer : {
        position : 'relative',
        height : 0,
        paddingBottom : '56.25%',
        overflow : 'hidden',
        '& iframe' : {
            position : 'absolute',
            height : '100%',
            width : '100%',
            left : 0,
            top : 0
        }
    }
}))

export default ({slideNo}) => {
    const classes=  useStyles()
    return (
        <div className={classes.main_container}>
            <div
              className={classes.conatainer}
            >
                <iframe
                  src="https://player.vimeo.com/video/430640188"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  title={"Bhavna Patel Testimonial"}
                  className={classes.iframe}
                />
            </div>
        </div>
    )    
}
