import React from "react";
import {
  makeStyles,
  Typography,
  CircularProgress,
  fade,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import logoimg from "../assets/loding-img.png";

const useStyles = makeStyles((theme) => ({
  fallbackLoadingClass: {
    position: "fixed",
    top: 0,
    width: `100vw`,
    overflow: "hidden",
    height: 4,
    zIndex: 10000,
  },
  container: {
    height: "100vh",
    width: "100vw",
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: theme.palette.secondary.main,
    overflow: "hidden",
    background: fade(theme.palette.primary.main, 0.125),
    backdropFilter: "blur(4px)",
    zIndex: 9999,
  },
  loadingImgDiv: {
    width: 250,
    height: 250,
    [theme.breakpoints.down("xs")]: {
      height: 250,
      width: 250,
    },
  },
  loadingImg: {
    width: "100%",
    height: "100%",
  },
  loadingImgCls: {
    width: "100%",
    overflow: "hidden",
    height: 9,
    zIndex: 10000,
    top: "-4px",
    backgroundColor: "#FFF",
  },
}));

export default function Loading(props) {
  const classes = useStyles();
  return (
    <>
      <LinearProgress
        color="secondary"
        className={classes.fallbackLoadingClass}
      />
      <div className={classes.container}>
        {/* <Typography variant="h4" align='center'>
                    Please wait...
                </Typography>
                <CircularProgress
                  color={"secondary"}
                  disableShrink
                /> */}
        <div className={classes.loadingImgDiv}>
          <img alt="slide-img" src={logoimg} className={classes.loadingImg} />
          {/* <LinearProgress color='secondary' className={classes.loadingImgCls}/> */}
        </div>
      </div>
    </>
  );
}
