import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles((theme) => ({
  main_container: {
    height: "calc(100% - 124px)",
    overflow: "hidden",
    position: "relative",
    paddingTop: 124,
  },
  conatainer: {
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      height: "88%",
    },
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  iframe: {
    width: "80%",
    height: "100%",

    [theme.breakpoints.up("md")]: {
      paddingBottom: "150px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingBottom: "200px",
    },
    // [theme.breakpoints.down("sm")]: {
    //     width:'100=%'
    //  },
    // marginTop:100
  },
  btnDiv: {
    margin: 0,
    display: "flex",
    justifyContent: "center",
    bottom: 40,
    width: "100%",
    position: "fixed",
  },
  scrollButtonContainerVideo: {
    top: 40,
    bottom: "unset",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      marginTop: 60,
      top: "60px",
    },
  },
  buttonLabel: {
    color: theme.palette.primary.main,
    fontSize: ".84rem !important",
    letterSpacing: "0.2rem",
  },
  scrollButton: {
    width: "200px",
    borderRadius: 0,
    borderWidth: "1px !important",
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 6,
    animation: "$intropic 0s 3s forwards",
    visibility: "hidden",
    "&:hover": {
      backgroundColor: `rgba(186,160,121,0.5) !important`,
      transition: `all 250ms`,
      "& *": {
        color: "white !important",
      },
    },
  },
  "@keyframes intropic": {
    to: { visibility: "visible" },
  },
  scrollButtonNotNumberOne: {
    borderColor: `${theme.palette.primary.main} !important`,
    borderWidth: "1px !important",
    transition: `all 250ms`,
  },

  iconDownRoot: {
    fontSize: "1.9rem !important",
    color: theme.palette.primary.main,
    transition: `all 250ms`,
  },
  iconDownRoot: {
    fontSize: "1.9rem !important",
    color: theme.palette.primary.main,
    transition: `all 250ms`,
  },
  buttonLabelNotNumberOne: {
    color: theme.palette.primary.main,
    fontWeight: 400,
    transition: `all 250ms`,
  },
  iconDownRootNotNumberOne: {
    color: `${theme.palette.primary.main} !important`,
    transition: `all 250ms`,
  },
}));

export default ({ onWheel, pageNo, handlePageChange }) => {
  const classes = useStyles();

  // const iframeRef = React.useRef()

  // useEffect(
  //     () => {
  //         setTimeout(
  //             () => {
  //                 let myFrame = ReactDOM.findDOMNode(iframeRef.current)
  //                 myFrame.contentDocument.body.addEventListener = ('wheel',function(){
  //                     console.log(`this is the error`)
  //                 })
  //             },
  //             5000
  //         )
  //     },
  //     []
  // )

  return (
    <>
      <div className={classes.main_container} onWheel={(e) => onWheel(e)}>
        <div className={classes.conatainer}>
          <iframe
            src={
              pageNo === 1
                ? "https://player.vimeo.com/video/430639551?autoplay=0"
                : "https://player.vimeo.com/video/430639551?autoplay=0"
            }
            frameBorder="0"
            allow="fullscreen"
            title={"Dr Palin"}
            className={classes.iframe}
            onWheel={(e) => onWheel(e)}
            //   ref={iframeRef}
          />
        </div>
        <div
          className={clsx(classes.btnDiv, classes.scrollButtonContainerVideo)}
        >
          <Button
            variant="outlined"
            classes={{
              root: clsx(
                classes.scrollButton,
                classes.scrollButtonNotNumberOne
              ),
              label: clsx(classes.buttonLabel, classes.buttonLabelNotNumberOne),
            }}
            onClick={(e) => handlePageChange(pageNo + 1)}
            endIcon={
              <KeyboardArrowDownIcon
                classes={{
                  root: clsx(
                    classes.iconDownRoot,
                    classes.iconDownRootNotNumberOne
                  ),
                }}
              />
            }
            tabIndex={"0"}
            disableFocusRipple
          >
            SCROLL DOWN
          </Button>
        </div>
      </div>
    </>
  );
};
