import React,{useState, useEffect} from 'react';
import { makeStyles, lighten }  from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import LogoPrimary from '../Common/LogoPrimary';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import { SwitchTransition} from 'react-transition-group';
import { withRouter } from "react-router-dom";

const useStyle = makeStyles(theme=>({ 
    root:{
        borderRadius: "0px",
        padding: "0px 15px",
        fontFamily:"GothicRegular",
    },
    drawerMenu:{
        width:"350px",
        height: "100%",
        position:"absolute"
    },
    drawerMenuOpen:{
        display : 'grid',
        gridTemplateRows : 'max-content max-content',
        zIndex : 1500
    },
    imageContainer : {
        minHeight : '40vh',
        height: "40vh",
	    maxHeight: "40vh",
        display : 'flex',
        justifyContent : 'center',
        alignItems : 'center',
        marginBottom : 12,
        [theme.breakpoints.only("md")] : {
            margin : 0,
        },
      
    },
    Image:{
        maxHeight : '100%',
        maxWidth : '101%',
        minWidth : 'auto',
        minHeight : 'auto',
        height : 'auto',
        width : '101%',
        [theme.breakpoints.down(theme.breakpoints.values.ssm)] : {
            objectFit:'contain',
        }
    },
    findMore:{
        paddingTop: "25px",
        [theme.breakpoints.up("sm")] : {
            paddingTop : 20,
        }
    },
    offerTitle:{
        lineHeight:"2",
        fontWeight:"bold",
        fontFamily:"GothicRegular",
        fontSize : '1.2rem'
    },
    title:{
        fontFamily:"GothicRegular",
        fontSize: ".85rem",
        fontWeight: "bold",
        textAlign: "center",
    },
    exclusiveoffer:{
        paddingTop:"10px",
        textAlign: "center",
        paddingBottom: "20px",
    },
    innerMainContainer : {
        minWidth : '100%',
        height : '85%',
        paddingTop : 16,
        display : 'grid',
        flexDirection : 'column',
        justifyItems : 'start',
        alignItems : 'center',
        overflow: "hidden",
        [theme.breakpoints.only("md")] : {
            padding : 0,
        }
    },
    footMenu:{
        alignSelf:"flex-start"
    },
    offerHeader:{
        justifyContent: "space-between",
        alignSelf : 'end'
    },
    offerHeaderText:{
        textAlign:"center",
        margin : '30px auto 0 auto',
        [theme.breakpoints.only("md")] : {
            margin : '40px auto 0 auto',
        }

    },
    paperAnchorDockedRight : {
        borderLeft : 'none'
    },
    exclusiveofferTypo : {
        fontSize : '1.05rem'
    },
    fadeContainer : {
        minWidth : '100%'
    },
    closeBtn:{  
        display : 'flex', 
        justifyContent : 'center', 
        marginTop : 16,
        [theme.breakpoints.only("md")] : {
            margin : 12,
        }
    },
    closeIcon:{
        backgroundColor:"white",
        color:theme.palette.primary.main,
        boxShadow:"none",
        '&:hover':{
            backgroundColor:lighten(theme.palette.primary.main, 0.85)
        }
    }
}));

// const offerList = [
//     {
//         no:0,
//         img:img1,
//         title:"OFFER #13",
//         description:"THE APOLLO FACELIFT"
//     },
//     {
//         no:1,
//         img:img1,
//         title:"OFFER #14",
//         description:"THE APOLLO FACELIFT1"
//     },
//     {
//         no:2,
//         img:img1,
//         title:"OFFER #15",
//         description:"THE APOLLO FACELIFT2"
//     },
//     {
//         no:3,
//         img:img1,
//         title:"OFFER #16",
//         description:"THE APOLLO FACELIFT3"
//     },
//     {
//         no:4,
//         img:img1,
//         title:"OFFER #17",
//         description:"THE APOLLO FACELIFT4"
//     }
// ];

const OfferMenu = ({OfferOpen, CloseOfferDrawer, slideNo, history, offers : {offers_data}}) => {
    const classes = useStyle();
    const [offer, setOffer] = useState(offers_data[0]);
    const [ChangeOffer, setChangeOffer] = useState(true);

    useEffect(()=>{
        if (slideNo === 0){
            let changeOfferTimeout = setInterval(()=>{
                setChangeOffer(!ChangeOffer)
                // if(offer.no < (offers_data.length-1))
                // {
                //     setOffer(offers_data[offer.no + 1])
                // } 
                // else if(offer.no === (offers_data.length-1))
                // {
                //     setOffer(offers_data[0])
                // }
                if(offers_data.length > 1){
                    // console.log(`-------------------------`)
                    // console.log(offer)
                    // console.log(offers_data.indexOf(offer),`first`)
                    // console.log(offers_data.length - 1,`second`)
                    // console.log(`-------------------------`)
                    if(offers_data.indexOf(offer) === offers_data.length - 1){
                        setOffer(offers_data[0])
                    }
                    else{
                        setOffer(offers_data[offers_data.indexOf(offer) + 1])
                    }
                }
            },5000)
            return(()=>{
                clearInterval(changeOfferTimeout);
            })
        }
    });
   
    return(
            <Drawer
                transitionDuration={{
                    enter : 400,
                    exit : 400
                }}
                classes={{
                    paper: clsx(classes.drawerMenu),
                    paperAnchorDockedRight : classes.paperAnchorDockedRight
                }}
                anchor='right'
                variant='persistent'
                className={clsx({[classes.drawerMenuOpen]:OfferOpen})}
                open={OfferOpen && offer ? true : false}
                onClose = {CloseOfferDrawer}
            >
                <div
                    className={classes.closeBtn}>
                    <Button color="primary" classes={{root:classes.closeIcon}} onClick={CloseOfferDrawer} aria-label="edit">
                        <CloseIcon />
                    </Button>
                </div>
                <Grid 
                    container 
                    className={classes.innerMainContainer}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        className={classes.offerHeader}>
                        <Grid 
                            item 
                            className={classes.offerHeaderText}>
                            <LogoPrimary coloredText={true}/>
                            <div className={classes.exclusiveoffer}>
                            <Typography
                            variant="subtitle2"
                            className={classes.exclusiveofferTypo}
                            >
                                EXCLUSIVE OFFERS
                            </Typography>
                            </div> 
                        </Grid>
                    </Grid>
                    {
                        offer
                        ?
                    <SwitchTransition>
                        <Fade 
                            in={ChangeOffer}
                            key={offer.id.toString()} 
                            timeout={1000}
                            appear>
                            <div className={classes.fadeContainer}>
                                <Grid item className={classes.imageContainer}>
                                    <img 
                                    alt="pic1"
                                    className={classes.Image}  
                                    src={offer.image_url}/>
                                </Grid>
                                <Grid
                                    container
                                    className={classes.footMenu}
                                    direction="column"
                                    justify="center"
                                    alignItems="center">
                                    <Grid item>
                                        <Typography 
                                            className={classes.title}  
                                            color="primary">  
                                        {offer.subtitle}
                                        </Typography>
                                        <Typography className={classes.offerTitle}> 
                                            {/* {offer.description} */}
                                            {offer.title}
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.findMore}>
                                        <Button
                                        onClick={()=>history.push('/offers')}
                                        className={classes.root} 
                                        borderradius={0} 
                                        variant="outlined" 
                                        color="primary"
                                        disableRipple
                                        disableTouchRipple
                                        >FIND OUT MORE</Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Fade>
                    </SwitchTransition>
                    :
                    null
                    }
                </Grid>
            </Drawer>
    )
};

export default withRouter(OfferMenu);