import React, { useState } from "react";
import Footer from "../Common/Footer";
import img1 from "../../assets/image/palin.jpg";
import img2 from "../../assets/image/consultation.jpg";
import img3 from "../../assets/image/clinic.jpg";
import img4 from "../../assets/image/image.jpg";
import instagram from "../../assets/image/insta2.png";
import { makeStyles } from "@material-ui/core/styles";
//import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import MyTypograph from "../Common/MyTypograph";

const useStyles = makeStyles((theme) => ({
  mainContainerGrid: {
    height: "100vh",
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  storyTitle: {
    display: "flex",
    marginTop: 50,
    marginBottom: 50,
    [theme.breakpoints.down("sm")]: {
      margin: "20px 0px",
    },
  },
  storyTitleText: {
    color: "#baa07c",
    fontFamily: "GothicRegular",
    letterSpacing: "5px",
    fontSize: "1.35rem",
    textTransform: "uppercase",
    alignSelf: "center",
  },
  imgGallery: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    margin: "auto",
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      margin: "auto",
      flexDirection: "column",
      height: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 25,
      flexDirection: "column",
      height: "auto",
    },
  },
  imgGalleryDiv: {
    height: "auto",
    margin: "0px 5px",
    [theme.breakpoints.down("sm")]: {
      minHeight: 200,
      "&>figure": {
        minHeight: 200,
      },
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: 200,
      "&>figure": {
        minHeight: 200,
      },
    },
  },
  footer: {
    width: "100%",
    height: "550px",
    [theme.breakpoints.down("xs")]: {
      height: "600px",
      paddingBottom: "130px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "600px",
      paddingBottom: "80px",
    },
  },
  img1GalleryEach: {
    objectFit: "cover",
    width: "220px",
    height: "200px",
    cursor: "pointer",
    transition: "all .6s ease",
    "&:hover": {
      filter: "grayscale(0%) !important",
      opacity: "100% !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "350px",
      minHeight: 200,
    },
    [theme.breakpoints.down("xs")]: {
      width: "350px",
      height: 125,
      minHeight: 200,
    },
  },
  img2GalleryEach: {
    objectFit: "cover",
    width: "350px",
    height: "200px",
    cursor: "pointer",
    transition: "all .6s ease",
    "&:hover": {
      filter: "grayscale(0%) !important",
      opacity: "100% !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "350px",
      minHeight: 200,
    },
    [theme.breakpoints.down("xs")]: {
      width: "350px",
      height: 125,
      minHeight: 200,
    },
  },
  img3GalleryEach: {
    objectFit: "cover",
    width: "250px",
    height: "200px",
    cursor: "pointer",
    transition: "all .6s ease",
    "&:hover": {
      filter: "grayscale(0%) !important",
      opacity: "100% !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "350px",
      minHeight: 200,
    },
    [theme.breakpoints.down("xs")]: {
      width: "350px",
      minHeight: 200,
    },
  },
  img4GalleryEach: {
    objectFit: "cover",
    width: "250px",
    height: "200px",
    cursor: "pointer",
    transition: "all .6s ease",
    "&:hover": {
      filter: "grayscale(0%) !important",
      opacity: "100% !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "350px",
      minHeight: 200,
    },
    [theme.breakpoints.down("xs")]: {
      width: "350px",
      minHeight: 200,
    },
  },
  hovered: {
    opacity: "50%",
    filter: "grayscale(100%)",
    transition: "all .6s ease",
  },
  successStoriesText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      letterSpacing: "0.4rem",
      top: 0,
      margin: "10px 0px",
    },
  },
  imageCaption: {
    textTransform: "uppercase",
    fontSize: "0.8rem",
  },
  figureImage: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    overflow: "visible",
  },
  instagramImage: {
    width: 120,
    height: 120,
    opacity: "1 !important",
  },
}));

const IamgeGallery = ({ OpenPopUpModalFunc }) => {
  const classes = useStyles();
  const [hover, sethover] = useState(false);

  function HoverEffect() {
    sethover(true);
  }

  function HoveroutEffect() {
    sethover(false);
  }

  return (
    <Grid container className={classes.mainContainerGrid}>
      <div className={classes.mainContainer}>
        <div className={classes.storyTitle}>
          <MyTypograph variant="Title" className={classes.successStoriesText}>
            Instagram
          </MyTypograph>
        </div>
        <div className={classes.imgGallery}>
          <div className={classes.imgGalleryDiv}>
            <figure
              className="imghvr-hinge-down"
              onMouseEnter={HoverEffect}
              onMouseLeave={HoveroutEffect}
              style={{ backgroundColor: "white" }}
            >
              <img
                className={clsx(classes.img1GalleryEach, {
                  [classes.hovered]: hover,
                })}
                src={img1}
                alt="img"
              />
              <figcaption style={{ backgroundColor: "#baa07c", padding: 5 }}>
                <div className={classes.figureImage}>
                  <img
                    src={instagram}
                    className={classes.instagramImage}
                    alt="img"
                  />
                  <span className={classes.imageCaption}> visit instagram</span>
                </div>
              </figcaption>
            </figure>
          </div>
          <div className={classes.imgGalleryDiv}>
            <figure
              className="imghvr-hinge-down"
              onMouseEnter={HoverEffect}
              onMouseLeave={HoveroutEffect}
              style={{ backgroundColor: "white" }}
            >
              <img
                className={clsx(classes.img2GalleryEach, {
                  [classes.hovered]: hover,
                })}
                src={img2}
                alt="img"
              />
              <figcaption style={{ backgroundColor: "#baa07c", padding: 5 }}>
                <div className={classes.figureImage}>
                  <span>
                    <img
                      src={instagram}
                      className={classes.instagramImage}
                      alt="img"
                    />
                  </span>
                  <span className={classes.imageCaption}> visit instagram</span>
                </div>
              </figcaption>
            </figure>
          </div>
          <div className={classes.imgGalleryDiv}>
            <figure
              className="imghvr-hinge-down"
              onMouseEnter={HoverEffect}
              onMouseLeave={HoveroutEffect}
              style={{ backgroundColor: "white" }}
            >
              <img
                className={clsx(classes.img3GalleryEach, {
                  [classes.hovered]: hover,
                })}
                src={img3}
                alt="img"
              />
              <figcaption style={{ backgroundColor: "#baa07c", padding: 5 }}>
                <div className={classes.figureImage}>
                  <div>
                    <img
                      src={instagram}
                      className={classes.instagramImage}
                      alt="img"
                    />
                  </div>
                  <span className={classes.imageCaption}> visit instagram</span>
                </div>
              </figcaption>
            </figure>
          </div>
          <div className={classes.imgGalleryDiv}>
            <figure
              className="imghvr-hinge-down"
              onMouseEnter={HoverEffect}
              onMouseLeave={HoveroutEffect}
              style={{ backgroundColor: "white" }}
            >
              <img
                className={clsx(classes.img4GalleryEach, {
                  [classes.hovered]: hover,
                })}
                src={img4}
                alt="img"
              />
              <figcaption style={{ backgroundColor: "#baa07c", padding: 5 }}>
                <div className={classes.figureImage}>
                  <div>
                    <img
                      src={instagram}
                      className={classes.instagramImage}
                      alt="img"
                    />
                  </div>
                  <span className={classes.imageCaption}> visit instagram</span>
                </div>
              </figcaption>
            </figure>
          </div>
          {/*  */}
        </div>

        <div className={classes.footer}>
          <Footer OpenPopUpModalFunc={OpenPopUpModalFunc} />
        </div>
      </div>
    </Grid>
  );
};

export default IamgeGallery;
