import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import AL from '../../assets/image/Partners/AL.png'
import GD from '../../assets/image/Partners/GD.png'
import JD from '../../assets/image/Partners/JD.png'
import OM from '../../assets/image/Partners/OM.png'
import RL from '../../assets/image/Partners/RL.png'

const useStyles = makeStyles(theme=>({
    partnersDiv:{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        height: "30%",
        padding: "50px",
        justifyContent: "center",
        cursor:"pointer",
        '& img':{
            height: "auto",
            width: "100%",
            //transition:"transform 400ms",
            '&:hover':{
                //transform: "scale(1.5)",
            }
        },
        [theme.breakpoints.down("sm")]: {
           padding:'25px 5px',
           cursor:"pointer",
        '& img':{
            height: "auto",
            width: "100%",
        },
        },
    },
    partnersDivTitle:{
            display: "flex",
            flexBasis: "100%",
            alignItems: "center",
            color: "black",
            textTransform:"uppercase",
            fontSize: "smaller",
            width: "80%",
            fontFamily:"GothicRegular",
            letterSpacing: "5px",
            // [theme.breakpoints.only("md")] : {
            //     width: "65%",
            // },
        "&:before":{
            content: '""',
            flexGrow: 1,
            background: "black",
            height: "2px",
            fontSize: "0px",
            lineHeight: "0px",
            margin: "0px 6px",
            width:'30%'
        },
        "&:after":{
            content: '""',
            flexGrow: 1,
            background: "black",
            height: "2px",
            fontSize: "0px",
            lineHeight: "0px",
            width:'30%'
        },
        [theme.breakpoints.down("sm")]: {
            width:'100%',
            letterSpacing:'3px',
            fontSize:'0.6rem'
         },
    },
    innerImg:{
        width: "50%",
        display: "flex",
        alignItems: "center",
        height: "60%",
        alignSelf: "center",
        [theme.breakpoints.down("sm")]: {
            width:'70%'
         },
    },
    displayNone:{
        display:"none !important"
    },
    partnersDivImage:{
        display: "flex", 
        height: "80%", 
        width: "80%",
        [theme.breakpoints.down("sm")]: {
           width:'100%'
         },
    },
    partnersDivEachImage:{
        display: "flex", 
        height: "auto", 
        width: "70%", 
        padding: "50px 0px 30px 0px",
        justifyContent: "center",
    }
}));

const partners_img_arr = [JD, OM, GD, RL, AL]

const Partners = ({isNodisplay}) => {
    const classes = useStyles();
    return(
        <>
            <div className={clsx(classes.partnersDiv,
                    {[classes.displayNone]:isNodisplay}
                )}>
                <div className={classes.partnersDivTitle}>In Association</div>
                <div className={classes.partnersDivImage}>
                    {partners_img_arr.map((img, index)=>(
                        <div key={index} className={classes.partnersDivEachImage}>
                            <div className={classes.innerImg}>
                                <img
                                    src={img}
                                    alt={"img"}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
export default Partners