import React, { useRef } from "react"; // useEffect, useState
import clsx from "clsx";
import img1 from "../../assets/image/pic1.jpg";
import img2 from "../../assets/image/pic2.jpg";
import img3 from "../../assets/image/pic3.jpg";
import img4 from "../../assets/image/pic4.jpg";
import img5 from "../../assets/image/pic5.jpg";
import DrawerSlider from "./DrawerSlider";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import Button from "@material-ui/core/Button";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyle = makeStyles((theme) => ({
  lanpPage_img_div: {
    top: "0",
    position: "absolute",
    right: "0%",
    width: "100%",
    transition: "right ease-in-out 400ms",
    "& .slick-slide": {
      transition:
        "opacity 1000ms ease 1s, visibility 1000ms ease 1s !important",
    },
  },
  shift: {
    right: "350px !important",
  },
  lanpPage_img: {
    height: "100vh",
    width: "100vw",
    objectFit: "cover",
    opacity: 1,
    transition: "opacity 250ms",
  },
  lanpPage_img_delay: {
    opacity: 0,
  },
  container: {
    position: "relative",
    height: "100%",
  },
  scrollButtonContainer: {
    margin: 0,
    display: "flex",
    justifyContent: "center",
    position: "fixed",
    bottom: 40,
    width: "100%",
  },
  btnDiv: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    placeSelf: "start",
  },
  buttonLabel: {
    fontSize: "0.87rem !important",
    letterSpacing: "0.1rem",
    color: "white",
    fontWeight: 600,
    transition: `all 250ms`,
  },
  scrollButton: {
    width: "200px",
    borderRadius: 0,
    borderColor: "white !important",
    borderWidth: "2px !important",
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 6,
    transition: `all 250ms`,
    "&:hover": {
      backgroundColor: `rgba(186,160,121,0.5) !important`,
      transition: `all 250ms`,
      "& *": {
        color: "white !important",
      },
    },
  },
  iconDownRoot: {
    fontSize: "1.9rem !important",
    color: "white",
    transition: `all 250ms`,
  },
  scrollButtonContainerBeforeAfterPage: {
    bottom: 30,
    [theme.breakpoints.up("md")]: {
      bottom: 30,
    },
    [theme.breakpoints.up("lg")]: {
      bottom: 30,
    },
    [theme.breakpoints.up("xl")]: {
      bottom: 150,
    },
  },
}));

const SliderWrapper = ({
  beforeChangefunc,
  afterChangefunc,
  OfferOpen,
  slideNo,
  pageNo,
  handlePageChange,
}) => {
  const classes = useStyle();
  const imgArr = [
    { url: img1 },
    { url: img2 },
    { url: img3 },
    { url: img4 },
    { url: img5 },
  ];

  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    const t = setTimeout(() => {
      setMounted(true);
    }, 500);
    return () => {
      clearTimeout(t);
    };
  }, []);

  React.useEffect(() => {
    if (slideRef.current !== null) {
      if (slideNo !== 0) {
        //console.log("Pause")
        slideRef.current.slickPause();
      } else {
        //console.log("Play")
        slideRef.current.slickPlay();
      }
    }
  }, [slideNo]);

  const settings = {
    centerPadding: true,
    arrows: false,
    afterChange: () => afterChangefunc(),
    beforeChange: () => beforeChangefunc(),
    dots: true,
    pauseOnHover: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => <DrawerSlider dots={dots} goTofunc={goTofunc} />,
  };

  function goTofunc(key) {
    slideRef.current.slickGoTo(key);
  }
  const counter = useRef(0);
  const slideRef = useRef(0);
  const imageLoaded = () => {
    counter.current += 1;
    if (counter.current >= imgArr.length) {
    }
  };
  return (
    <>
      <Slider
        className={clsx(
          classes.lanpPage_img_div /*,{[classes.shift]:OfferOpen}*/
        )} // Check conditon OfferOPen or not
        ref={slideRef}
        {...settings}
      >
        {imgArr.map((item, n) => (
          <img
            alt="slide-img"
            className={clsx(classes.lanpPage_img, {
              [classes.lanpPage_img_delay]: !mounted,
            })}
            src={item.url}
            onLoad={imageLoaded}
            key={n.toString()}
          />
        ))}
      </Slider>
      <div className={classes.scrollButtonContainer}>
        <div className={classes.btnDiv}>
          <Button
            variant="outlined"
            classes={{
              root: classes.scrollButton,
              label: classes.buttonLabel,
            }}
            onClick={(e) => handlePageChange(slideNo + 1)}
            endIcon={
              <KeyboardArrowDownIcon
                classes={{
                  root: classes.iconDownRoot,
                }}
              />
            }
            tabIndex={"0"}
            disableFocusRipple
          >
            SCROLL DOWN
          </Button>
        </div>
      </div>
    </>
  );
};

export default SliderWrapper;
