import API from "../api";

export function checkoffers() {
  return API.get("/offers");
}

export function contactus({ type, ...args }) {
  return API.post("/contact-us", { ...args });
}

export function subscribeuser(email) {
  return API.post("/subscriber", { email });
}

export function submitconsultationform({ ...args }) {
  return API.post("/consultation", { ...args });
}

export function getpdfurl() {
  return API.get("/price-pdf");
}

export function getclientsecretforpayment() {
  return API.get("/payment-intent");
}

export function giftVoucher({ type, ...args }) {
  return API.post("/store-giftvoucher", { ...args });
}

export function giftVoucherList() {
  return API.get("/list-giftvouchertypes");
}

export function giftVoucherDetailsView(id, voucher_type) {
  return API.get(`/gift/view?id=${id}&voucher_type=${voucher_type}`);
}

export function treatmentList() {
  return API.get("/list-treatment");
}

export function testimonialView(id, payload) {
  return API.post(`/request-testimonial?id=${id}`, payload);
}
