import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import theme from "./theme";
import "babel-polyfill";
import configureStore from "./store";
import Main from "./Main";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LoadAssets from "./containers/LoadAssets";

const store = configureStore();
function App() {
  const notistackRef = React.useRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <Provider store={store}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={5000}
        timeout={5000}
        ref={notistackRef}
        action={(key) => (
          <IconButton onClick={onClickDismiss(key)} color="inherit">
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      >
        <ThemeProvider theme={theme}>
          <Router>
            <Main />
          </Router>
        </ThemeProvider>
      </SnackbarProvider>
    </Provider>
  );
}
export default App;
