import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas'

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;  
  const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware))
  const store = createStore(
      rootReducer,
      enhancer
    )
  sagaMiddleware.run(rootSaga);  
  return store;
}