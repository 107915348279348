import { createMuiTheme, darken, lighten } from "@material-ui/core/styles";
import "../src/assets/style/fonts.css";

const theme = createMuiTheme({
    typography: {
        subtitle2: {
            fontFamily: "GothicRegular",
            fontSize: "1.1rem",
        },
        button: {
            fontFamily: "GothicRegular",
            fontSize: "1.1rem",
        },
    },

    palette: {
        primary: {
            main: "#baa07c",
            light: "#C7B396",
            dark: darken("#baa07c", 0.5),
            //#fcf7ef color used in success stories
            //#c8a377 used in footer
        },
        secondary: {
            main: "#89137d",
        },
        grey: {
            light: "#9e9e9e",
        },
    },

    overrides: {
        MuiButton: {
            contained: {
                borderRadius: 30,
                width: "200px",
                height: "46px",
                fontSize: ".85rem",
            },
            outlined: {
                "& $label": {
                    fontSize: "0.82rem",
                    height: "30px",
                    minWidth: "125px",
                },
                border: `1px solid #baa07c !important`,
                transition: "color 250ms ease",
                "&:hover": {
                    backgroundColor: "white !important",
                    border: `1px solid #baa07c`,
                    "& $label": {
                        color: lighten("#baa07c", 0.52),
                        transition: "color 250ms ease",
                    },
                },
            },
        },
    },
    props: {
        MuiButton: {
            disableElevation: true,
        },
    },
    breakpoints: {
        values: {
          xs: 0,
          ssm:350,
          sm: 550,
          mmd:460,
          md: 960,
          lg: 1310,
          xl: 1920,
        },
      },
});

export default theme;
