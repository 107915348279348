import React, { useEffect, useLayoutEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import logoimg from "../../assets/Logo-Square_Gradient.jpg";
import introimg from "../../assets/introLine.png";
// import mobIntroimg from "../../assets/mobIntroLine.png";
import mobIntroimg from "../../assets/mobintroline2.png";
import clsx from "clsx";
import { lighten } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  mainDiv: {
    display: "flex",
    height: "90%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "auto",
      width: "100%",
      objectFit: "cover",
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
    },
    //backgroundColor: "#f6f3ef",
  },
  logo: {
    display: "flex",
    height: "auto",
    position: "absolute",
    justifyContent: "center",
    width: "30%",
    opacity: "0",
    animationName: "$LogoPic",
    animationDelay: "2.5s",
    animationDuration: "3s",
    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
  },
  intro: {
    display: "flex",
    position: "absolute",
    justifyContent: "center",
    opacity: "0",
    width: "40%",
    height: "50%",
    animationName: "$intropic",
    animationDuration: "2.5s",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "& img": {
        width: "30rem",
        height: "auto",
      },
    },
  },
  "@keyframes LogoPic": {
    "0%": {
      opacity: "0",
    },
    "55%": {
      opacity: "1",
    },
    "100%": {
      opacity: "0",
    },
  },
  "@keyframes intropic": {
    "0%": {
      opacity: "0",
    },
    "55%": {
      opacity: "1",
    },
    "100%": {
      opacity: "0",
    },
  },
  skipBtn: {
    display: "flex",
    height: "10%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "GothicRegular",
    color: lighten(theme.palette.common.black, 0.75),
    letterSpacing: `0.25rem`,
  },
  skipBtnSpan: {
    cursor: "pointer",
    transition: "color .4s",
    "&:hover": {
      color: theme.palette.common.black,
    },
  },
}));

const LandingAnimation = ({ completeAnimate }) => {
  const classes = useStyle();
  const [width, setInnerWidth] = useState(0);
  useLayoutEffect(() => {
    function updateWindowDimensions() {
      setInnerWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateWindowDimensions);
    updateWindowDimensions();
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      completeAnimate();
    }, 5000);
    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <div className={classes.mainDiv}>
        <div className={clsx(classes.intro)}>
          {width > 860 ? (
            <img alt="slide-img" src={introimg} />
          ) : (
            <img alt="slide-img" src={mobIntroimg} />
          )}
        </div>
        <div className={clsx(classes.logo)}>
          <img alt="slide-img" src={logoimg} />
        </div>
      </div>
      <div className={classes.skipBtn}>
        <span
          className={classes.skipBtnSpan}
          onClick={() => {
            completeAnimate();
          }}
        >
          SKIP
        </span>
      </div>
    </div>
  );
};

export default LandingAnimation;
