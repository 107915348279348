import {
  START_CHECK_FOR_OFFERS,
  SUCCESS_CHECK_FOR_OFFERS,
  ERROR_CHECK_FOR_OFFERS,
  START_SUBMIT_CONTACT_FORM,
  SUCCESS_SUBMIT_CONTACT_FORM,
  ERROR_SUBMIT_CONTACT_FORM,
  SET_IS_SUBMITTED_FALSE,
  START_SUBSCRIBE_USER,
  SUCCESS_SUBSCRIBE_USER,
  ERROR_SUBSCRIBE_USER,
  SET_FALSE,
  START_SUBMIT_CONSULTATION_FORM,
  SUCCESS_SUBMIT_CONSULTATION_FORM,
  ERROR_SUBMIT_CONSULTATION_FORM,
  NOTIFICATION,
  EMPTY_NOTIFICATION,
  START_GET_PDF_URL,
  SUCCESS_GET_PDF_URL,
  ERROR_GET_PDF_URL,
  START_GET_CLIENT_SECRET_PAYMENT,
  SUCCESS_GET_CLIENT_SECRET_PAYMENT,
  ERROR_GET_CLIENT_SECRET_PAYMENT,
  STORE_FORM_DATA_IN_LOCAL,
  START_SUBMIT_GIFT_VOUCHER_FORM,
  SUCCESS_SUBMIT_GIFT_VOUCHER_FORM,
  ERROR_SUBMIT_GIFT_VOUCHER_FORM,
  SET_FALSE_GIFT_VOUCHER,
  START_GIFT_VOUCER_LIST,
  SUCESS_GIFT_VOUCER_LIST,
  ERROR_GIFT_VOUCER_LIST,
  FETCH_GIFT_VOUCEHER_VIEWS,
  SUCESS_GIFT_VOUCEHER_VIEWS,
  ERROR_GIFT_VOUCEHER_VIEWS,
  START_TREATMENT_LIST_USER,
  SUCCESS_TREATMENT_LIST_USER,
  ERROR_TREATMENT_LIST_USER,
  START_SUBMIT_TESTIMONIAL_FORM,
  SUCCESS_SUBMIT_TESTIMONIAL_FORM,
  ERROR_SUBMIT_TESTIMONIAL_FORM,
  SET_FALSE_TESTIMONIAL,
} from "../actions/main";

const initState = {
  offers: {
    isLoading: false,
    isError: false,
    offers_data: [],
    isAvailable: false,
  },
  contact_form: {
    isLoading: false,
    isError: false,
    isSubmitted: false,
  },
  subscriber_actions: {
    isLoading: false,
    isError: false,
    isSubscribed: false,
  },
  consultation_form: {
    isLoading: false,
    isError: false,
  },
  message: "",
  variant: "",
  pdf_data_status: {
    isLoading: false,
    isError: false,
    pdf_data: {},
  },
  client_secret_status: {
    isLoading: false,
    isError: false,
    client_secret: "",
  },
  formdata: {},
  gift_voucher: {
    isLoading: false,
    isError: false,
    isGiftVoucherSubmitted: false,
  },
  gift_voucher_list: {
    isLoading: false,
    isError: false,
    data: [],
  },
  giftVoucherViewDetails: {
    isLoading: false,
    isError: false,
    data: {},
  },
  testimonialDetails: {
    isLoading: false,
    isError: false,
    isSubmitted: false,
    data: {},
  },
  tretmentMenuList: {
    isLoading: false,
    isError: false,
    data: [],
  },
};

const main = (prevState = initState, action) => {
  switch (action.type) {
    case START_CHECK_FOR_OFFERS:
      return {
        ...prevState,
        offers: {
          ...prevState.offers,
          isLoading: true,
          isError: false,
          offers_data: [],
        },
      };
    case SUCCESS_CHECK_FOR_OFFERS: {
      const { offers_data } = action;
      return {
        ...prevState,
        offers: {
          ...prevState.offers,
          isLoading: false,
          isError: false,
          isAvailable: true,
          offers_data,
        },
      };
    }
    case ERROR_CHECK_FOR_OFFERS: {
      return {
        ...prevState,
        offers: {
          ...prevState.offers,
          isLoading: false,
          isError: true,
          isAvailable: false,
          offers_data: [],
        },
      };
    }
    case START_SUBMIT_CONTACT_FORM: {
      return {
        ...prevState,
        contact_form: {
          isLoading: true,
          isError: false,
          isSubmitted: false,
        },
      };
    }
    case SUCCESS_SUBMIT_CONTACT_FORM: {
      const { email } = action;
      return {
        ...prevState,
        contact_form: {
          isLoading: false,
          isError: false,
          isSubmitted: true,
          email,
        },
      };
    }
    case ERROR_SUBMIT_CONTACT_FORM: {
      return {
        ...prevState,
        contact_form: {
          isLoading: false,
          isError: true,
          isSubmitted: false,
        },
      };
    }
    case SET_IS_SUBMITTED_FALSE: {
      return {
        ...prevState,
        contact_form: {
          ...prevState.contact_form,
          isSubmitted: false,
        },
      };
    }
    case START_SUBSCRIBE_USER: {
      return {
        ...prevState,
        subscriber_actions: {
          isLoading: true,
          isError: false,
          isSubscribed: false,
        },
      };
    }
    case SUCCESS_SUBSCRIBE_USER: {
      return {
        ...prevState,
        subscriber_actions: {
          isLoading: false,
          isError: false,
          isSubscribed: true,
        },
      };
    }
    case ERROR_SUBSCRIBE_USER: {
      return {
        ...prevState,
        subscriber_actions: {
          isLoading: false,
          isError: true,
          isSubscribed: false,
        },
      };
    }
    case SET_FALSE: {
      const {
        field = "subscriber_actions",
        key_of_field = "isSubscribed",
      } = action;
      return {
        ...prevState,
        [field]: {
          ...prevState[field],
          [key_of_field]: false,
        },
      };
    }
    case START_SUBMIT_CONSULTATION_FORM: {
      return {
        ...prevState,
        consultation_form: {
          isLoading: true,
          isError: false,
          isSubmitted: false,
        },
      };
    }
    case SUCCESS_SUBMIT_CONSULTATION_FORM: {
      return {
        ...prevState,
        consultation_form: {
          isLoading: false,
          isError: false,
          isSubmitted: true,
        },
      };
    }
    case ERROR_SUBMIT_CONSULTATION_FORM: {
      return {
        ...prevState,
        consultation_form: {
          isLoading: false,
          isError: true,
          isSubmitted: false,
        },
      };
    }
    case STORE_FORM_DATA_IN_LOCAL: {
      const { type, ...formdata } = action;
      return {
        ...prevState,
        formdata,
      };
    }
    case NOTIFICATION: {
      const { message, variant } = action;
      return {
        ...prevState,
        message,
        variant,
      };
    }
    case EMPTY_NOTIFICATION: {
      return {
        ...prevState,
        message: "",
        variant: "",
      };
    }
    case START_GET_PDF_URL: {
      return {
        ...prevState,
        pdf_data_status: {
          isLoading: true,
          isError: false,
          pdf_data: {},
        },
      };
    }
    case SUCCESS_GET_PDF_URL: {
      const { pdf_data } = action;
      return {
        ...prevState,
        pdf_data_status: {
          isLoading: false,
          isError: false,
          pdf_data,
        },
      };
    }
    case ERROR_GET_PDF_URL: {
      return {
        ...prevState,
        pdf_data_status: {
          isLoading: false,
          isError: true,
          pdf_data: {},
        },
      };
    }
    case START_GET_CLIENT_SECRET_PAYMENT: {
      return {
        ...prevState,
        client_secret_status: {
          isLoading: true,
          isError: false,
          client_secret: "",
        },
        //everytime you open popup again set formdata to empty object
        //so that it unclear prev
        formdata: {},
      };
    }
    case SUCCESS_GET_CLIENT_SECRET_PAYMENT: {
      const { client_secret } = action;
      return {
        ...prevState,
        client_secret_status: {
          isLoading: false,
          isError: false,
          client_secret,
        },
      };
    }
    case ERROR_GET_CLIENT_SECRET_PAYMENT: {
      return {
        ...prevState,
        client_secret_status: {
          isLoading: false,
          isError: true,
          client_secret: "",
        },
      };
    }
    case START_SUBMIT_GIFT_VOUCHER_FORM: {
      return {
        ...prevState,
        gift_voucher: {
          isLoading: true,
          isError: false,
          isGiftVoucherSubmitted: false,
        },
      };
    }
    case SUCCESS_SUBMIT_GIFT_VOUCHER_FORM: {
      const { email } = action;
      return {
        ...prevState,
        gift_voucher: {
          isLoading: false,
          isError: false,
          isGiftVoucherSubmitted: true,
          email,
        },
      };
    }
    case ERROR_SUBMIT_GIFT_VOUCHER_FORM: {
      return {
        ...prevState,
        gift_voucher: {
          isLoading: false,
          isError: true,
          isGiftVoucherSubmitted: false,
        },
      };
    }
    case SET_FALSE_GIFT_VOUCHER: {
      const {
        field = "gift_voucher",
        key_of_field = "isGiftVoucherSubmitted",
      } = action;
      return {
        ...prevState,
        [field]: {
          ...prevState[field],
          [key_of_field]: false,
        },
      };
    }
    case START_GIFT_VOUCER_LIST:
      return {
        ...prevState,
        gift_voucher_list: {
          ...prevState,
          isLoading: true,
          isError: false,
        },
      };
    case SUCESS_GIFT_VOUCER_LIST: {
      const { data } = action;
      return {
        ...prevState,
        gift_voucher_list: {
          ...prevState,
          isLoading: false,
          isError: false,
          data,
        },
      };
    }
    case ERROR_GIFT_VOUCER_LIST: {
      return {
        ...prevState,
        gift_voucher_list: {
          ...prevState,
          isLoading: false,
          isError: true,
          data: [],
        },
      };
    }
    case FETCH_GIFT_VOUCEHER_VIEWS: {
      return {
        ...prevState,
        giftVoucherViewDetails: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }
    case SUCESS_GIFT_VOUCEHER_VIEWS: {
      return {
        ...prevState,
        giftVoucherViewDetails: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    }
    case ERROR_GIFT_VOUCEHER_VIEWS: {
      return {
        ...prevState,
        giftVoucherViewDetails: {
          isLoading: false,
          isError: true,
          data: {},
        },
      };
    }
    case START_SUBMIT_TESTIMONIAL_FORM: {
      return {
        ...prevState,
        testimonialDetails: {
          isLoading: true,
          isError: false,
          isSubmitted: false,
          data: {},
        },
      };
    }
    case SUCCESS_SUBMIT_TESTIMONIAL_FORM: {
      return {
        ...prevState,
        testimonialDetails: {
          isLoading: false,
          isError: false,
          isSubmitted: true,
          data: action.payload,
        },
      };
    }
    case ERROR_SUBMIT_TESTIMONIAL_FORM: {
      return {
        ...prevState,
        testimonialDetails: {
          isLoading: false,
          isError: true,
          isSubmitted: false,
          data: {},
        },
      };
    }
    case START_TREATMENT_LIST_USER:
      return {
        ...prevState,
        tretmentMenuList: {
          ...prevState,
          isLoading: true,
          isError: false,
        },
      };
    case SUCCESS_TREATMENT_LIST_USER: {
      const { data } = action;
      return {
        ...prevState,
        tretmentMenuList: {
          ...prevState,
          isLoading: false,
          isError: false,
          data,
        },
      };
    }
    case ERROR_TREATMENT_LIST_USER: {
      return {
        ...prevState,
        tretmentMenuList: {
          ...prevState,
          isLoading: false,
          isError: true,
          data: [],
        },
      };
    }
    case SET_FALSE_TESTIMONIAL: {
      const {
        field = "tretmentMenuList",
        key_of_field = "isSubmitted",
      } = action;
      return {
        ...prevState,
        [field]: {
          ...prevState[field],
          [key_of_field]: false,
        },
      };
    }
    default:
      return prevState;
  }
};
export default main;
