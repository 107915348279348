import React, { useState, useMemo, useLayoutEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import goldSide from "../../assets/goldSide.png";
import Logo from "../../assets/dca-logo.svg";
import SocialMediaIconCommon from "./SocialMediaIconCommon";
import { withRouter, useLocation } from "react-router-dom";
import { Button, Hidden } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import { Augmentation } from "../../utils/data"; //
import { ForMen } from "../../utils/data"; //
import { Rejuvenation } from "../../utils/data"; //
import { Dermatology } from "../../utils/data"; //
import { JointsandTendons } from "../../utils/data";
import { Other } from "../../utils/data"; //
import { backGroundColor_1 } from "../../utils/data";
import Fade from "@material-ui/core/Fade";
import { TreatmentForTopMenu } from "../../utils/data";
import SkinBooster from "../../assets/image/menulink/Skin Booster.jpeg";
import Biotin from "../../assets/image/menulink/Biotin.jpg";
import vitaminDInjections from "../../assets/image/menulink/vitamin D injections.jpg";
import DefaultMenuImage from "../../assets/image/menulink/DefaultMenuImage.jpg";
import DPAVoucherFemale from "../../assets/image/Subpages/gift_voucher/DPA Voucher Female.png";
import offerImage from "../../assets/image/menulink/offersHover.jpg";
import Profilo from "../../assets/image/menulink/Profilo.jpg";
import Jalupro from "../../assets/image/menulink/Jalupro.jpg";
import PhoneIcon from "../../assets/phone.png";
import DrawerMenu from "../../components/Common/DrawerMenu/DrawerMenu";
import MyTooltip from "../Common/MyTooltip";
import { connect } from "react-redux";

const HeadMenuList = [
  {
    name: "Aftercare",
    src: "/after-care",
  },
  {
    name: "DR PALIN",
    src: "/meet-dr-palin",
  },
  {
    name: "SUCCESS STORIES",
    src: "/success-stories",
  },
  {
    name: "CONTACT US",
    src: "/contact-us",
  },
];

const useStyles = makeStyles((theme) => ({
  mainHeader: {
    height: "120px",
    width: "100%",
    display: "flex",
    background: "white",
    transition: "box-shadow .4s",
    position: "fixed",
    top: 0,
    boxShadow: "0px 0px 15px #888888",
    zIndex: theme.zIndex.modal + 1,
    [theme.breakpoints.down("sm")]: {
      height: 90,
      zIndex: 1,
    },
  },
  innerMainContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
      maxWidth: "100vw",
      alignItems: "center",
    },
  },
  goldbarDiv: {
    display: "flex",
    height: "100%",
    width: "1%",
    "& img": {
      height: "auto",
      width: "100%",
      objectFit: "cover",
    },
  },
  logoImgContainer: {
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 16px 0 16px",
    width: `16%`,
    [theme.breakpoints.down("md")]: {
      height: "unset",
      width: "unset",
      padding: 0,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "60px",
    },
  },
  logoImg: {
    objectFit: "cover",
    cursor: "pointer",
    maxWidth: 150,
    [theme.breakpoints.down("md")]: {
      maxWidth: "120px",
    },
  },
  isActive: {
    "&:after": {
      content: `""`,
      display: "block",
      height: "2px",
      background: theme.palette.primary.main,
      width: "100%",
    },
  },
  navBarDiv: {
    color: theme.palette.primary.main,
    width: "56%",
    fontSize: "1rem",
    display: "flex",
    "& ul": {
      listStyle: "none",
      padding: 0,
      margin: 0,
      display: "flex",
      height: "100%",
      alignItems: "center",
      textTransform: "uppercase",
      width: "100%",
      justifyContent: "space-between",
      "& li": {
        cursor: "pointer",
        "& a": {
          color: theme.palette.primary.main,
          textDecoration: "none",
          fontFamily: "GothicRegular",
          transition: "all 0.5s ease 0s",
          "&:hover": {
            fontFamily: "CenturyGothicBold",
          },
          "&:after": {
            content: `""`,
            display: "block",
            height: "2px",
            background: theme.palette.primary.main,
            width: "0%",
            transition: "all 0.3s ease 0s",
          },
          "&:hover:after": {
            width: "100%",
          },
        },
      },
    },
  },
  onClicked: {
    fontFamily: "CenturyGothicBold !important",
    "&:after": {
      width: "100% !important",
    },
  },
  SocialBar: {
    width: "27%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& button": {
      width: "270px",
      fontSize: "1rem",
    },
    [theme.breakpoints.down("md")]: {
      "& button": {
        width: "250px",
      },
    },
  },
  SocialBarDisplayNone: {
    display: "none",
  },
  drawerMenu: {
    "& .MuiDrawer-paperAnchorTop": {
      marginTop: 120,
    },
  },
  drawerMenuContainer: {
    background: backGroundColor_1,
    width: "100%",
    padding: "0px",
    display: "flex",
    alignSelf: "center",
    justifyContent: "space-between",
    height: 550,
    "@media (max-width: 1280px)": {
      height: 607,
    },
    [theme.breakpoints.up("lg")]: {
      height: 670,
    },
    [theme.breakpoints.up("xl")]: {
      height: 700,
    },
  },
  subNavMenuDivInnerh3: {
    color: theme.palette.secondary.main,
    marginBlockStart: "0",
    fontSize: "0.8333rem",
    marginBottom: "7px",
    [theme.breakpoints.only("md")]: {
      fontSize: "0.7rem",
    },
  },
  subNavMenuDivInner: {
    // padding:"0px 50px",
    padding: "50px 0px",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 1920px)": {
      padding: "23px 0px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  subNavMenuDivul: {
    padding: 0,
    "& li": {
      listStyle: "none",
      // color:theme.palette.primary.main,
      color: "black",
      fontSize: "0.8333rem",
      marginBottom: "7px",
      minInlineSize: "max-content",
      cursor: "pointer",
      transition: "opacity .3s ease-in-out",
      "&:hover": {
        opacity: "100% !important",
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.only("md")]: {
        marginBottom: "10px",
        fontSize: "0.65rem",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "1rem",
      },
    },
  },
  hovered: {
    opacity: "50%",
  },
  dynamicImageStyle: {
    [theme.breakpoints.up("md")]: {
      paddingTop: 54,
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: 54,
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: 54,
    },
  },
  menuImageContainer: {
    //padding : "0px 50px",
    display: "flex",
    flexDirection: "column",
    //marginTop : "1em",
    width: "30%",
    height: "auto",
    paddingTop: 54,
    [theme.breakpoints.up("md")]: {
      paddingTop: 0,
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: 0,
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: 0,
    },
  },
  imgHoveredContainer: {
    position: "relative",
    right: `1%`,
    [theme.breakpoints.up("xl")]: {
      right: `3.2%`,
    },
  },
  img: {
    width: "87%",
    objectFit: "contain",
  },
  defaultImg: {
    height: "100%",
    width: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    marginLeft: "auto",
  },
  removeBoxShadow: {
    boxShadow: "none",
  },
  forMenli: {
    minInlineSize: "none",
    width: "150px",
  },
  phoneIconContainer: {
    marginRight: theme.spacing(2.8),
  },
  phoneIcon: {
    height: 30,
    width: "auto",
  },
  Bookbtn: {
    margin: "30px auto",
    [theme.breakpoints.only("md")]: {
      margin: 0,
    },
    "& button": {
      margin: "auto 10px",
      fontFamily: "GothicRegular",
      fontSize: ".85rem",
      [theme.breakpoints.only("md")]: {
        margin: "auto 5px",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: 10,
        marginBottom: 10,
      },
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px auto",
    },
  },
  buttonLabel: {
    color: "white",
    fontSize: ".90rem !important",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-14px",
    },
  },
  PriceListDiv: {
    width: "50%",
    marginLeft: "-12px",
    marginTop: "28px",
  },
  buttonsHeightWidth: {
    minHeight: 23,
    minWidth: 175,
    height: "35px",
    [theme.breakpoints.only("md")]: {
      minWidth: 0,
    },
  },
}));

const Header = ({
  isSocailBarVisible = false,
  history,
  forVp = false,
  ifoffer = false,
  offerPage = false,
  pdf_data_status,
  handleFormOpen = () => undefined,
  ...props
}) => {
  const classes = useStyles();

  const [openDrawer, setOpendrawer] = useState(false);
  const [Hovered, setHovered] = useState(false);
  const [hoverImage, setHoverImage] = useState("");
  const [BoxShadow, setBoxShadow] = useState(false);
  const [OpenPopUpModal, setOpenPopUpModal] = useState(false);
  const [whichModal, setwhichModal] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [ifOffer, setIfOffer] = useState(true);
  const [isNavDrawerOpen, setNavDrawerOpen] = useState(false);
  const [topOpen, setToOpen] = useState("");
  const [clickCondition, setclickCondition] = useState(false);
  const [clickTreatment, setclickTreatment] = useState(false);
  const [width, setInnerWidth] = useState(0);
  const isActive = props.location.pathname;

  useLayoutEffect(() => {
    function updateWindowDimensions() {
      setInnerWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateWindowDimensions);
    updateWindowDimensions();
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  function HoverEffect() {
    setHovered(true);
  }
  function OpenPopUpModalFunc(data) {
    setwhichModalfunc(data);
    setOpenPopUpModal(true);
  }
  function openMenuFunc() {
    setIsMenuOpen(!isMenuOpen);
  }

  function closeMenuFunc() {
    setIsMenuOpen(false);
  }
  function setwhichModalfunc(data) {
    setwhichModal(data);
  }

  function closePopUpModalFunc() {
    setwhichModalfunc("");
    setOpenPopUpModal(false);
  }
  function HoveroutEffect() {
    setHovered(false);
  }
  function subNavListOnclick(src) {
    //closeMenuFunc()
    history.push(`/${src}`);
  }
  function onMousenEnterEvent(menuImage) {
    HoverEffect();
    setHoverImage(menuImage);
  }
  function onMouseLeaveEvent() {
    HoveroutEffect();
    setHoverImage("");
  }
  function closeDrawer() {
    setOpendrawer(false);
    setBoxShadow(false);
  }
  function setDrawerOpenFunc(value, e) {
    if (isNavDrawerOpen) {
      if (value === topOpen) {
        setNavDrawerOpen(!isNavDrawerOpen);
      } else if (value !== topOpen) {
        setToOpen(value);
      }
    } else {
      setNavDrawerOpen(!isNavDrawerOpen);
      setToOpen(value);
    }
  }
  function openSubNavListfunc(data, e) {
    if (data === "condition") {
      setclickTreatment(false);
      setclickCondition(!clickCondition);
    } else {
      setclickCondition(false);
      setclickTreatment(!clickTreatment);
    }
    setDrawerOpenFunc(data, e);
  }

  const { pathname } = useLocation();

  const isBookConsultationBtnInHeader = useMemo(() => {
    if (pathname === "/eyelashgrowth" || pathname === "/contact-us") {
      return true;
    } else {
      return false;
    }
  }, [pathname]);

  return (
    <>
      <AppBar
        id="mainHeader"
        className={clsx(classes.mainHeader, {
          [classes.mainHeaderForVp]: forVp,
          [classes.removeBoxShadow]: BoxShadow,
        })}
      >
        <div className={classes.innerMainContainer}>
          {width < 860 ? (
            <DrawerMenu
              isMenuOpen={isMenuOpen}
              ifOffer={ifOffer}
              closeMenuFunc={closeMenuFunc}
              openMenuFunc={openMenuFunc}
              OpenPopUpModal={OpenPopUpModal}
              setclickCondition={setclickCondition}
              setclickTreatment={setclickTreatment}
              OpenPopUpModalFunc={OpenPopUpModalFunc}
              isNavDrawerOpen={isNavDrawerOpen}
              topOpen={topOpen}
              setNavDrawerOpen={setNavDrawerOpen}
              setToOpen={setToOpen}
              openSubNavListfunc={openSubNavListfunc}
            />
          ) : (
            <div className={classes.goldbarDiv}>
              <img alt="" src={goldSide} />
            </div>
          )}
          <div className={classes.logoImgContainer}>
            <img
              alt=""
              src={Logo}
              onClick={() => {
                history.push("/");
              }}
              className={classes.logoImg}
            />
          </div>
          <Hidden smDown>
            <div className={classes.navBarDiv}>
              <ul>
                <li>
                  <a
                    href="/"
                    className={clsx({
                      [classes.onClicked]: openDrawer,
                    })}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpendrawer(!openDrawer);
                      setBoxShadow(!BoxShadow);
                    }}
                  >
                    Conditions
                  </a>
                </li>
                {HeadMenuList.map((list, index) => {
                  return (
                    <li
                      className={
                        isActive === list.src ? classes.isActive : "notActive"
                      }
                      key={index}
                    >
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(list.src);
                        }}
                      >
                        {list.name}
                      </a>
                    </li>
                  );
                })}

                {
                  <li
                    className={
                      isActive === "/offers" ? classes.isActive : "notActive"
                    }
                  >
                    <a
                      href="/offers"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/offers");
                      }}
                    >
                      OFFERS
                    </a>
                  </li>
                }
              </ul>
            </div>
            {offerPage || isBookConsultationBtnInHeader ? (
              <div
                className={clsx(classes.SocialBar, {
                  [classes.SocialBarDisplayNone]: isSocailBarVisible,
                })}
              >
                <Button
                  disableElevation={true}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    if (!isBookConsultationBtnInHeader) {
                      history.push("/book-consultation");
                    } else if (pathname === "/contact-us") {
                      history.push("/book-consultation");
                    } else {
                      return handleFormOpen();
                    }
                  }}
                >
                  {isBookConsultationBtnInHeader &&
                  pathname === "/eyelashgrowth"
                    ? "ONLINE CONSULTATION"
                    : "BOOK CONSULTATION"}
                </Button>
              </div>
            ) : (
              <div
                className={clsx(classes.SocialBar, {
                  [classes.SocialBarDisplayNone]: isSocailBarVisible,
                })}
              >
                <SocialMediaIconCommon />
              </div>
            )}
          </Hidden>
          <Hidden mdUp>
            <div
              className={classes.phoneIconContainer}
              onClick={() => {
                history.push("/book-consultation");
              }}
            >
              <img
                src={PhoneIcon}
                alt={"Phone"}
                className={classes.phoneIcon}
              />
            </div>
          </Hidden>
        </div>
      </AppBar>
      <Drawer
        anchor="top"
        open={openDrawer}
        onClose={() => closeDrawer()}
        className={classes.drawerMenu}
      >
        <div className={classes.drawerMenuContainer}>
          <div
            className={classes.subNavMenuDivInner}
            style={{ paddingLeft: "50px" }}
          >
            <ul className={classes.subNavMenuDivul}>
              <h3 className={classes.subNavMenuDivInnerh3}>Augmentation</h3>
              {Augmentation.map((dataObj, index) => (
                <li
                  key={index}
                  onClick={() => {
                    subNavListOnclick(dataObj.src);
                  }}
                  onMouseEnter={() => onMousenEnterEvent(dataObj.menuimage)}
                  onMouseLeave={onMouseLeaveEvent}
                  className={clsx({
                    [classes.hovered]: Hovered,
                  })}
                >
                  {dataObj.name}
                </li>
              ))}
            </ul>
          </div>
          <div className={classes.subNavMenuDivInner}>
            <ul className={classes.subNavMenuDivul}>
              <h3
                className={clsx(
                  classes.headinOfList,
                  classes.subNavMenuDivInnerh3
                )}
              >
                Rejuvenation
              </h3>
              {Rejuvenation.map((dataObj, index) => (
                <li
                  key={index}
                  onClick={() => {
                    subNavListOnclick(dataObj.src);
                  }}
                  onMouseEnter={() => onMousenEnterEvent(dataObj.menuimage)}
                  onMouseLeave={onMouseLeaveEvent}
                  className={clsx({
                    [classes.hovered]: Hovered,
                  })}
                >
                  {dataObj.name}
                </li>
              ))}
              <li
                onClick={() => {
                  window.open(pdf_data_status.pdf_data.pricepdf_url);
                }}
                onMouseEnter={() => onMousenEnterEvent(SkinBooster)}
                onMouseLeave={() => onMouseLeaveEvent()}
                className={clsx({
                  [classes.hovered]: Hovered,
                })}
              >
                Skin Boosters
              </li>
            </ul>
            <ul
              className={clsx(
                classes.newListinSamediv,
                classes.subNavMenuDivul
              )}
            >
              <h3
                className={clsx(
                  classes.headinOfList,
                  classes.subNavMenuDivInnerh3
                )}
              >
                Dermatology
              </h3>
              {Dermatology.map((dataObj, index) => (
                <li
                  key={index}
                  // onClick={() => {
                  //   subNavListOnclick(dataObj.src);
                  // }}
                  onClick={() => {
                    dataObj.src === ""
                      ? console.log("U Clicked")
                      : subNavListOnclick(dataObj.src);
                  }}
                  onMouseEnter={() => onMousenEnterEvent(dataObj.menuimage)}
                  onMouseLeave={onMouseLeaveEvent}
                  className={clsx({
                    [classes.hovered]: Hovered,
                  })}
                >
                  {dataObj.comingSoon ? (
                    <MyTooltip title={"Coming Soon"}>
                      <span>{dataObj.name}</span>
                    </MyTooltip>
                  ) : (
                    dataObj.name
                  )}
                </li>
              ))}
            </ul>
          </div>

          <div className={classes.subNavMenuDivInner}>
            <ul className={classes.subNavMenuDivul}>
              <h3 className={classes.subNavMenuDivInnerh3}>For Men</h3>
              {ForMen.map((dataObj, index) => (
                <li
                  key={index}
                  onClick={() => {
                    subNavListOnclick(dataObj.src);
                  }}
                  onMouseEnter={() => onMousenEnterEvent(dataObj.menuimage)}
                  onMouseLeave={onMouseLeaveEvent}
                  style={{
                    minInlineSize: "unset",
                    width: "150px",
                    lineHeight: "1.4",
                  }}
                  className={clsx({
                    [classes.hovered]: Hovered,
                  })}
                >
                  {dataObj.name}
                </li>
              ))}
            </ul>
            <ul
              className={clsx(
                classes.newListinSamediv,
                classes.subNavMenuDivul
              )}
            >
              <h3
                className={clsx(
                  classes.headinOfList,
                  classes.subNavMenuDivInnerh3
                )}
              >
                Other
              </h3>
              {Other.map((dataObj, index) => (
                <li
                  key={index}
                  onClick={() => {
                    subNavListOnclick(dataObj.src);
                  }}
                  onMouseEnter={() => onMousenEnterEvent(dataObj.menuimage)}
                  onMouseLeave={onMouseLeaveEvent}
                  className={clsx({
                    [classes.hovered]: Hovered,
                  })}
                >
                  {dataObj.comingSoon ? (
                    <MyTooltip title={"Coming Soon"}>
                      <span>{dataObj.name}</span>
                    </MyTooltip>
                  ) : (
                    dataObj.name
                  )}
                </li>
              ))}
            </ul>
            <ul className={classes.subNavMenuDivul}>
              <h3
                className={clsx(
                  classes.headinOfList,
                  classes.subNavMenuDivInnerh3
                )}
              >
                Joints & Tendons
              </h3>
              {JointsandTendons.map((dataObj, index) => (
                <li
                  key={index}
                  onClick={() => {
                    subNavListOnclick(dataObj.src);
                  }}
                  onMouseEnter={() => onMousenEnterEvent(dataObj.menuimage)}
                  onMouseLeave={onMouseLeaveEvent}
                  className={clsx({
                    [classes.hovered]: Hovered,
                  })}
                >
                  {dataObj.name}
                </li>
              ))}
            </ul>
          </div>
          <div className={classes.subNavMenuDivInner}>
            <ul className={classes.subNavMenuDivul}>
              <h3 className={classes.subNavMenuDivInnerh3}>All Treatments</h3>
              {TreatmentForTopMenu.map((dataObj, index) => (
                <React.Fragment key={index}>
                  {dataObj.data.map((subCategory, index) => {
                    return (
                      <li
                        key={index.toString()}
                        onClick={() => {
                          subNavListOnclick(subCategory.src);
                        }}
                        onMouseEnter={() =>
                          onMousenEnterEvent(subCategory.menuimage)
                        }
                        onMouseLeave={onMouseLeaveEvent}
                        className={clsx({
                          [classes.hovered]: Hovered,
                        })}
                      >
                        {subCategory.name}
                      </li>
                    );
                  })}
                  <li
                    onClick={() => {
                      window.open(pdf_data_status.pdf_data.pricepdf_url);
                    }}
                    onMouseEnter={() => onMousenEnterEvent(Biotin)}
                    onMouseLeave={onMouseLeaveEvent}
                    className={clsx({
                      [classes.hovered]: Hovered,
                    })}
                  >
                    Biotin Injections
                  </li>
                  <li
                    onClick={() => {
                      window.open(pdf_data_status.pdf_data.pricepdf_url);
                    }}
                    onMouseEnter={() => onMousenEnterEvent(vitaminDInjections)}
                    onMouseLeave={onMouseLeaveEvent}
                    className={clsx({
                      [classes.hovered]: Hovered,
                    })}
                  >
                    Vitamin D Injections
                  </li>
                  <li
                    onClick={() => {
                      window.open(pdf_data_status.pdf_data.pricepdf_url);
                    }}
                    onMouseEnter={() => onMousenEnterEvent(Profilo)}
                    onMouseLeave={onMouseLeaveEvent}
                    className={clsx({
                      [classes.hovered]: Hovered,
                    })}
                  >
                    Profhilo®
                  </li>
                  <li
                    onClick={() => {
                      window.open(pdf_data_status.pdf_data.pricepdf_url);
                    }}
                    onMouseEnter={() => onMousenEnterEvent(Jalupro)}
                    onMouseLeave={onMouseLeaveEvent}
                    className={clsx({
                      [classes.hovered]: Hovered,
                    })}
                  >
                    Jalupro®
                  </li>
                  <br />
                  <h3 className={classes.subNavMenuDivInnerh3}>
                    Gift Vouchers & Offers
                  </h3>
                  <li
                    onMouseEnter={() => onMousenEnterEvent(DPAVoucherFemale)}
                    onMouseLeave={onMouseLeaveEvent}
                    onClick={() => {
                      history.push("/gift");
                    }}
                    className={clsx({
                      [classes.hovered]: Hovered,
                    })}
                  >
                    Send a Gift
                  </li>
                  <li
                    onMouseEnter={() => onMousenEnterEvent(offerImage)}
                    onMouseLeave={onMouseLeaveEvent}
                    onClick={() => {
                      history.push("/offers");
                    }}
                    className={clsx({
                      [classes.hovered]: Hovered,
                    })}
                  >
                    Exclusive Offers
                  </li>
                  <div className={classes.PriceListDiv}>
                    <Button
                      variant="contained"
                      className={classes.bookBtn}
                      color="primary"
                      disableElevation
                      onClick={() => {
                        window.open(pdf_data_status.pdf_data.pricepdf_url);
                      }}
                      classes={{
                        root: classes.buttonsHeightWidth,
                        label: classes.buttonLabel,
                      }}
                    >
                      Price List
                    </Button>
                  </div>
                </React.Fragment>
              ))}
            </ul>
          </div>
          <div
            className={clsx(classes.menuImageContainer, {
              [classes.imgHoveredContainer]: Hovered,
            })}
          >
            {Hovered ? (
              <div
                className={classes.dynamicImageStyle}
                style={{ paddingTop: 54 }}
              >
                <Fade in={Hovered} timeout={250}>
                  {hoverImage !== "" ? (
                    <img
                      src={hoverImage}
                      className={classes.img}
                      alt="Hover Img"
                    />
                  ) : (
                    <></>
                  )}
                </Fade>
              </div>
            ) : (
              <img
                src={DefaultMenuImage}
                className={classes.defaultImg}
                alt="Default Img"
              />
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({
  pdf_data_status: state.main.pdf_data_status,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
