export const START_CHECK_FOR_OFFERS = "START_CHECK_FOR_OFFERS";
export const SUCCESS_CHECK_FOR_OFFERS = "SUCCESS_CHECK_FOR_OFFERS";
export const ERROR_CHECK_FOR_OFFERS = "ERROR_CHECK_FOR_OFFERS";

export const START_SUBMIT_CONTACT_FORM = "START_SUBMIT_CONTACT_FORM";
export const SUCCESS_SUBMIT_CONTACT_FORM = "SUCCESS_SUBMIT_CONTACT_FORM";
export const ERROR_SUBMIT_CONTACT_FORM = "ERROR_SUBMIT_CONTACT_FORM";

export const SET_IS_SUBMITTED_FALSE = "SET_IS_SUBMITTED_FALSE";

export const START_SUBSCRIBE_USER = "START_SUBSCRIBE_USER";
export const SUCCESS_SUBSCRIBE_USER = "SUCCESS_SUBSCRIBE_USER";
export const ERROR_SUBSCRIBE_USER = "ERROR_SUBSCRIBE_USER";

export const SET_FALSE = "SET_FALSE";

export const START_SUBMIT_CONSULTATION_FORM = "START_SUBMIT_CONSULTATION_FORM";
export const SUCCESS_SUBMIT_CONSULTATION_FORM =
  "SUCCESS_SUBMIT_CONSULTATION_FORM";
export const ERROR_SUBMIT_CONSULTATION_FORM = "ERROR_SUBMIT_CONSULTATION_FORM";

export const NOTIFICATION = "NOTIFICATION";
export const EMPTY_NOTIFICATION = "EMPTY_NOTIFICATION";

export const START_GET_PDF_URL = "START_GET_PDF_URL";
export const SUCCESS_GET_PDF_URL = "SUCCESS_GET_PDF_URL";
export const ERROR_GET_PDF_URL = "ERROR_GET_PDF_URL";

export const STORE_FORM_DATA_IN_LOCAL = "STORE_FORM_DATA_IN_LOCAL";

export const START_GET_CLIENT_SECRET_PAYMENT =
  "START_GET_CLIENT_SECRET_PAYMENT";
export const SUCCESS_GET_CLIENT_SECRET_PAYMENT =
  "SUCCESS_GET_CLIENT_SECRET_PAYMENT";
export const ERROR_GET_CLIENT_SECRET_PAYMENT =
  "ERROR_GET_CLIENT_SECRET_PAYMENT";

export const START_SUBMIT_GIFT_VOUCHER_FORM = "START_SUBMIT_GIFT_VOUCHER_FORM";
export const SUCCESS_SUBMIT_GIFT_VOUCHER_FORM =
  "SUCCESS_SUBMIT_GIFT_VOUCHER_FORM";
export const ERROR_SUBMIT_GIFT_VOUCHER_FORM = "ERROR_SUBMIT_GIFT_VOUCHER_FORM";

export const SET_FALSE_GIFT_VOUCHER = "SET_FALSE_GIFT_VOUCHER";

export const START_GIFT_VOUCER_LIST = "START_GIFT_VOUCER_LIST";
export const SUCESS_GIFT_VOUCER_LIST = "SUCESS_GIFT_VOUCER_LIST";
export const ERROR_GIFT_VOUCER_LIST = "ERROR_GIFT_VOUCER_LIST";

export const FETCH_GIFT_VOUCEHER_VIEWS = "FETCH_GIFT_VOUCEHER_VIEWS";
export const SUCESS_GIFT_VOUCEHER_VIEWS = "GET_GIFT_VOUCEHER_VIEWS";
export const ERROR_GIFT_VOUCEHER_VIEWS = "ERROR_GIFT_VOUCEHER_VIEWS";

export const START_TREATMENT_LIST_USER = "START_TREATMENT_LIST_USER";
export const SUCCESS_TREATMENT_LIST_USER = "SUCCESS_TREATMENT_LIST_USER";
export const ERROR_TREATMENT_LIST_USER = "ERROR_TREATMENT_LIST_USER";

export const START_SUBMIT_TESTIMONIAL_FORM = "START_SUBMIT_TESTIMONIAL_FORM";
export const SUCCESS_SUBMIT_TESTIMONIAL_FORM =
  "SUCCESS_SUBMIT_TESTIMONIAL_FORM";
export const ERROR_SUBMIT_TESTIMONIAL_FORM = "ERROR_SUBMIT_TESTIMONIAL_FORM";

export const SET_FALSE_TESTIMONIAL = "SET_FALSE_TESTIMONIAL";
