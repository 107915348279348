import React, { useEffect, useLayoutEffect, useState } from "react";
import NavList from "./NavList";
import MenuButton from "./MenuButton";
import SocialMediaIcon from "../SocialMediaIcon";
import logoimg from "../../../assets/dca-logo.svg";
import sleepingLady from "../../../assets/image/DrawerMenu.jpg";
import { makeStyles, lighten } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import { CSSTransition } from "react-transition-group";
import Grid from "@material-ui/core/Grid";
import grey from "@material-ui/core/colors/grey";
import clsx from "clsx";
import SubNavList from "./SubNavList";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { START_GET_PDF_URL } from "../../../actions/main";

const transitionDurationForNavOpen = "300ms";

const useStyle = makeStyles((theme) => ({
  drawerMenu: {
    display: "grid",
    gridTemplateColumns: "fit-content",
    width: "fit-content",
    position: "relative",
    overflowY: "scroll",
    "& .MuiCollapse-container": {
      display: "block",
      width: "100%",
    },
  },
  paperAnchorDockedLeft: {
    borderRight: 0,
  },
  innerMainContainer: {
    width: "50vw",
    display: "grid",
    gridTemplateRows: "min-content max-content auto",
    gridRowGap: 40,
    transition: `all ${transitionDurationForNavOpen} ease !important`,
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
      gridRowGap: 50,
    },
    [theme.breakpoints.down("xs")]: {
      width: "90vw",
      gridRowGap: 20,
    },
  },
  innerMainContainerHeight: {
    height: "max-content",
    transition: `all ${transitionDurationForNavOpen} ease !important`,
  },
  collapsedGridRowGap: {
    width: "60vw",
    height: "100%",
    gridRowGap: "0px !important",
    transition: `all ${transitionDurationForNavOpen} ease !important`,
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
      overflow: "scroll",
    },
    "@media only screen and (min-device-width: 768px) and (max-device-width: 1020px)": {
      width: "initial",
      overflow: "scroll",
    },
    "@media only screen and (min-device-width: 768px) and (max-device-width: 1366px)": {
      width: "71vw",
      overflow: "scroll",
    },
  },
  navbarDrawerGrid: {
    display: "grid",
    gridTemplateRows: "max-content",
  },
  navbarDrawer: {
    height: "max-content",
  },
  logo: {
    marginTop: "75px",
    cursor: "pointer",
    transition: `margin-top ${transitionDurationForNavOpen} ease`,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  collapsedLogo: {
    marginTop: "60px !important",
    transition: `margin-top ${transitionDurationForNavOpen} ease`,
  },
  navMenu: {
    padding: "10px",
    color: theme.palette.primary.main,
    margin: "1em",
  },
  footmenu: {
    color: grey[400],
    cursor: "pointer",
    fontSize: "0.78rem",
    fontFamily: "GothicRegular",
    transition: "all 250ms ease",
    "&:hover": {
      color: "black",
      transition: "all 250ms ease",
    },
  },
  offerBtn: {
    color: theme.palette.getContrastText(grey[800]),
    backgroundColor: "black",
    "&:hover": {
      backgroundColor: `${lighten(theme.palette.common.black, 0.25)}`,
      transition: "all 250ms ease-in",
    },
  },
  Bookbtn: {
    margin: "30px auto",
    [theme.breakpoints.only("md")]: {
      margin: 0,
    },
    "& button": {
      margin: "auto 10px",
      fontFamily: "GothicRegular",
      fontSize: ".85rem",
      [theme.breakpoints.only("md")]: {
        margin: "auto 5px",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: 10,
        marginBottom: 10,
      },
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px auto",
    },
  },
  PriceBtn: {
    margin: "30px auto",
    [theme.breakpoints.only("md")]: {
      margin: 0,
    },
    "& button": {
      margin: "auto 10px",
      fontFamily: "GothicRegular",
      fontSize: ".85rem",
      [theme.breakpoints.only("md")]: {
        margin: "auto 5px",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: 10,
        marginBottom: 10,
      },
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px auto",
    },
  },
  priceBtnDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  giftVoucherBtnDiv: {
    display: "flex",
    width: "100%",
    marginTop: "10px",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  Image: {
    minHeight: 96.3,
    [theme.breakpoints.down("sm")]: {
      minHeight: 50,
    },
  },
  ImageImg: {
    width: "100%",
    height: "auto",
  },
  footMenu: {
    padding: "10px",
    color: theme.palette.primary.main,
    margin: "1em",
  },
  footDrawer: {
    display: "flex",
    alignItems: "center",
  },
  footDrawerUL: {
    listStyle: "none",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  footDrawerLI: {
    display: "inline-block",
    fontSize: "10px",
  },
  buttonsHeightWidth: {
    minHeight: 42,
    minWidth: 230,
    [theme.breakpoints.only("md")]: {
      minWidth: 0,
    },
  },
  priceButtonsHeightWidth: {
    minHeight: 42,
    minWidth: 230,
    [theme.breakpoints.only("md")]: {
      minWidth: 0,
    },
  },
  extraGrid: {
    width: "60vw",
    transition: "all 250ms ease !important",
    display: "grid",
    gridTemplateRows: "max-content max-content",
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
    },
  },
  innerCollapseGrid: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: "-6px",
    // [theme.breakpoints.down("sm")] : {
    //   display:'none'
    // },
  },
  collapsedGrid: {
    height: "fit-content",
    display: "grid",
    gridTemplateRows: "min-content min-content min-content min-content",
    gridRowGap: 30,
    [theme.breakpoints.down("sm")]: {
      gridRowGap: 60,
    },
    [theme.breakpoints.down("xs")]: {
      gridRowGap: 25,
      overflowX: "scroll",
    },
  },
  paperHeight: {
    height: "100%",
    overflowY: "hidden !important",
    display: "grid",
    alignItems: "start",
    [theme.breakpoints.down("xs")]: {
      overflow: "auto",
      position: "relative",
      height: "120%",
    },
  },
  customGrid: {
    display: "grid",
    justifyItems: "center",
    alignItems: "center",
    zIndex: 99,
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      position: "initial",
      height: "100vh",
    },
  },
  closedDrawerMainContainer: {
    display: "grid",
    width: "max-content",
  },
  openedDrawerMainContainer: {
    display: "grid",
    gridTemplateColumns: "auto auto",
  },
  navbarcollapse: {
    zIndex: "1201",
    overflow: "hidden",
    "& .MuiCollapse-wrapper": {
      height: "100%",
    },
  },
  displayNone: {
    display: "none !important",
  },
}));

const DrawerMenu = ({
  isMenuOpen,
  openMenuFunc,
  ifOffer,
  OnPageChange,
  closeMenuFunc,
  OpenPopUpModalFunc,
  OpenPopUpModal,
  history,
  openSubNavListfunc,
  setclickCondition,
  setclickTreatment,
  isNavDrawerOpen,
  getpdfurl,
  topOpen,
  setNavDrawerOpen,
  pdf_data_status,
}) => {
  useEffect(() => {
    setNavDrawerOpen(false);
  }, [isMenuOpen]);
  useEffect(() => {
    getpdfurl();
  }, [getpdfurl]);

  function setDrawerCloseFunc() {
    setNavDrawerOpen(false);
  }
  const [width, setInnerWidth] = useState(0);
  useLayoutEffect(() => {
    function updateWindowDimensions() {
      setInnerWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateWindowDimensions);
    updateWindowDimensions();
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const classes = useStyle();

  return (
    <div
      className={clsx(classes.closedDrawerMainContainer, {
        [classes.openedDrawerMainContainer]: isMenuOpen,
      })}
    >
      <Drawer
        transitionDuration={500}
        classes={{
          root: clsx(classes.drawerMenu, {
            [classes.extraGrid]: isNavDrawerOpen,
          }),
          paperAnchorDockedLeft: classes.paperAnchorDockedLeft,
          paper: clsx(classes.paperHeight, {
            [classes.customGrid]: !isNavDrawerOpen,
          }),
        }}
        open={isMenuOpen}
        onClose={() => openMenuFunc()}
      >
        <Grid
          container
          className={clsx(classes.innerMainContainer, {
            [classes.innerMainContainerHeight]: !isNavDrawerOpen,
            [classes.collapsedGridRowGap]: isNavDrawerOpen,
          })}
        >
          {/* Logo */}
          <Grid
            container
            className={clsx(classes.logo, {
              [classes.collapsedLogo]: isNavDrawerOpen,
            })}
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={8}>
              <img
                height="65"
                onClick={() => setDrawerCloseFunc()}
                alt="logo"
                src={logoimg}
              />
            </Grid>
          </Grid>

          {/* NavBar */}
          <Grid
            container
            wrap="nowrap"
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.navbarDrawerGrid}
          >
            <Grid item xs={12} className={classes.navbarDrawer}>
              <NavList
                openSubNavListfunc={openSubNavListfunc}
                isNavDrawerOpen={isNavDrawerOpen}
                setclickCondition={setclickCondition}
                setclickTreatment={setclickTreatment}
                closeMenuFunc={closeMenuFunc}
                topOpen={topOpen}
                width={width}
              />
            </Grid>
          </Grid>
          {/*// ! price button here on small screen */}
          <div className={classes.priceBtnDiv}>
            <Button
              variant="contained"
              className={classes.PriceBtn}
              color="secondary"
              disableElevation
              onClick={() => {
                window.open(pdf_data_status.pdf_data.pricepdf_url);
              }}
              classes={{
                root: classes.priceButtonsHeightWidth,
              }}
            >
              Price
            </Button>
          </div>
          <div className={classes.giftVoucherBtnDiv}>
            <Button
              variant="contained"
              className={classes.PriceBtn}
              color="secondary"
              disableElevation
              onClick={() => {
                history.push("/gift");
              }}
              classes={{
                root: classes.priceButtonsHeightWidth,
              }}
            >
              GIFT VOUCHER
            </Button>
          </div>
          {/* SocialMedia */}
          <CSSTransition
            in={!isNavDrawerOpen}
            classNames="socialmediaandothers"
            timeout={{
              enter: 300,
              exit: 0,
              appear: 250,
            }}
          >
            <Grid
              container
              className={clsx(classes.collapsedGrid, {
                [classes.displayNone]: isNavDrawerOpen,
              })}
            >
              {width > 860 && (
                <Grid
                  container
                  className={clsx({
                    [classes.innerCollapseGrid]: true,
                  })}
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs></Grid>
                  <Grid item xs={10}>
                    <SocialMediaIcon />
                  </Grid>
                  <Grid item xs></Grid>
                </Grid>
              )}
              {/* Button */}
              <Grid
                container
                className={clsx(classes.Bookbtn, {
                  [classes.innerCollapseGrid]: true,
                })}
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item xs></Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={10}
                  lg={10}
                  xl={12}
                  style={{ textAlign: width < 860 ? "center" : undefined }}
                >
                  <Button
                    variant="contained"
                    className={classes.bookBtn}
                    color="secondary"
                    disableElevation
                    onClick={() => {
                      closeMenuFunc();
                      history.push("/book-consultation");
                    }}
                    classes={{
                      root: classes.buttonsHeightWidth,
                    }}
                  >
                    Book Consultation
                  </Button>
                  {ifOffer && (
                    <Button
                      color="default"
                      onClick={() => {
                        closeMenuFunc();
                        history.push("/offers");
                      }}
                      classes={{
                        contained: classes.offerBtn,
                        root: classes.buttonsHeightWidth,
                      }}
                      variant="contained"
                      disableElevation
                    >
                      Exclusive offers
                    </Button>
                  )}
                </Grid>
                <Grid item xs></Grid>
              </Grid>

              {/* Image */}
              <Grid
                container
                className={clsx(classes.Image, {
                  [classes.innerCollapseGrid]: true,
                })}
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid
                  className={classes.Image}
                  container
                  justify="center"
                  alignItems="center"
                  item
                  xs={8}
                >
                  <img
                    className={classes.ImageImg}
                    alt="sleepingLady"
                    src={sleepingLady}
                  />
                </Grid>
              </Grid>
              {width < 860 && (
                <Grid
                  container
                  className={clsx({
                    [classes.innerCollapseGrid]: true,
                  })}
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs></Grid>
                  <Grid item xs={10}>
                    <SocialMediaIcon />
                  </Grid>
                  <Grid item xs></Grid>
                </Grid>
              )}
              {/* FooterNavBar */}
              <Grid
                container
                direction="column"
                justify="center"
                className={clsx({
                  [classes.innerCollapseGrid]: true,
                })}
                style={{
                  backgroundColor: width < 860 ? "#141414" : undefined,
                  height: width < 860 ? "60px" : undefined,
                  position: width < 860 ? "absolute" : undefined,
                  bottom: width < 860 ? 0 : undefined,
                }}
                alignItems="center"
              >
                <Grid item xs={12} className={classes.footDrawer}>
                  <ul className={classes.footDrawerUL}>
                    {width > 860 ? (
                      <>
                        <li className={classes.footDrawerLI}>
                          <span
                            onClick={() => {
                              OpenPopUpModalFunc("Privacy");
                            }}
                            className={classes.footmenu}
                          >
                            PRIVACY POLICY
                          </span>
                          <span className={classes.footMenu}>·</span>
                        </li>
                        <li className={classes.footDrawerLI}>
                          <span
                            onClick={() => history.push("/contact-us")}
                            className={classes.footmenu}
                          >
                            CONTACT US
                          </span>
                          {width > 860 && (
                            <span className={classes.footMenu}>·</span>
                          )}
                        </li>
                        <li
                          className={classes.footDrawerLI}
                          onClick={() => {
                            OpenPopUpModalFunc("TandC");
                          }}
                        >
                          <span className={classes.footmenu}>
                            TERMS &amp; CONDITIONS
                          </span>
                        </li>
                      </>
                    ) : (
                      <li className={classes.footDrawerLI}>
                        <span
                          onClick={() => history.push("/contact-us")}
                          className={classes.footmenu}
                        >
                          CONTACT US
                        </span>
                        {width > 860 && (
                          <span className={classes.footMenu}>·</span>
                        )}
                      </li>
                    )}
                  </ul>
                </Grid>
              </Grid>
            </Grid>
          </CSSTransition>
          {width > 860 && (
            <SubNavList
              closeMenuFunc={closeMenuFunc}
              topOpen={topOpen}
              isNavDrawerOpen={isNavDrawerOpen}
            />
          )}
        </Grid>
      </Drawer>
      <MenuButton
        OpenPopUpModal={OpenPopUpModal}
        OpenPopUpModalFunc={OpenPopUpModalFunc}
        OnPageChange={OnPageChange}
        openMenuFunc={openMenuFunc}
        isMenuOpen={isMenuOpen}
        isNavDrawerOpen={isNavDrawerOpen}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  pdf_data_status: state.main.pdf_data_status,
});

const mapDispatchToProps = (dispatch) => ({
  getpdfurl: () =>
    dispatch({
      type: START_GET_PDF_URL,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DrawerMenu));
