import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CroppedDPA from '../../assets/dca-logo-cropped-white.svg';

const useStyle = makeStyles(theme=>({ 
    text:{
        display:"inline !important",
        textAlign:"center",
        '& span':{
            fontFamily: "serif",
            fontSize: "25px",
            letterSpacing: "-5px",
            
        }
    },
    coloredText:{
        color:theme.palette.primary.main
    },
    img : {
        width : 'auto',
        height : 16,
        [theme.breakpoints.down("sm")]: {
            height: 12,
        },
    }
}));

const Logo=({coloredText=false}) => {
    const classes = useStyle();
    return (
        <div className={clsx(classes.text,{[classes.coloredText]:coloredText})}>
            <img src={CroppedDPA} alt='dpa' className={classes.img}/>
        </div>
    )
}
export default Logo