import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
  headers: { "Content-Type": "application/json", Accept: "application/json" },
  validateStatus: function(status) {
    return (status >= 200 && status < 300) || status === 401 || status === 422;
  },
});

function setAccessTokenInLocalStorage(token) {
  const { localStorage } = window;
  try {
    return localStorage.setItem("token", token);
  } catch (e) {
    // memory full
    // console.log("Token not stored");
    return undefined;
  }
}

function getAccessTokenFromLocalStorage() {
  const { localStorage } = window;
  const token = localStorage.getItem("token");
  return token || "";
}

function clearLocalStorage() {
  const { localStorage } = window;
  // console.log("in clear storage")
  localStorage.clear();
}

function post(url, paramObj, headers = {}) {
  instance.defaults.headers.common["Authorization"] =
    "Bearer " + getAccessTokenFromLocalStorage(); // set token before making request
  if (headers.socket_id) {
    instance.defaults.headers.common["X-Socket-ID"] = headers.socket_id;
  }
  return instance
    .post(url, paramObj)
    .then((response) => {
      switch (response.status) {
        case 200:
          return response.data;
        case 401:
          return { status: false, unauthenticated: true };
        case 422:
          return { status: false, message: "Ill formed response!" };
        default:
          return { status: false, message: "Ill formed response!" };
      }
    })
    .then((response) => {
      if (response.success) {
        return response.data;
      } else {
        return response;
      }
    })
    .catch((error) => {
      return {
        status: false,
        message: error.message || "Something went wrong! Try again later",
      };
    });
}

function get(url, paramObj = {}, headers = {}) {
  instance.defaults.headers.common["Authorization"] =
    "Bearer " + getAccessTokenFromLocalStorage();
  // set token before making request
  // console.log("headers.socket_id = ", headers.socket_id);
  if (headers.socket_id) {
    instance.defaults.headers.common["X-Socket-ID"] = headers.socket_id;
  }
  return instance
    .get(url, {
      params: paramObj,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 401) {
        return { status: false, unauthenticated: true };
      }
      return {
        success: false,
        message: response.message || "Ill formed response!",
      };
    })
    .then((response) => {
      if (response.success) {
        return response.data;
      } else {
        return response;
      }
    })
    .catch((error) => {
      // console.log(error)
      return {
        status: false,
        message: error.message || "Something went wrong! Try again later",
      };
    });
}
export default {
  get,
  post,
  getAccessTokenFromLocalStorage,
  setAccessTokenInLocalStorage,
  clearLocalStorage,
  baseUrl,
};
