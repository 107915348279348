import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import Logo from "../LogoWhite";
import MenuComponent from "./MenuIcon";
// import MenuIcon from "../../../assets/image/menu-icon.svg";
import { CSSTransition } from "react-transition-group";
const useStyle = makeStyles((theme) => ({
  menuBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: "124px",
    width: "50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
    "& :hover": {
      backgroundColor: theme.palette.primary.light,
      transition: `background 250ms ease-in-out`,
    },
    position: "absolute",
    top: "0%",
    cursor: "pointer",
    "& div": {
      color: grey[50],
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    animation: "$initialSlide 150ms",
    [theme.breakpoints.down("sm")]: {
      height: "90px !important",
      width: "36px !important",
    },
  },
  "@keyframes initialSlide": {
    from: {
      transform: "translateX(-100%)",
    },
    to: {
      transform: "translateX(0%)",
    },
  },
  menuIcon: {
    marginTop: 8,
    height: 16,
    width: 17,
    [theme.breakpoints.down("sm")]: {
      height: 12,
      width: 14,
    },
  },
  opened: {
    position: "absolute",
    marginLeft: "60%",
    transition: "all 300ms ease",
    zIndex: theme.zIndex.drawer + 101,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "90%",
    },
    "@media (max-width: 768px)": {
      position: "absolute",
      right: "0px",
    },
    "@media (max-width: 1366px)": {
      marginLeft: "71%",
    },
  },
  closed: {
    position: "absolute",
    marginLeft: "50%",
    transition: "all 300ms ease",
    zIndex: theme.zIndex.drawer + 101,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "90%",
    },
  },
  "@keyframes eachPageOut": {
    "0%": {
      transform: "translateX(0%)",
    },
    "100%": {
      transform: "translateX(-100%)",
    },
  },
  onEachPage: {
    animation: "$eachPageOut 5s ease-in-out",
  },
  modalOpen: {
    //zIndex:"1300"
  },
}));

const MenuButton = ({
  openMenuFunc,
  isMenuOpen,
  isNavDrawerOpen,
  OnPageChange,
  OpenPopUpModal,
}) => {
  const classes = useStyle();

  return (
    <CSSTransition in={isMenuOpen} timeout={500} classNames="menuBtnTrans">
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={clsx(classes.menuBar, {
          [classes.opened]: isNavDrawerOpen && isMenuOpen,
          [classes.closed]: !isNavDrawerOpen && isMenuOpen,
          [classes.onEachPage]: OnPageChange,
          [classes.modalOpen]: OpenPopUpModal,
        })}
        onClick={() => openMenuFunc()}
      >
        <Grid
          justify="center"
          container
          alignItems="center"
          item
          xs={12}
          direction="row"
        >
          <Logo />

          <MenuComponent />
          {/* <img src={MenuIcon} alt='menu button' className={classes.menuIcon}/> */}
        </Grid>
      </Grid>
    </CSSTransition>
  );
};
export default MenuButton;
