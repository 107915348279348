import React, { useEffect,useLayoutEffect,useState } from "react";
import { makeStyles } from "@material-ui/core";
import SuccessStories from "./HomePages/SuccessStories";
import IamgeGallery from "./HomePages/ImageGallery";
import MeetDrPalin from "./HomePages/MeetDrPalin";
import AestheCliniPage from "./HomePages/AestheCliniPage";


const useStyles = makeStyles((theme) => ({
    root: {
        overflow: "auto",
        height: "100%",
    },
    bodytagMine: {
        height: "auto",
        overflow: "initial",
        background: "black",
    },
}));

export default ({ pageNo, OpenPopUpModal, OpenPopUpModalFunc,width,handlePageChange }) => {
    const classes = useStyles();

    const divRef = React.useRef(null);
   

    useEffect(() => {
        document.body.className = classes.bodytagMine;
        divRef.current.focus();
        divRef.current.scrollTo(0, 0);
        divRef.current.scroll(0, 0);
        return () => {
            document.body.className = "";
        };
    }, [pageNo]);

    

    return (
        <div className={classes.root} ref={divRef}>
            {width < 860 &&
            <>
            <AestheCliniPage pageNo={pageNo} handlePageChange={handlePageChange} width={width} />
            <MeetDrPalin pageNo={pageNo} />
            </>
}
            <SuccessStories width={width}  handlePageChange={handlePageChange} pageNo={pageNo}/>
            <IamgeGallery
                OpenPopUpModal={OpenPopUpModal}
                OpenPopUpModalFunc={OpenPopUpModalFunc}
            />
        </div>
    );
};
