import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

// const LightTooltip = withStyles((theme) => ({
//     tooltip: {
//       arrow : "#89137d",
//       backgroundColor: "#89137d",
//       color: 'white',
//       boxShadow: theme.shadows[1],
//       fontSize: "0.8rem",
//     },
// }))(Tooltip)

const useStyles = makeStyles((theme) => ({
    arrow: {
      color: "#89137d",
    },
    tooltip: {
      backgroundColor: "#89137d",
      color: 'white',
      boxShadow: theme.shadows[1],
      fontSize: "0.8rem",
      top : `7px !important`
    }
}));

const MyTooltip = (props) => {
    const{
        placement = 'top',
        children,
        title = "Read More"
    } = props;
    const classes = useStyles();
    return (
       <>
            <Tooltip 
                title={title}
                placement = {placement}
                arrow
                classes = {classes}
            >
                {children}
            </Tooltip>
       </>
    )
}

export default MyTooltip