import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import defaultPDF from '../assets/dpa.pdf'

const useStyles = makeStyles(theme => ({
    main_container : {
        height : '100vh',
        width : '100vw',
        overflow : 'hidden'
    }
}))

const DpaPdf =  (props) => {
    const classes = useStyles()
    return (
        <div className={classes.main_container}>
            <iframe
              src={defaultPDF}
              style={{
                  height : '100%',
                  width : '100%',
              }}
              title={'DPA'}
            />
        </div>
    )    
}

export default connect()(DpaPdf)