import React, { useLayoutEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import meetDr from "../../assets/image/meetdrpalin.png";
import quotes from "../../assets/quotes.png";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import SocialMediaIconNew from "../Common/SocialMediaIconCommon";
import FootDivider from "../Common/FootDivider";
import ReLoadGif from "../Common/ReLoadGif";
import NameTag from "../../assets/NameTag.png";
import { withRouter } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MyTypograph from "../Common/MyTypograph";
import signGif from "../../assets/Signature-Transperent.gif";

const useStyles = makeStyles((theme) => ({
  containerMeetDr: {
    display: "flex",
    backgroundColor: "white",
    height: "100vh",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginTop: "100px",
      flexDirection: "column",
      height: "100vh",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
      flexDirection: "column",
    },
  },
  ImgMeetDr: {
    display: "flex",
    position: "absolute",
    left: "-2%",
    bottom: "0%",
    width: "34%",
    zIndex: "1",
  },
  quote_img: {
    width: "100%",
  },
  quote: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  quoteMessageContainer: {
    textAlign: "center",
    justifyContent: "flex-end",
    display: "flex",
    position: "relative",
    top: -40,
    right: 24,
    [theme.breakpoints.down("sm")]: {
      top: -20,
      right: 0,
    },
    [theme.breakpoints.down("xs")]: {
      top: 80,
      right: 0,
    },
  },
  drInfo: {
    display: "flex",
  },
  quoteText: {
    fontSize: "2.5rem",
    [theme.breakpoints.up("xl")]: {
      fontSize: "4rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  sign: {
    color: "black",
    width: "230px",
    height: 130.63,
    display: "flex",
    placeSelf: "center",
    outline: "none",
    border: "none",
    [theme.breakpoints.only("md")]: {
      width: "180px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "320px",
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  quoteMessage: {
    display: "grid",
    "& h6": {
      lineHeight: "1.2",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "50px 0",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "90px 0",
    },
  },
  quoma: {
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 8,
    },
  },
  quoteImg: {
    height: "30px",
    [theme.breakpoints.only("md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "15px",
    },
  },
  drInfoTitle: {
    fontFamily: "CenturyGothicBold",
    color: "#343E47",
    fontSize: "1.7rem",
    fontWeight: "700",
    letterSpacing: "0.1rem",
    [theme.breakpoints.only("md")]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  drInfoSubtitle: {
    fontFamily: "GothicRegular",
    color: "#343E47",
    fontSize: "1.2rem",
    fontWeight: "400",
    margin: "2px 0 0 0",
    [theme.breakpoints.only("md")]: {
      fontSize: "1.05rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.5rem",
    },
  },
  drInfoContainer: {
    display: "grid",
    width: "85%",
    flexDirection: "column",
    rowGap: "24px",
    [theme.breakpoints.only("xl")]: {
      rowGap: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  drInfoSubTitle: {
    display: "flex",
    width: "55%",
    paddingTop: 10,
  },
  drInfoSubTitleImg: {
    height: "15px",
    margin: "auto",
    width: "auto",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  drInfoDescription: {
    fontFamily: "GothicRegular",
    color: "#343E47",
    width: "86%",
    display: "grid",
    rowGap: "12px",
    placeSelf: "end",
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.41rem",
      rowGap: "18px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "0.65rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      rowGap: "6px",
    },
  },
  SocialMediaIcon: {
    right: 0,
    position: "absolute",
    top: 0,
    fontSize: "40px",
    margin: "45px",
  },
  btnDiv: {
    display: "flex",
    justifyContent: "space-around",
    width: "85%",
    placeSelf: "end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      justifyContent: "center",
      marginTop: "170px",
    },
  },
  buttonRoot: {
    borderRadius: 0,
    padding: "1px 18px",
    "&:hover": {
      backgroundColor: `rgba(186,160,121,0.5) !important`,
      transition: `all 250ms`,
      animation: "none",
      "& *": {
        color: "white !important",
      },
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "20px",
      paddingBottom: "20px",
    },
  },
  buttonLabel: {
    color: theme.palette.primary.main,
    fontSize: ".84rem !important",
    letterSpacing: "0.2rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-14px",
    },
  },
  threeParas: {
    margin: "6px 0",
  },
  scrollButton: {
    width: "200px",
    borderRadius: 0,
    borderWidth: "1px !important",
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 6,
    animation: "$intropic 3s infinite",
    boxShadow: "0px 0px 0px 0px rgba(186,160,121,0.6)",
    "&:hover": {
      backgroundColor: `rgba(186,160,121,0.5) !important`,
      transition: `all 250ms`,
      animation: "none",
      "& *": {
        color: "white !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  "@keyframes intropic": {
    "0%": {
      transform: "scale(.9)",
    },
    "70%": {
      transform: "scale(1)",
      boxShadow: "0px 0px 0px 50px rgba(186,160,121,0)",
    },
    "100%": {
      transform: "scale(.9)",
      boxShadow: "0px 0px 0px 0px rgba(186,160,121,0)",
    },
  },
  iconDownRoot: {
    fontSize: "1.9rem !important",
    color: theme.palette.primary.main,
    transition: `all 250ms`,
  },
  photoAndParagraphs: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: -200,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
      flexDirection: "column",
      justifyContent: "center",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
    },
  },
  title_container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      padding: "25px 0px",
    },
  },
  innerMainContainerTitileAC: {
    height: "100px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      margin: "10px 0",
    },
  },
  afterCareTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      letterSpacing: "0.3rem",
    },
  },
  title: {
    fontWeight: 700,
    fontFamily: "GothicRegular",
    letterSpacing: "0.05rem",
    fontSize: "1.9rem",
    [theme.breakpoints.down("sm")]: {
      letterSpacing: "0.01 rem",
      fontSize: "1.2rem",
      fontWeight: 800,
      textAlign: "center",
      fontFamily: "OpenSansBold",
    },
  },
  subtitle: {
    fontFamily: "GothicRegular",
    fontWeight: 400,
    letterSpacing: "0.05rem",
    [theme.breakpoints.down("sm")]: {
      letterSpacing: "0.01 rem",
      fontSize: "1.3rem",
      fontWeight: 800,
      textAlign: "center",
      fontFamily: "OpenSansBold",
    },
  },
  titleImage: {
    width: 300,
    margin: theme.spacing(1.5, 0),
    [theme.breakpoints.down("sm")]: {
      width: 285,
    },
  },
  mainPhotoContainer: {
    width: "38%",
    [theme.breakpoints.up("xl")]: {
      width: "28%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "30%",
      position: "relative",
      left: "30%",
    },
  },
  mainPhoto: {
    width: "100%",
    height: "auto",
    position: "relative",
    top: "-80px",
    [theme.breakpoints.down("sm")]: {
      top: "unset",
    },
  },
  ReLoadGifDiv: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "& img": {
      width: "100%",
      height: "auto",
      border: "0 solid",
      outline: "none",
    },
  },
}));

const MeetDrPalin = ({ history, handlePageChange, pageNo }) => {
  const classes = useStyles();
  const isFirefox = typeof InstallTrigger !== "undefined";
  const [width, setInnerWidth] = useState(0);
  useLayoutEffect(() => {
    function updateWindowDimensions() {
      setInnerWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateWindowDimensions);
    updateWindowDimensions();
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    <div>
      <div className={classes.containerMeetDr}>
        {width > 860 && (
          <>
            <div className={classes.SocialMediaIcon}>
              <SocialMediaIconNew />
            </div>
            <div className={classes.ImgMeetDr}>
              <img
                className={classes.quote_img}
                align="bottom"
                src={meetDr}
                alt="Meet Dr.Palin"
              />
            </div>
          </>
        )}
        {width < 860 && (
          <div className={classes.photoAndParagraphs}>
            <div className={classes.title_container}>
              <div className={classes.innerMainContainerTitileAC}>
                <MyTypograph variant="Title" className={classes.afterCareTitle}>
                  MEET DR PALIN
                </MyTypograph>
              </div>
              <div className={classes.title}>
                Dr Martin Scott Palin&nbsp;
                <span className={classes.subtitle}>MBChB MRCS</span>
              </div>
              <img
                src={NameTag}
                alt="name tag"
                className={classes.titleImage}
              />
            </div>
            <div className={classes.mainPhotoContainer}>
              <img src={meetDr} className={classes.mainPhoto} alt="dr palin" />
            </div>
          </div>
        )}
        <div className={classes.quote}>
          {width < 860 && (
            <div className={classes.btnDiv}>
              <Button
                variant="outlined"
                classes={{
                  root: classes.scrollButton,
                  label: classes.buttonLabel,
                }}
                onClick={(e) => handlePageChange(pageNo + 1)}
                endIcon={
                  <KeyboardArrowDownIcon
                    classes={{
                      root: classes.iconDownRoot,
                    }}
                  />
                }
                tabIndex={"0"}
                disableFocusRipple
              >
                SCROLL DOWN
              </Button>
              <Button
                onClick={() => history.push("/meet-dr-palin")}
                variant="outlined"
                disableElevation
                classes={{
                  root: classes.buttonRoot,
                  label: classes.buttonLabel,
                }}
              >
                Learn More
              </Button>
            </div>
          )}
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className={classes.quoteMessageContainer}
          >
            <div className={classes.quoteMessage}>
              <div className={classes.quoma}>
                <img alt="" className={classes.quoteImg} src={quotes} />
              </div>
              <Typography
                color="primary"
                className={classes.quoteText}
                variant="subtitle2"
              >
                Together, let’s
                <br />
                take a step closer
                <br />
                to a better you
                <br />
              </Typography>

              <div className={classes.sign}>
                {isFirefox ? (
                  <div className={classes.ReLoadGifDiv}>
                    <img alt="" src={signGif} />
                  </div>
                ) : (
                  <ReLoadGif />
                )}
              </div>
              <img className={classes.drInfoSubTitleImg} alt="" src={NameTag} />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            className={classes.drInfo}
          >
            <div className={classes.drInfoContainer}>
              <div className={classes.drInfoDescription}>
                <div className={classes.drInfoTitle}>
                  Dr Martin Scott Palin
                  <p className={classes.drInfoSubtitle}>MBChB MRCS</p>
                </div>
                <div className={classes.threeParas}>
                  As a neurosurgeon, I have performed over 600 brain and spinal
                  operations. Some of these procedures were devastating to the
                  appearance of the patient - particularly when surgery was
                  mainly focused on the facial area.
                </div>
                <div className={classes.threeParas}>
                  These had a long lasting impact on the patient’s confidence,
                  identity and quality of life. Therefore, I devised methods to
                  not only achieve the best possible medical outcome but also
                  the most aesthetic result.
                </div>
                <div className={classes.threeParas}>
                  Having seen the incredible difference this made to the
                  patient’s confidence and wellbeing, I am now here to help you.
                </div>
              </div>
              {width > 860 && (
                <div className={classes.btnDiv}>
                  <Button
                    variant="outlined"
                    classes={{
                      root: classes.scrollButton,
                      label: classes.buttonLabel,
                    }}
                    onClick={(e) => handlePageChange(pageNo + 1)}
                    endIcon={
                      <KeyboardArrowDownIcon
                        classes={{
                          root: classes.iconDownRoot,
                        }}
                      />
                    }
                    tabIndex={"0"}
                    disableFocusRipple
                  >
                    SCROLL DOWN
                  </Button>
                  <Button
                    onClick={() => history.push("/meet-dr-palin")}
                    variant="outlined"
                    disableElevation
                    classes={{
                      root: classes.buttonRoot,
                      label: classes.buttonLabel,
                    }}
                  >
                    Learn More
                  </Button>
                </div>
              )}
            </div>
          </Grid>
        </div>
        {width > 860 && <FootDivider />}
      </div>
    </div>
  );
};

export default withRouter(MeetDrPalin);
