// Augmentation menu images
import Jawline from "../assets/image/menulink/jawline.jpg";
import Lips from "../assets/image/menulink/lips.jpg";
import Cheeks from "../assets/image/menulink/Cheeks.jpg";
import Chin from "../assets/image/menulink/Chin.jpg";
import Periorbital from "../assets/image/menulink/Periorbital.jpg";
import ForeheadLines from "../assets/image/menulink/Forehead lines.jpg";
import FrownLinesEdited from "../assets/image/menulink/FROWN LINES.png";
import NefertitiNeckLift from "../assets/image/menulink/neck.jpg";
import MarionetteLines from "../assets/image/menulink/Marionette Lines Edited.jpg";
import NasiolabialFolds from "../assets/image/menulink/Nasiolabial folds edited.jpg";
import NoseRhinoplasty from "../assets/image/menulink/Nose Rhinoplasty.jpg";
import LegSlimming from "../assets/image/menulink/leg_slimming.png";
import EyebrowLift from "../assets/image/menulink/Eyebrow lift.jpg";
import GummySmile from "../assets/image/menulink/Gummy smile.jpg";
import BunnyLines from "../assets/image/menulink/Bunny Lines.jpg";
import CrowsFeet from "../assets/image/menulink/Crows Feet.jpg";
import Temples from "../assets/image/menulink/Temples.jpg";
import WeightLossGirl from "../assets/image/menulink/weight-loss-girl.jpeg";
// Rejuvenation menu Images
import AcneScarring from "../assets/image/menulink/Acne Scarring.jpg";
import AcneTreatment from "../assets/image/menulink/Acne Treatment.jpg";
import Hyperpigmentation from "../assets/image/menulink/Hyperpigmentation.jpg";
import StretchMarks from "../assets/image/menulink/Stretch Marks.jpg";
import DiffuseRedness from "../assets/image/menulink/Diffuse redness.jpeg";
import skinightening from "../assets/image/menulink/skin tightening.jpeg";
import CollagenBoost from "../assets/image/menulink/Collagen Boost.jpg";

// Dermatology menu Image
import ThreadVeins from "../assets/image/menulink/Thread veins.jpg";
import VaricoseVeins from "../assets/image/menulink/Varicose Veins copy.jpg";
import Blackheads from "../assets/image/menulink/Blackheads.jpg";
import SkinTag from "../assets/image/menulink/SKIN-TAG.jpg";
import Warts from "../assets/image/menulink/Warts.jpg";
import MoleRemoval from "../assets/image/menulink/mole.jpg";
import MilliaRemoval from "../assets/image/menulink/Millia removal.jpg";
import FungalNailInfection from "../assets/image/menulink/funal nail.jpg";
import VascularLesions from "../assets/image/menulink/Vascular Lesions.jpg";

// For men menu images
import ApolloFacelift from "../assets/image/menulink/Apollo Facelift.jpg";

//Joints & Tendons images
import MedialEpicondylitis from "../assets/image/menulink/Medial Epicondylitis .jpeg";
import RotatorCuffTendonitis from "../assets/image/menulink/Rotator Cuff Tendonitis .jpg";
import CarpalTunnelSyndrome from "../assets/image/menulink/Carpal Tunnel Syndrome .jpeg";
import AchillesTendonitis from "../assets/image/menulink/Achilles Tendonitis.jpeg";
import PlanterFasciitis from "../assets/image/menulink/Planter Fasciitis .jpeg";
import LateralEpicondylitis from "../assets/image/menulink/tennis elbow.jpg";

// Other menu images
import Eyelashes from "../assets/image/menulink/Eyelashes.jpg";
import Migraine from "../assets/image/menulink/Migraine.jpg";
import TeethGrinding from "../assets/image/menulink/Teeth Grinding.jpg";
import ExcessSweating from "../assets/image/menulink/Sweating copy.jpg";
import Hairloss from "../assets/image/menulink/Hairloss.jpg";
import B12Deficiency from "../assets/image/menulink/B12 deficiency.jpg";
import LaserHairRemoval from "../assets/image/menulink/laser.jpg";

// ALL Treatment Menu images

import AntiWrinkleBotox from "../assets/image/menulink/Anti-wrinkle botox.jpg";
import DermalFillers from "../assets/image/menulink/Dermal Fillers.jpg";
import MicroscleroTherapy from "../assets/image/menulink/microsclerotherapy.jpg";
import PRPTherapy from "../assets/image/menulink/PRP Therapy.jpg";
import VitaminInfusion from "../assets/image/menulink/Vitamin Infusion Website.jpg";
import SkinPeels from "../assets/image/menulink/Skin Peels.jpg";
import Microneedling from "../assets/image/menulink/Micro needling.jpg";
import VitaminB12Injections from "../assets/image/menulink/B12 deficiency.jpg";
import Pabrinex from "../assets/image/menulink/Pabrinex.jpg";
import Antioxidants from "../assets/image/menulink/Antioxidants.jpg";
import Bimatoprost from "../assets/image/menulink/bimatoprost.jpg";
import Ozempic from "../assets/image/menulink/ozempic.png";
import Aqualyx from "../assets/image/menulink/aqualyx.jpg";
import Deso from "../assets/image/menulink/deso.jpg";
import Sexanda from "../assets/image/menulink/Sexanda.jpg";
import Gentlemaxpro from "../assets/image/menulink/gentlemaxpro.jpg";

// import { vitaminInfusion } from './commonFaqdata'

const PerferedTreatment = [
  {
    name: "Anti-Wrinkle Botox\xAE",
    comingSoon: false,
    src: "botox",
    id: 1,
  },
  {
    name: "Dermal Fillers",
    comingSoon: false,
    src: "filler",
    id: 2,
  },
  {
    name: "The Apollo Facelift® for Men",
    comingSoon: false,
    src: "apollo-facelift",
    id: 3,
  },
  {
    name: "Microsclerotherapy",
    comingSoon: false,
    src: "micro-sclero-therapy",
    id: 4,
  },
  {
    name: "Platelet-Rich Plasma (PRP) Therapy",
    comingSoon: false,
    src: "paltelet-rich-plasma",
    id: 5,
  },
  {
    name: "Eyelash Growth",
    comingSoon: false,
    src: "eyelashgrowth",
    id: 6,
  },
  {
    name: "Vitamin Infusion",
    comingSoon: false,
    src: "vitamin-infusions",
    id: 7,
  },
  {
    name: "OBAGI® Skin Treatment",
    comingSoon: false,
    src: "obagi-skin-treatment",
    id: 8,
  },
  {
    name: "Microneedling",
    comingSoon: false,
    src: "microneedling",
    id: 9,
  },
  {
    name: "Other",
    comingSoon: false,
    src: "microneedling",
    id: 10,
  },
];

const Treatment = [
  {
    key: false,
    data: [
      {
        name: "Anti-Wrinkle Botox\xAE",
        comingSoon: false,
        src: "botox",
        menuimage: AntiWrinkleBotox,
      },
      {
        name: "Dermal Fillers",
        comingSoon: false,
        src: "filler",
        menuimage: DermalFillers,
      },
      {
        name: "The Apollo Facelift® for Men",
        comingSoon: false,
        src: "apollo-facelift",
        menuimage: ApolloFacelift,
      },
      {
        name: "Microsclerotherapy",
        comingSoon: false,
        src: "micro-sclero-therapy",
        menuimage: MicroscleroTherapy,
      },
      {
        name: "Platelet-Rich Plasma (PRP) Therapy",
        comingSoon: false,
        src: "paltelet-rich-plasma",
        menuimage: PRPTherapy,
      },
      {
        name: "Vitamin Infusion",
        comingSoon: false,
        src: "vitamin-infusions",
        menuimage: VitaminInfusion,
      },
      {
        name: "OBAGI® Skin Treatment",
        comingSoon: false,
        src: "obagi-skin-treatment",
        menuimage: SkinPeels,
      },
      {
        name: "Microneedling",
        comingSoon: false,
        src: "microneedling",
        menuimage: Microneedling,
      },
      {
        name: "Vitamin B12 Injections",
        comingSoon: false,
        src: "vitamin-b12-def",
        menuimage: VitaminB12Injections,
      },
      {
        name: "Pabrinex® Vitamin C & B Complex Infusion",
        comingSoon: false,
        src: "vitamin-infusions",
        menuimage: Pabrinex,
      },
      {
        name: "Glutathione/Antioxidants Infusion",
        comingSoon: false,
        src: "vitamin-infusions",
        menuimage: Antioxidants,
      },
      {
        name: "Bimatoprost 0.03% Eyelash Solution",
        comingSoon: false,
        src: "eyelashgrowth",
        menuimage: Bimatoprost,
      },
      {
        name: "Ozempic®",
        comingSoon: false,
        src: "weight-loss",
        menuimage: Ozempic,
      },
      {
        name: "Aqualyx®",
        comingSoon: false,
        src: "weight-loss",
        menuimage: Aqualyx,
      },
      {
        name: "Deso®",
        comingSoon: false,
        src: "weight-loss",
        menuimage: Deso,
      },
      {
        name: "Saxenda®",
        comingSoon: false,
        src: "weight-loss",
        menuimage: Sexanda,
      },
      {
        name: "GentleMax Pro® Laser Therapy",
        comingSoon: false,
        src: "gentlemax-pro",
        menuimage: Gentlemaxpro,
      },
    ],
  },
];

const Augmentation = [
  {
    name: "Jawline",
    comingSoon: false,
    src: "jawline",
    menuimage: Jawline,
  },
  {
    name: "Lips",
    comingSoon: false,
    src: "lips",
    menuimage: Lips,
  },
  {
    name: "Cheek",
    comingSoon: false,
    src: "cheeks",
    menuimage: Cheeks,
  },
  {
    name: "Chin",
    comingSoon: false,
    src: "chin",
    menuimage: Chin,
  },
  {
    name: "Tear Trough & Periorbital ",
    comingSoon: false,
    src: "trea-trough",
    menuimage: Periorbital,
  },
  {
    name: "Forehead Lines",
    comingSoon: false,
    src: "forehead-lines",
    menuimage: ForeheadLines,
  },
  {
    name: "Frown Lines",
    comingSoon: false,
    src: "frown-lines",
    menuimage: FrownLinesEdited,
  },
  {
    name: "Nefertiti Neck Lift",
    comingSoon: false,
    src: "nefertiti-neck-lift",
    menuimage: NefertitiNeckLift,
  },
  {
    name: "Marionette Lines",
    comingSoon: false,
    src: "marionette-lines",
    menuimage: MarionetteLines,
  },
  {
    name: "Nasolabial Folds",
    comingSoon: false,
    src: "nasolabial-folds",
    menuimage: NasiolabialFolds,
  },
  {
    name: "Nose (Liquid Rhinoplasty)",
    comingSoon: false,
    src: "nose",
    menuimage: NoseRhinoplasty,
  },
  {
    name: "Leg Slimming",
    comingSoon: false,
    src: "leg-slimming",
    menuimage: LegSlimming,
  },
  {
    name: "Eyebrow Lift",
    comingSoon: false,
    src: "eyebrows-lift",
    menuimage: EyebrowLift,
  },
  {
    name: "Gummy Smile Correction",
    comingSoon: false,
    src: "gummy-smile",
    menuimage: GummySmile,
  },
  {
    name: "Bunny Lines",
    comingSoon: false,
    src: "bunny-lines",
    menuimage: BunnyLines,
  },
  {
    name: "Crow’s Feet",
    comingSoon: false,
    src: "crows-feet",
    menuimage: CrowsFeet,
  },
  {
    name: "Temples",
    comingSoon: false,
    src: "temple",
    menuimage: Temples,
  },
];

const Rejuvenation = [
  {
    name: "Acne Scar Treatment",
    comingSoon: false,
    src: "acne-scarring",
    menuimage: AcneScarring,
  },
  {
    name: "Acne Treatment",
    comingSoon: false,
    src: "acne",
    menuimage: AcneTreatment,
  },
  {
    name: "Hyperpigmentation",
    comingSoon: false,
    src: "hyperpigmentation",
    menuimage: Hyperpigmentation,
  },
  {
    name: "Stretch Marks",
    comingSoon: false,
    src: "stretch-marks",
    menuimage: StretchMarks,
  },
  {
    name: "Weight Loss/Fat Dissolving",
    comingSoon: false,
    src: "weight-loss",
    menuimage: WeightLossGirl,
  },
  {
    name: "Diffuse Redness",
    comingSoon: false,
    src: "gentlemax-pro",
    menuimage: DiffuseRedness,
  },
  {
    name: "Skin Tightening",
    comingSoon: false,
    src: "gentlemax-pro",
    menuimage: skinightening,
  },
  {
    name: "Collagen Boost",
    comingSoon: false,
    src: "gentlemax-pro",
    menuimage: CollagenBoost,
  },
];

const Dermatology = [
  {
    name: "Thread Veins",
    comingSoon: false,
    src: "thread-veins",
    menuimage: ThreadVeins,
  },
  {
    name: "Varicose Veins",
    comingSoon: false,
    src: "varicose",
    menuimage: VaricoseVeins,
  },
  {
    name: "Blackheads Removal",
    comingSoon: false,
    src: "black-heads",
    menuimage: Blackheads,
  },
  {
    name: "Skin Tag Removal",
    comingSoon: false,
    src: "skin-tag-removal",
    menuimage: SkinTag,
  },
  {
    name: "Wart Removal",
    comingSoon: true,
    src: "",
    menuimage: Warts,
  },
  {
    name: "Mole Removal",
    comingSoon: true,
    src: "",
    menuimage: MoleRemoval,
  },
  {
    name: "Milia Removal ",
    comingSoon: false,
    src: "milia",
    menuimage: MilliaRemoval,
  },
  {
    name: "Fungal Nail Infection",
    comingSoon: false,
    src: "fungal-nail-infection",
    menuimage: FungalNailInfection,
  },
  {
    name: "Vascular Lesions",
    comingSoon: false,
    src: "gentlemax-pro",
    menuimage: VascularLesions,
  },
];

const JointsandTendons = [
  {
    name: "Lateral Epicondylitis (Tennis elbow)",
    comingSoon: false,
    src: "paltelet-rich-plasma",
    menuimage: LateralEpicondylitis,
  },
  {
    name: "Medial Epicondylitis (Golfers elbow)",
    comingSoon: false,
    src: "paltelet-rich-plasma",
    menuimage: MedialEpicondylitis,
  },
  {
    name: "Rotator Cuff Tendonitis",
    comingSoon: false,
    src: "paltelet-rich-plasma",
    menuimage: RotatorCuffTendonitis,
  },
  {
    name: "Carpal Tunnel Syndrome ",
    comingSoon: false,
    src: "paltelet-rich-plasma",
    menuimage: CarpalTunnelSyndrome,
  },
  {
    name: "Achilles Tendonitis",
    comingSoon: true,
    src: "paltelet-rich-plasma",
    menuimage: AchillesTendonitis,
  },
  {
    name: "Planter Fasciitis",
    comingSoon: true,
    src: "paltelet-rich-plasma",
    menuimage: PlanterFasciitis,
  },
];

const ForMen = [
  {
    name: "The Apollo Facelift® (Face Sculpting)",
    comingSoon: false,
    src: "apollo-facelift",
    menuimage: ApolloFacelift,
  },
];

const Other = [
  {
    name: "Eyelash Growth",
    comingSoon: false,
    src: "eyelashgrowth",
    menuimage: Eyelashes,
  },
  {
    name: "Migraines",
    comingSoon: false,
    src: "migraine",
    menuimage: Migraine,
  },
  {
    name: "Teeth Grinding",
    comingSoon: false,
    src: "teeth-grinding",
    menuimage: TeethGrinding,
  },
  {
    name: "Excess Sweating",
    comingSoon: false,
    src: "excess-sweating",
    menuimage: ExcessSweating,
  },
  {
    name: "Hairloss & Prevention",
    comingSoon: false,
    src: "hairloss-treatment-og",
    menuimage: Hairloss,
  },
  {
    name: "Vitamin B12 Deficiency ",
    comingSoon: false,
    src: "vitamin-b12-def",
    menuimage: B12Deficiency,
  },
  {
    name: "Laser Hair Removal",
    comingSoon: false,
    src: "laser-hair-removal",
    menuimage: LaserHairRemoval,
  },
];

const TreatmentForTopMenu = [
  {
    key: false,
    data: [
      {
        name: "Anti-Wrinkle Botox\xAE",
        comingSoon: false,
        src: "botox",
        menuimage: AntiWrinkleBotox,
      },
      {
        name: "Dermal Fillers",
        comingSoon: false,
        src: "filler",
        menuimage: DermalFillers,
      },
      {
        name: "Microsclerotherapy",
        comingSoon: false,
        src: "micro-sclero-therapy",
        menuimage: MicroscleroTherapy,
      },
      {
        name: "Platelet-Rich Plasma (PRP) Therapy",
        comingSoon: false,
        src: "paltelet-rich-plasma",
        menuimage: PRPTherapy,
      },
      // {
      //   name: "Eyelash Growth",
      //   comingSoon: false,
      //   src: "eyelashgrowth",
      //   menuimage: Eyelashes,
      // },
      {
        name: "Vitamin Infusion",
        comingSoon: false,
        src: "vitamin-infusions",
        menuimage: VitaminInfusion,
      },
      {
        name: "OBAGI® Skin Treatment",
        comingSoon: false,
        src: "obagi-skin-treatment",
        menuimage: SkinPeels,
      },
      {
        name: "Microneedling",
        comingSoon: false,
        src: "microneedling",
        menuimage: Microneedling,
      },
      {
        name: "Vitamin B12 Injection",
        comingSoon: false,
        src: "vitamin-b12-def",
        menuimage: B12Deficiency,
      },
      {
        name: "Pabrinex® Vitamin C & B Complex Infusion",
        comingSoon: false,
        src: "vitamin-infusions",
        menuimage: Pabrinex,
      },
      {
        name: "Glutathione/Antioxidants Infusion",
        comingSoon: false,
        src: "vitamin-infusions",
        menuimage: Antioxidants,
      },
      {
        name: "Bimatoprost 0.03% Eyelash Solution",
        comingSoon: false,
        src: "eyelashgrowth",
        menuimage: Bimatoprost,
      },
      {
        name: "Ozempic®",
        comingSoon: false,
        src: "weight-loss",
        menuimage: Ozempic,
      },
      {
        name: "Aqualyx®",
        comingSoon: false,
        src: "weight-loss",
        menuimage: Aqualyx,
      },
      {
        name: "Deso®",
        comingSoon: false,
        src: "weight-loss",
        menuimage: Deso,
      },
      {
        name: "Saxenda®",
        comingSoon: false,
        src: "weight-loss",
        menuimage: Sexanda,
      },
      {
        name: "GentleMax Pro® Laser Therapy",
        comingSoon: false,
        src: "gentlemax-pro",
        menuimage: Gentlemaxpro,
      },
    ],
  },
];

const backGroundColor_2 = "#fcf7ef"; //SuccessStories, 404 page
const backGroundColor_1 = "#fbf9f7"; //ReviewCard
export {
  Treatment,
  backGroundColor_2,
  backGroundColor_1,
  Augmentation,
  Rejuvenation,
  Dermatology,
  ForMen,
  Other,
  JointsandTendons,
  PerferedTreatment,
  TreatmentForTopMenu,
};
