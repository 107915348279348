import {
  START_CHECK_FOR_OFFERS,
  SUCCESS_CHECK_FOR_OFFERS,
  ERROR_CHECK_FOR_OFFERS,
  START_SUBMIT_CONTACT_FORM,
  SUCCESS_SUBMIT_CONTACT_FORM,
  ERROR_SUBMIT_CONTACT_FORM,
  START_SUBSCRIBE_USER,
  SUCCESS_SUBSCRIBE_USER,
  ERROR_SUBSCRIBE_USER,
  START_SUBMIT_CONSULTATION_FORM,
  SUCCESS_SUBMIT_CONSULTATION_FORM,
  ERROR_SUBMIT_CONSULTATION_FORM,
  NOTIFICATION,
  EMPTY_NOTIFICATION,
  START_GET_PDF_URL,
  SUCCESS_GET_PDF_URL,
  ERROR_GET_PDF_URL,
  START_GET_CLIENT_SECRET_PAYMENT,
  SUCCESS_GET_CLIENT_SECRET_PAYMENT,
  ERROR_GET_CLIENT_SECRET_PAYMENT,
  START_SUBMIT_GIFT_VOUCHER_FORM,
  SUCCESS_SUBMIT_GIFT_VOUCHER_FORM,
  ERROR_SUBMIT_GIFT_VOUCHER_FORM,
  START_GIFT_VOUCER_LIST,
  SUCESS_GIFT_VOUCER_LIST,
  ERROR_GIFT_VOUCER_LIST,
  FETCH_GIFT_VOUCEHER_VIEWS,
  SUCESS_GIFT_VOUCEHER_VIEWS,
  ERROR_GIFT_VOUCEHER_VIEWS,
  START_TREATMENT_LIST_USER,
  SUCCESS_TREATMENT_LIST_USER,
  ERROR_TREATMENT_LIST_USER,
  START_SUBMIT_TESTIMONIAL_FORM,
  SUCCESS_SUBMIT_TESTIMONIAL_FORM,
  ERROR_SUBMIT_TESTIMONIAL_FORM,
} from "../actions/main";

import {
  take,
  call,
  put,
  takeLatest,
  takeEvery,
  select,
} from "redux-saga/effects";

import {
  checkoffers,
  contactus,
  subscribeuser,
  submitconsultationform,
  getpdfurl,
  getclientsecretforpayment,
  giftVoucher,
  giftVoucherList,
  giftVoucherDetailsView,
  treatmentList,
  testimonialView,
} from "../api/main";

export function* notificationwatcher() {
  yield takeEvery(
    [
      SUCCESS_SUBSCRIBE_USER,
      ERROR_SUBSCRIBE_USER,
      SUCCESS_SUBMIT_GIFT_VOUCHER_FORM,
      ERROR_SUBMIT_GIFT_VOUCHER_FORM,
      ERROR_GIFT_VOUCEHER_VIEWS,
      SUCCESS_SUBMIT_TESTIMONIAL_FORM,
      ERROR_SUBMIT_TESTIMONIAL_FORM,
    ],
    notificationworker
  );
}

function* notificationworker({ message, variant }) {
  yield put({
    type: NOTIFICATION,
    message,
    variant,
  });
  yield put({
    type: EMPTY_NOTIFICATION,
    message: "",
    variant: "",
  });
}

export function* checkofferssaga() {
  while (true) {
    yield take(START_CHECK_FOR_OFFERS);
    const response = yield call(checkoffers);
    if (response.status) {
      const { data: offers_data } = response;
      yield put({
        type: SUCCESS_CHECK_FOR_OFFERS,
        offers_data,
      });
    } else {
      yield put({
        type: ERROR_CHECK_FOR_OFFERS,
      });
    }
  }
}

export function* contactussaga() {
  yield takeLatest(START_SUBMIT_CONTACT_FORM, contactusworker);
}

function* contactusworker(form) {
  const response = yield call(contactus, { ...form });
  const { email } = form;
  if (response.status) {
    yield put({
      type: SUCCESS_SUBMIT_CONTACT_FORM,
      email,
    });
  } else {
    yield put({
      type: ERROR_SUBMIT_CONTACT_FORM,
    });
  }
}

export function* subscribeusersaga() {
  yield takeLatest(START_SUBSCRIBE_USER, subscribeuserworker);
}

function* subscribeuserworker({ email }) {
  const response = yield call(subscribeuser, email);
  if (response.status) {
    yield put({
      type: SUCCESS_SUBSCRIBE_USER,
      message: "You have successfully subscribed.",
      variant: "success",
    });
  } else {
    yield put({
      type: ERROR_SUBSCRIBE_USER,
      message: "Error subscribing, Please try again!",
      variant: "error",
    });
  }
}

export function* submitconsultationformwatcher() {
  yield takeLatest(
    START_SUBMIT_CONSULTATION_FORM,
    submitconsultationformworker
  );
}

function* submitconsultationformworker({ payment_id, address }) {
  const form = yield select((state) => state.main.formdata);
  const response = yield call(submitconsultationform, {
    ...form,
    payment_id,
    address,
  });
  if (response.status) {
    yield put({
      type: SUCCESS_SUBMIT_CONSULTATION_FORM,
    });
  } else {
    yield put({
      type: ERROR_SUBMIT_CONSULTATION_FORM,
    });
  }
}

export function* getpdfurlwatcher() {
  yield takeLatest(START_GET_PDF_URL, getpdfurlworker);
}

function* getpdfurlworker() {
  const response = yield call(getpdfurl);
  if (response.status) {
    const { data: pdf_data } = response;
    yield put({
      type: SUCCESS_GET_PDF_URL,
      pdf_data,
    });
  } else {
    yield put({
      type: ERROR_GET_PDF_URL,
    });
  }
}

export function* getclientsecretforpaymentwatcher() {
  yield takeLatest(
    START_GET_CLIENT_SECRET_PAYMENT,
    getclientsecretforpaymentworker
  );
}

function* getclientsecretforpaymentworker() {
  const response = yield call(getclientsecretforpayment);
  if (response.status) {
    const { client_secret } = response;
    yield put({
      type: SUCCESS_GET_CLIENT_SECRET_PAYMENT,
      client_secret,
    });
  } else {
    yield put({
      type: ERROR_GET_CLIENT_SECRET_PAYMENT,
    });
  }
}

export function* giftvouchersaga() {
  yield takeLatest(START_SUBMIT_GIFT_VOUCHER_FORM, giftvoucherworker);
}

function* giftvoucherworker(form) {
  const response = yield call(giftVoucher, { ...form });
  const { email } = form;
  if (response.status) {
    yield put({
      type: SUCCESS_SUBMIT_GIFT_VOUCHER_FORM,
      message: "Gift Voucher added successfully.",
      variant: "success",
      email,
    });
  } else {
    yield put({
      type: ERROR_SUBMIT_GIFT_VOUCHER_FORM,
      message: response.message,
      variant: "error",
    });
  }
}

export function* giftVoucherwatcher() {
  yield takeLatest(START_GIFT_VOUCER_LIST, giftVoucherworker);
}

function* giftVoucherworker() {
  const response = yield call(giftVoucherList);
  const { data } = response;
  if (response.status) {
    yield put({
      type: SUCESS_GIFT_VOUCER_LIST,
      data,
    });
  } else {
    yield put({
      type: ERROR_GIFT_VOUCER_LIST,
    });
  }
}

export function* giftVoucherViewwatcher() {
  yield takeLatest(FETCH_GIFT_VOUCEHER_VIEWS, giftVoucherViewworker);
}

function* giftVoucherViewworker({ id, voucher_type }) {
  const response = yield call(giftVoucherDetailsView, id, voucher_type);
  const { data } = response;
  if (response.status) {
    yield put({
      type: SUCESS_GIFT_VOUCEHER_VIEWS,
      data,
    });
  } else {
    yield put({
      type: ERROR_GIFT_VOUCEHER_VIEWS,
      message: "eVoucher not available!",
      variant: "error",
    });
  }
}

function* contactUsViewWorker({ id, payload }) {
  const response = yield call(testimonialView, id, payload);
  if (response.status) {
    yield put({
      type: SUCCESS_SUBMIT_TESTIMONIAL_FORM,
      payload: response,
      message: "Request testimonial added successfully.",
      variant: "success",
      id: id,
    });
  } else {
    yield put({
      type: ERROR_SUBMIT_TESTIMONIAL_FORM,
      message: response.message,
      variant: "error",
    });
  }
}

export function* contactUsWatcher() {
  yield takeLatest(START_SUBMIT_TESTIMONIAL_FORM, contactUsViewWorker);
}

function* tretmentListworker() {
  const response = yield call(treatmentList);
  const { data } = response;
  if (response.status) {
    yield put({
      type: SUCCESS_TREATMENT_LIST_USER,
      data,
    });
  } else {
    yield put({
      type: ERROR_TREATMENT_LIST_USER,
    });
  }
}

export function* tretmentListWatcher() {
  yield takeLatest(START_TREATMENT_LIST_USER, tretmentListworker);
}
