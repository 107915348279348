import React from "react";
import { Route } from "react-router-dom";
const ApolloFacelift = React.lazy(() => import("../ApolloFacelift"));
const EyelashGrowth = React.lazy(() => import("../EyelashGrowth"));
const Migraines = React.lazy(() => import("../Migraines"));
const TeethGrinding = React.lazy(() => import("../TeethGrinding"));
const ExcessSweating = React.lazy(() => import("../ExcessSweating"));
const Hairloss = React.lazy(() => import("../Hairloss"));
const VitaminB12def = React.lazy(() => import("../VitaminB12def"));
const GentleMaxPro = React.lazy(() => import("../GentleMaxPro"));
const LaserHairRemoval = React.lazy(() => import("../LaserHairRemoval"));
const FungalNailInfection = React.lazy(() => import("../FungalNailInfection"));

export default function AllMenAndOtherRoutes(
  redirectFunc,
  OpenPopUpModal,
  OpenPopUpModalFunc,
  ifOffer,
  enternalLinkFunc
) {
  return [
    <Route path="/apollo-facelift" exact>
      <ApolloFacelift
        redirectFunc={redirectFunc}
        enternalLinkFunc={enternalLinkFunc}
        OpenPopUpModal={OpenPopUpModal}
        OpenPopUpModalFunc={OpenPopUpModalFunc}
        ifoffer={ifOffer}
      />
    </Route>,
    <Route path="/eyelashgrowth" exact>
      <EyelashGrowth
        enternalLinkFunc={enternalLinkFunc}
        redirectFunc={redirectFunc}
        OpenPopUpModal={OpenPopUpModal}
        OpenPopUpModalFunc={OpenPopUpModalFunc}
        ifoffer={ifOffer}
      />
    </Route>,
    <Route path="/gentlemax-pro" exact>
      <GentleMaxPro
        redirectFunc={redirectFunc}
        enternalLinkFunc={enternalLinkFunc}
        OpenPopUpModal={OpenPopUpModal}
        OpenPopUpModalFunc={OpenPopUpModalFunc}
        ifoffer={ifOffer}
      />
    </Route>,
    <Route path="/laser-hair-removal" exact>
      <LaserHairRemoval
        redirectFunc={redirectFunc}
        enternalLinkFunc={enternalLinkFunc}
        OpenPopUpModal={OpenPopUpModal}
        OpenPopUpModalFunc={OpenPopUpModalFunc}
        ifoffer={ifOffer}
      />
    </Route>,
    <Route path="/fungal-nail-infection" exact>
      <FungalNailInfection
        redirectFunc={redirectFunc}
        enternalLinkFunc={enternalLinkFunc}
        OpenPopUpModal={OpenPopUpModal}
        OpenPopUpModalFunc={OpenPopUpModalFunc}
        ifoffer={ifOffer}
      />
    </Route>,
    <Route path="/migraine" exact>
      <Migraines
        redirectFunc={redirectFunc}
        OpenPopUpModal={OpenPopUpModal}
        OpenPopUpModalFunc={OpenPopUpModalFunc}
        ifoffer={ifOffer}
      />
    </Route>,
    <Route path="/teeth-grinding" exact>
      <TeethGrinding
        redirectFunc={redirectFunc}
        OpenPopUpModal={OpenPopUpModal}
        OpenPopUpModalFunc={OpenPopUpModalFunc}
        ifoffer={ifOffer}
      />
    </Route>,
    <Route path="/excess-sweating" exact>
      <ExcessSweating
        redirectFunc={redirectFunc}
        OpenPopUpModal={OpenPopUpModal}
        OpenPopUpModalFunc={OpenPopUpModalFunc}
        ifoffer={ifOffer}
      />
    </Route>,
    <Route path="/hairloss-treatment" exact>
      <Hairloss
        redirectFunc={redirectFunc}
        OpenPopUpModal={OpenPopUpModal}
        OpenPopUpModalFunc={OpenPopUpModalFunc}
        ifoffer={ifOffer}
      />
    </Route>,
    <Route path="/vitamin-b12-def" exact>
      <VitaminB12def
        enternalLinkFunc={enternalLinkFunc}
        redirectFunc={redirectFunc}
        OpenPopUpModal={OpenPopUpModal}
        OpenPopUpModalFunc={OpenPopUpModalFunc}
        ifoffer={ifOffer}
      />
    </Route>,
  ];
}
