import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import facebook from '../../assets/social_icon/fa.svg'
import instagram from '../../assets/social_icon/insta.svg'
import twitter from '../../assets/social_icon/tw.svg'
import youtube from '../../assets/social_icon/youtbe.svg'
import clsx from 'clsx';

const useStyles = makeStyles(theme=>({
    icon:{
        cursor:"pointer",           
        color:theme.palette.primary.main,
        '& img':{
            transition: "all .2s ease-in-out",
            margin: "16px",
            height: "30px",
            [theme.breakpoints.up("xl")] : {
                height: "50px",
                margin: "16px",
            },
            [theme.breakpoints.only("md")] : {
                height: "24px",
                margin: "12px",
            },
            '&:hover':{
                transform: "scale(1.5)",
            }
        }
    },
    forFooter: { 
        '& img':{
            margin: 12
        }
    },
    
    
}))

const SocialMediaIconNew =({forFooter=false})=>{
    const classes = useStyles();
    
return(
    <div className={clsx( classes.icon, 
        {
            [classes.forFooter]:forFooter
        
        })}>    
        <img
            onClick={()=>{window.open('https://www.instagram.com/drmartinpalin/')}}
            alt="INSTAGRAM"
            src={instagram}/>
        <img
            onClick={()=>{window.open('https://twitter.com/drmartinpalin')}}
            alt="Twitter"
            src={twitter}/>
        <img
            onClick={()=>{window.open('https://www.facebook.com/drmartinpalin')}}
            alt="Facebook"
            src={facebook}/>
        <img
            onClick={()=>{window.open('https://www.youtube.com/c/DrPalinAesthetics')}}
            alt="Youtube"
            src={youtube}/>
    </div>
    )
}

export default SocialMediaIconNew

