import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { START_GET_PDF_URL } from '../actions/main'

const useStyles = makeStyles(theme => ({
    main_container : {
        height : '100vh',
        width : '100vw',
        overflow : 'hidden'
    }
}))

const PDF =  (props) => {


    const classes = useStyles()

    const {
        getpdfurl,
        pdf_data_status
    } = props

    console.log(pdf_data_status)
    useEffect(
        () => {
            getpdfurl()
        },
        []
    )

    return (
        pdf_data_status.isLoading
        ?
        <div>
            Loading...
        </div>
        :
        pdf_data_status.isError
        ?
        <div>
            Something went wrong
        </div>
        :
        <div className={classes.main_container}>
            <iframe
              src={pdf_data_status.pdf_data.pricepdf_url}
              style={{
                  height : '100%',
                  width : '100%',
              }}
              title={'Price List'}
            />
        </div>
    )    
}

const mapStateToProps = state => ({
    pdf_data_status : state.main.pdf_data_status
})

const mapDispatchToProps = dispatch => ({
    getpdfurl : () => dispatch({
        type : START_GET_PDF_URL
    })
})

export default connect(mapStateToProps, mapDispatchToProps)(PDF)