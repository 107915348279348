import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
//import Slide from '@material-ui/core/Slide'

const useStyle = makeStyles((theme) => ({
  drawerOpen: {
    width: "50px",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      // width: "34px",
      display: "none",
    },
    // [theme.breakpoints.down("xs")]: {
    //   width: "34px",
    // },
  },
  sliderDotDrawer: {
    height: "100%",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    // [theme.breakpoints.down("xs")]: {
    //   width: "28px",
    // },
  },
  sliderDotUl: {
    padding: 0,
    paddingTop: 85,
    marginBlockStart: "0px",
    marginBlockEnd: "0px",
    "& ul": {
      padding: "0px !important",
    },
    "& li": {
      listStyleType: "none",
      "& button": {
        fontSize: "0px",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "50%",
        border: "none",
        text: "none",
        padding: "5px",
        marginTop: "24px",
        cursor: "pointer !important",
        outline: "none",
      },
      "& button:hover": {
        backgroundColor: "black",
      },
    },
  },
  paperAnchorDockedLeft: {
    borderRight: "none",
  },
}));

const DrawerSlider = ({ dots, goTofunc }) => {
  const classes = useStyle();

  const [showDotAnimation, setShowDotAnimation] = useState(false);

  React.useEffect(() => {
    const t = setTimeout(() => {
      setShowDotAnimation(true);
    }, 350);
    return () => {
      clearTimeout(t);
    };
  }, []);

  return (
    <Drawer
      variant="persistent"
      open={showDotAnimation}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: true,
        }),
        paperAnchorDockedLeft: classes.paperAnchorDockedLeft,
      }}
      transitionDuration={{
        enter: 150,
        exit: 500,
      }}
    >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.sliderDotDrawer}
      >
        <ul className={classes.sliderDotUl}>
          {dots.map(({ key, props }) => {
            return (
              <li
                onClick={() => {
                  goTofunc(key);
                }}
                className={props.className}
                key={key}
              >
                <button>{key}</button>
              </li>
            );
          })}
        </ul>
      </Grid>
    </Drawer>
  );
};
export default DrawerSlider;
