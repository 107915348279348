import { all } from "redux-saga/effects";
import {
  checkofferssaga,
  contactussaga,
  subscribeusersaga,
  submitconsultationformwatcher,
  notificationwatcher,
  getpdfurlwatcher,
  getclientsecretforpaymentwatcher,
  giftvouchersaga,
  giftVoucherwatcher,
  giftVoucherViewwatcher,
  tretmentListWatcher,
  contactUsWatcher,
} from "./main";

export default function* helloSaga() {
  yield all([
    checkofferssaga(),
    contactussaga(),
    subscribeusersaga(),
    submitconsultationformwatcher(),
    notificationwatcher(),
    getpdfurlwatcher(),
    getclientsecretforpaymentwatcher(),
    giftvouchersaga(),
    giftVoucherwatcher(),
    giftVoucherViewwatcher(),
    tretmentListWatcher(),
    contactUsWatcher(),
  ]);
}
