
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Header from '../components/Common/Header'
import Footer from '../components/Common/Footer'
import { backGroundColor_1 } from '../utils/data'
import { Button } from '@material-ui/core';
import { withRouter } from "react-router-dom";
//import { grey } from '@material-ui/core/colors';
import { useCustomScroll } from '../utils/hooks';

const useStyles = makeStyles(theme=>({
    PageNotFoundMainContainer:{
        display:'flex',
        height:"100vh",
        background:backGroundColor_1,
        overflow:"hidden",
    },
    innerContainerPNF:{
        height:"100%",
        width:"100%"
    },
    headerPNF:{
        height:"20%"
    },
    actualPNF:{
        height:"60%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        //marginTop : 160
    },
    footerPNF:{
        //marginBottom : 40
    },
    actualInnerLabelPNF:{
        width:"35%",
        boxShadow: "0px 6px 10px 0px #d9d9d9",
        background:"white",
        height : '55vh',
        [theme.breakpoints.down("sm")]: {
            width:'unset',
            margin:'0px auto',
            padding:'20px'
         },
    },
    actualInnerLabel404PNF:{
        color:theme.palette.secondary.main,
        fontSize:"5rem",
        height:"40%",
        display:'flex',
        justifyContent: "center",
        alignItems: "flex-end",
        fontWeight: 600,
        [theme.breakpoints.only("xl")] : {
            fontSize:"8rem",
        },
    },
    actualInnerLabelTextPNF:{
        height:"60%",
        display: "flex",
        flexDirection: "column",
    },
    actualInnerLabelTextPNF1:{
        textTransform:"uppercase",
        fontFamily:"Open Sans",
        margin:0,
        letterSpacing:" .1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        lineHeight: "35px",
        height:"22%",
        fontSize: "1.25rem",
        fontWeight: 600,
        //color : grey[700],
        [theme.breakpoints.only("xl")] : {
            fontSize: "1.60rem",
        },
        '&:after':{
            content: `""`,
            display: "block",
            height: "3px",
            background: theme.palette.primary.main,
            width:"27%",
            transition: "all 0.3s ease 0s",
            marginTop : 8,
            [theme.breakpoints.only("xl")] : {
                height: 5,
            },
        },
    },
    actualInnerLabelTextPNF2:{
        margin:0,
        fontFamily:"Open Sans",
        textAlign: "center",
        height: "40%",
        width: "100%",
        fontSize: "1.28rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        //color : grey[600],
        letterSpacing : '0.06rem',
        marginTop : 16,
        [theme.breakpoints.only("xl")] : {
            marginTop : 0,
            fontSize: "1.60rem",
        },
    },
    actualInnerButtonPNF:{
        height: "60%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        '& button':{
            width:225,
            fontFamily:"Open Sans",
            [theme.breakpoints.only("xl")] : {
                width:310,
                height:60,
                fontSize: "1.25rem",
            },
        }
    },
    mainLogo : {
        height : 100,
        width : 'auto',
        margin : '30px 0 30px 0'
    }
}))

const PageNotFound = ({history, OpenPopUpModalFunc})=>{
    const classes= useStyles();
    
    const [sticky] = useCustomScroll();

    return(
        <div className={classes.PageNotFoundMainContainer} >
            <div className={classes.innerContainerPNF}>
                <div className={classes.headerPNF}>
                    <Header 
                        forVp={true}
                        sticky={sticky}
                        isSocailBarVisible={true} />
                </div>
                <div className={classes.actualPNF}>
                    <div className={classes.actualInnerLabelPNF} >
                        <div className={classes.actualInnerLabel404PNF} >
                            404
                        </div>
                        <div className={classes.actualInnerLabelTextPNF} >
                            <p
                             className={classes.actualInnerLabelTextPNF1}
                            >
                                Page Not Found
                            </p>
                            <p 
                             className={classes.actualInnerLabelTextPNF2}
                            >
                                This page has been moved
                                <br/>
                                or no longer exists
                            </p>
                            <div className={classes.actualInnerButtonPNF} >
                                <Button
                                  onClick={()=>history.push('/')}
                                  color="secondary"
                                  variant="contained"
                                  disableElevation
                                >
                                    Back To HomePage 
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.footerPNF}>
                    <Footer
                    isInVh={true} 
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    isPartnerNodisplay={true}
                    isNodisplay={true}
                    />
                </div>
            </div>
        </div>
    )
} 

export default withRouter(PageNotFound)