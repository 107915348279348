import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import quotesGold from "../../assets/quotesGold.png";
import { backGroundColor_2 } from "../../utils/data";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    fontSize: "1.2rem",
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: ".85rem",
    },
  },
  reviewCard: {
    flexBasis: "30%",
    height: "100%",
    opacity: "0",
    transform: "translateY(100%)",
    transition: "transform .5s, opacity .5s ease-in",
    [theme.breakpoints.down("sm")]: {
      flexBasis: "40%",
      margin: "0px 15px",
      height: "450px",
    },
  },
  reviewerInfo: {
    height: "50%",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      minHeight: "190px",
      height: "unset",
    },
  },
  reviewTextDiv: {
    height: "50%",
    backgroundColor: backGroundColor_2,
    [theme.breakpoints.down("sm")]: {
      minHeight: "160px",
      height: "unset",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "160px",
      height: "unset",
    },
  },
  reviewerInfoText: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  reviewerImg: {
    borderRadius: "50%",
    height: "100%",
    width: "100%",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "100px",
    },
  },
  reviewerName: {
    fontSize: "1rem",
    fontFamily: "CenturyGothicBold",
    // [theme.breakpoints.up("xl")] : {
    //     fontSize:"1.2rem"
    // },
    [theme.breakpoints.only("md")]: {
      fontSize: ".85rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".85rem",
    },
  },
  reviewerCity: {
    fontFamily: "GothicRegular",
    fontSize: ".95rem",
    // [theme.breakpoints.up("xl")] : {
    //     fontSize:'1rem'
    // },
    [theme.breakpoints.down("md")]: {
      fontSize: ".75rem",
    },
  },
  reviewerImgDiv: {
    [theme.breakpoints.only("md")]: {
      height: "100px",
      width: "100px",
    },
    [theme.breakpoints.only("lg")]: {
      height: "120px",
      width: "120px",
    },
    [theme.breakpoints.only("xl")]: {
      height: "180px",
      width: "180px",
    },
  },
  quotesGoldDiv: {
    width: "100%",
    height: "20%",
    textAlign: "center",
    display: "flex",
    alignItems: "flex-end",
    [theme.breakpoints.only("md")]: {
      height: "10%",
    },
    [theme.breakpoints.up("xl")]: {
      height: "11%",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  quotesGold: {
    height: "100%",
  },
  reviewTextChildDiv: {
    textAlign: "center",
    width: "80%",
    height: "80%",
    display: "flex",
    alignItems: "flex-start",
    paddingTop: "0.5rem",
    [theme.breakpoints.up("md")]: {
      paddingTop: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  reviewText: {
    fontFamily: "GothicRegular",
    // fontSize:".75rem",
    // [theme.breakpoints.up("xl")] : {
    //     fontSize: "1rem",
    // },
    [theme.breakpoints.only("md")]: {
      fontSize: ".85rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".85rem",
      padding: "5px",
    },
  },
  reviewerInfoInnerDiv: {
    display: " flex",
    justifyContent: "space-evenly",
    height: "100%",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "25px 0px",
    },
  },
  reviewTextDivInnerDiv: {
    display: " flex",
    justifyContent: "space-evenly",
    height: "100%",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      //   margin:'10px 0px'
    },
  },
  quotesGoldImg: {
    width: "100%",
    height: "40%",
    [theme.breakpoints.down("sm")]: {
      height: "15px",
      margin: "20px 0px",
    },
  },
  reviewCardInnerDiv: {
    height: "100%",
  },
  animationOpen: {
    opacity: "1",
    transform: "translateY(0%)",
  },
  delayOne: {
    transitionDelay: "600ms",
  },
  delayTwo: {
    transitionDelay: "900ms",
    [theme.breakpoints.down("sm")]: {
      marginTop: "60px",
    },
  },
}));

const ReviewCard = ({ data, index, fadeIn }) => {
  const classes = useStyles();
  // const dataReview =
  //   data.review.length > 150 && window.innerWidth < 816
  //     ? `${data.review.substring(0, 150)}...`
  //     : data.review;
  //   const dstr = data.review.substring(0, 50);

  return (
    <div
      className={clsx(classes.reviewCard, {
        [classes.animationOpen]: fadeIn,
        [classes.delayOne]: index === 1,
        [classes.delayTwo]: index === 2,
      })}
    >
      <div className={classes.reviewCardInnerDiv}>
        <div className={classes.reviewerInfo}>
          <div className={classes.reviewerInfoInnerDiv}>
            <div className={classes.reviewerImgDiv}>
              <img alt="" className={classes.reviewerImg} src={data.imgUrl} />
            </div>
            <div className={classes.reviewerInfoText}>
              <Typography color="secondary" className={classes.reviewerName}>
                {data.name}
              </Typography>
              <Typography className={classes.reviewerCity}>
                {data.city}
              </Typography>
              <Rating
                className={classes.root}
                name="hover-feedback"
                value={data.rating}
                readOnly
                precision={0.5}
              />
            </div>
          </div>
        </div>
        <div className={classes.reviewTextDiv}>
          <div className={classes.reviewTextDivInnerDiv}>
            <div className={classes.quotesGoldDiv}>
              <div className={classes.quotesGoldImg}>
                <img alt="" className={classes.quotesGold} src={quotesGold} />
              </div>
            </div>
            <div className={classes.reviewTextChildDiv}>
              <Typography className={classes.reviewText}>
                {data.review}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
