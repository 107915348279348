import React from 'react'
import { Route } from "react-router-dom";
const ThreadVeins = React.lazy(() => import('../ThreadVeins'));
const Varicose = React.lazy(() => import('../Varicose'));
const BlackHeads = React.lazy(() => import('../BlackHeads'));
const SkinTagRemoval = React.lazy(() => import('../SkinTagRemoval'));
const MiliaRemoval = React.lazy(() => import('../MiliaRemoval'));
const WeightLoss = React.lazy(() => import('../WeightLoss'));
export default function DermatologyRoutes(
    redirectFunc,
    OpenPopUpModal,
    OpenPopUpModalFunc,
    ifOffer,
){
    return (
        [
            <Route path='/thread-veins' exact>
                <ThreadVeins    
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
             <Route path='/weight-loss' exact>
             <WeightLoss    
                 redirectFunc={redirectFunc}
                 OpenPopUpModal={OpenPopUpModal}
                 OpenPopUpModalFunc={OpenPopUpModalFunc}
                 ifoffer={ifOffer}
             />
         </Route>,
            <Route path='/varicose' exact>
                <Varicose    
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/black-heads' exact>
                <BlackHeads
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
             <Route path='/skin-tag-removal' exact>
                <SkinTagRemoval    
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/milia' exact>
                <MiliaRemoval
                    redirectFunc={redirectFunc}    
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>
        ]
    )    
}
