import React from 'react'
import { Route } from "react-router-dom";
const Acne = React.lazy(() => import('../Acne'));
const AcneScarring = React.lazy(() => import('../AcneScarring'));
const Hyperpigmentation = React.lazy(() => import('../Hyperpigmentation'));
const StretchMarks = React.lazy(() => import('../StretchMarks'));

export default function RejuvenationRoutes(
    redirectFunc,
    OpenPopUpModal,
    OpenPopUpModalFunc,
    ifOffer,
    enternalLinkFunc
){
    return (
        [
            <Route path='/acne' exact>
                <Acne
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/acne-scarring' exact>
                <AcneScarring
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/hyperpigmentation' exact>
                <Hyperpigmentation
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>,
            <Route path='/stretch-marks' exact>
            <StretchMarks    
                    enternalLinkFunc={enternalLinkFunc}
                    redirectFunc={redirectFunc}
                    OpenPopUpModal={OpenPopUpModal}
                    OpenPopUpModalFunc={OpenPopUpModalFunc}
                    ifoffer={ifOffer}
                />
            </Route>
        ]
    )    
}
